import { apiClient } from "@/api/api-client.ts";
import type { User } from "@/types/user.ts";

const socialService = {
	follow: async (payload: {
		username: string;
	}) => {
		const response = await apiClient.post<User>("/follow", payload);

		return response.data;
	},
	unfollow: async (payload: {
		username: string;
	}) => {
		const response = await apiClient.post<User>("/unfollow", payload);

		return response.data;
	},
	following: async (payload: {
		userIds: string[];
	}) => {
		const response = await apiClient.post<User>("/following", payload);

		return response.data;
	},
};

export default socialService;
