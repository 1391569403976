import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconDrop = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M26.4445 17.566C26.059 13.9518 24.5661 10.545 22.1701 7.81213C20.3353 5.34814 19.003 3.53542 19.003 0.624254C19.0027 0.508044 18.9701 0.394208 18.9088 0.2955C18.8475 0.196793 18.7599 0.117114 18.6558 0.065393C18.5518 0.0136718 18.4354 -0.00804868 18.3197 0.00266548C18.204 0.0133796 18.0936 0.0561051 18.0008 0.126054C15.1571 2.21467 13.0966 5.19763 12.1496 8.59694C11.7018 10.6822 11.4845 12.8103 11.5015 14.943C8.95099 14.3923 8.38088 10.5912 8.38088 10.5372C8.36604 10.4403 8.32875 10.3482 8.272 10.2683C8.21524 10.1884 8.1406 10.1229 8.05401 10.0769C7.96743 10.031 7.87131 10.006 7.77333 10.0038C7.67535 10.0017 7.57822 10.0225 7.4897 10.0645C7.35617 10.1186 4.21004 11.7182 4.02551 18.1167C4.02551 18.3268 4 18.5369 4 18.7455C4.00595 21.7286 5.19336 24.5877 7.30228 26.6971C9.4112 28.8064 12.2698 29.9941 15.2523 30H15.2778C18.255 29.9861 21.1059 28.795 23.2084 26.6865C25.3108 24.578 26.4941 21.7234 26.5 18.7455C26.5 18.4304 26.4475 17.566 26.4475 17.566"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconDrop;
