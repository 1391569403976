import { collectionsQueryOptions } from "@/api/queries/collection-queries.ts";
import { Switch } from "@/components/core/forms/switch.tsx";
import { IconCollections, IconPlus, IconQrcode } from "@/components/mvicons";
import { useScreenWidth } from "@/hooks/use-screen-width.ts";
import type { Collection } from "@/types/collection.ts";
import { getAssetByCollectionName } from "@/utils/asset-url.ts";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { useState } from "react";

export const Route = createFileRoute("/_profile/collections")({
	component: Page,
	loader: ({ context: { queryClient } }) => {
		return queryClient.ensureQueryData(collectionsQueryOptions());
	},
});

function ItemPlaceholder({
	handleClick,
}: {
	handleClick?: () => void;
}) {
	return (
		<div className="relative flex grow-0 w-auto max-w-[260px] md:max-w-[180px]">
			<button
				onClick={handleClick}
				type="button"
				className="z-[2] absolute inset-0 flex flex-1 shrink-0 flex-col justify-center items-center w-full h-full rounded-xl gap-4 p-2 md:p-4 bg-white bg-opacity-5 hover:bg-opacity-10 border-2 border-white/20 border-dashed transition-all"
			>
				<IconQrcode className="text-white" size={32} />
				<span className="max-w-[180px] text-center text-xs sm:text-sm font-semibold text-white leading-tight">
					Scan QR Code and redeem digital twin and rewards!
				</span>
			</button>
			<img
				className="z-[1] opacity-0 block w-full h-auto"
				src="/chills1.jpg"
				alt=""
			/>
		</div>
	);
}

function GuidelinePlaceholder({ className }: { className?: string }) {
	return (
		<div
			className={clsx(
				"hidden md:flex flex-row w-full rounded-xl gap-4 p-8 bg-white bg-opacity-5",
				className,
			)}
		>
			<div className="inline-flex text-white">
				<IconCollections size={38} />
			</div>
			<div className="flex flex-col w-full gap-3">
				<span className="text-sm font-semibold text-white leading-tight">
					Your Grails and Prizes
				</span>
				<span className="text-sm text-white/70 leading-tight line-clamp-4">
					As you collect comics your collection will grow here. Over time you’ll
					receive additional collectibles, bonus content and rewards and any of
					those that are collectible will also show up here.
				</span>
				<span className="text-sm text-white/70 leading-tight line-clamp-3">
					This is your personal collection. Each item is yours and all kinds of
					awesome will land here as you explore the Macroverse and participate
					across it.
				</span>
			</div>
		</div>
	);
}

function Collections({
	collections,
}: {
	collections: Collection[];
}) {
	// This is just to simulate the counters, make sure your collection has atleast 3 items in the database
	// visit the macroverse-admin to access this module, just replace the second parameter in slice
	const tCol = collections.slice(0, 2);
	const screenWidth = useScreenWidth();

	const getSlotCount = () => {
		if (screenWidth < 640) return 3; // Mobile
		if (screenWidth < 768) return 4; // Tablet
		return 5; // Desktop
	};

	const slotCount = getSlotCount();

	return (
		<div className="flex flex-col w-full gap-2 mb-24">
			<div className="flex flex-row w-full gap-2">
				{tCol.map((collection) => (
					<div
						key={collection.id}
						className={clsx(
							"relative group flex grow-0 w-auto max-w-[260px] md:max-w-[180px] bg-white bg-opacity-5 rounded-xl",
							[collection.isOwned ? "grayscale-0" : "grayscale"],
						)}
					>
						<div className="z-[2] absolute inset-0 flex flex-col w-full h-full p-2 gap-2 bg-black/50 transition-all opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
							{collection.isOwned ? (
								<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
									<IconCollections size={14} />
									<span className="text-xs font-semibold uppercase">
										You own this
									</span>
								</div>
							) : (
								<>
									<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
										<IconPlus size={12} />
										<span className="text-xs font-semibold uppercase">
											Available Now
										</span>
									</div>
									<button
										type="button"
										className="inline-flex flex-row items-center w-auto mt-auto ml-auto rounded-md p-1 bg-white/20 backdrop-blur text-white"
									>
										<IconQrcode size={32} />
									</button>
								</>
							)}
						</div>

						{collection.assets && collection.assets?.length !== 0 && (
							<img
								className="z-[1] opacity-100 block w-full h-auto"
								src={
									getAssetByCollectionName(
										collection.assets,
										"cover_image",
									) as string
								}
								alt={"FOO"}
							/>
						)}
					</div>
				))}

				{Array.from({ length: slotCount }).map((_, index) => {
					const placeholderCount = slotCount - tCol.length;

					if (index < placeholderCount - (tCol.length === 1 ? 3 : 0)) {
						return <ItemPlaceholder key={`placeholder-${index}`} />;
					}

					if (tCol.length === 1 && index === placeholderCount - 3) {
						return <GuidelinePlaceholder key="guideline" />;
					}

					return null;
				})}
			</div>
		</div>
	);
}

function Page() {
	const [showAllItems, setShowAllItems] = useState(true);
	const collectionQuery = useSuspenseQuery(collectionsQueryOptions());
	const collections = collectionQuery.data;

	return (
		<div className="flex flex-col w-full gap-6">
			<div className="flex flex-col w-full gap-4">
				<div className="flex flex-row items-start sm:items-end justify-between gap-2 sm:gap-4">
					<div className="inline-flex flex-col w-auto">
						<span className="text-sm font-semibold text-white">
							Your Collection
						</span>
					</div>
					<div className="inline-flex flex-row items-center w-auto gap-3">
						<span className="text-sm text-white/50">Show what's missing</span>
						<Switch checked={showAllItems} onCheckedChange={setShowAllItems} />
					</div>
				</div>

				<Collections collections={collections.items} />

				{/*<CollectionItems*/}
				{/*	items={collections?.items}*/}
				{/*	showAllItems={showAllItems}*/}
				{/*/>*/}
				{/*<FtueCollectionItems items={collections?.items} />*/}

				{/*{collectionsCount > 4 ? (*/}
				{/*	<CollectionItems*/}
				{/*		items={collections?.items}*/}
				{/*		showAllItems={showAllItems}*/}
				{/*	/>*/}
				{/*) : (*/}
				{/*	<FtueCollectionItems*/}
				{/*		items={collections?.items}*/}
				{/*		showAllItems={showAllItems}*/}
				{/*	/>*/}
				{/*)}*/}
			</div>
		</div>
	);
}
