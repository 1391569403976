import { transformSearch } from "@/api/helper.ts";
import collectionService from "@/api/services/collection-service.ts";
import { queryOptions } from "@tanstack/react-query";
import { z } from "zod";

export const collectionSearchSchema = z.object({
	search: z.string().optional(),
});

export const collectionsQueryOptions = (
	search?: z.infer<typeof collectionSearchSchema>,
	options?: { enabled: boolean },
) => {
	return queryOptions({
		queryKey: ["collections", search],
		queryFn: () => collectionService.list(transformSearch(search, ["search"])),
		...options,
	});
};
