import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconExternalLink = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.9981 2.01128C12.4427 2.01128 11.9925 1.56104 11.9925 1.00564C11.9925 0.45024 12.4427 0 12.9981 0H18.9944C19.5498 0 20 0.45024 20 1.00564V7.00188C20 7.55728 19.5498 8.00752 18.9944 8.00752C18.439 8.00752 17.9887 7.55728 17.9887 7.00188V3.43347L8.71249 12.7097C8.31977 13.1024 7.68303 13.1024 7.2903 12.7097C6.89758 12.317 6.89758 11.6802 7.2903 11.2875L16.5665 2.01128H12.9981ZM3.00468 4.50964C2.74121 4.50964 2.48854 4.6143 2.30224 4.8006C2.11594 4.9869 2.01128 5.23957 2.01128 5.50304V16.496C2.01128 16.7595 2.11594 17.0121 2.30224 17.1984C2.48854 17.3847 2.74121 17.4894 3.00468 17.4894H13.9976C14.2611 17.4894 14.5138 17.3847 14.7001 17.1984C14.8864 17.0121 14.991 16.7595 14.991 16.496V10.4998C14.991 9.94436 15.4413 9.49412 15.9967 9.49412C16.5521 9.49412 17.0023 9.94436 17.0023 10.4998V16.496C17.0023 17.2929 16.6858 18.0571 16.1223 18.6206C15.5588 19.1841 14.7945 19.5007 13.9976 19.5007H3.00468C2.20779 19.5007 1.44354 19.1841 0.88005 18.6206C0.316563 18.0571 0 17.2929 0 16.496V5.50304C0 4.70615 0.316563 3.9419 0.88005 3.37841C1.44354 2.81492 2.20779 2.49836 3.00468 2.49836H9.00092C9.55632 2.49836 10.0066 2.9486 10.0066 3.504C10.0066 4.0594 9.55632 4.50964 9.00092 4.50964H3.00468Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconExternalLink;
