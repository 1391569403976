import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconInfo = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 31 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M15.585 0C7.34609 0 0.584961 6.76113 0.584961 15C0.584961 23.2389 7.34609 30 15.585 30C23.8238 30 30.585 23.2389 30.585 15C30.585 6.76113 23.8238 0 15.585 0ZM17.3428 23.7891H13.8271V11.4844H17.3428V23.7891ZM15.585 9.72656C14.6159 9.72656 13.8271 8.93777 13.8271 7.96875C13.8271 6.99973 14.6159 6.21094 15.585 6.21094C16.554 6.21094 17.3428 6.99973 17.3428 7.96875C17.3428 8.93777 16.554 9.72656 15.585 9.72656Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconInfo;
