import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconSolana = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 34 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M33.403 23.6562L27.8653 29.5938C27.7402 29.7188 27.6151 29.8125 27.4273 29.9062C27.2709 29.9688 27.0832 30 26.9268 30H0.646276C0.521131 30 0.395986 29.9688 0.302127 29.9062C0.208268 29.8437 0.114409 29.75 0.0518368 29.625C-0.0107358 29.5 -0.0107358 29.375 0.0205505 29.25C0.0518368 29.125 0.0831231 29 0.176982 28.9062L5.71465 22.9688C5.8398 22.8438 5.96495 22.75 6.15266 22.6562C6.30909 22.5938 6.49681 22.5625 6.65324 22.5625H32.9337C33.0589 22.5625 33.184 22.5938 33.2779 22.6562C33.3717 22.7188 33.4656 22.8125 33.5282 22.9375C33.5907 23.0625 33.5907 23.1875 33.5595 23.3125C33.5282 23.4375 33.4969 23.5625 33.403 23.6562ZM27.8653 11.6875C27.7402 11.5625 27.6151 11.4688 27.4273 11.375C27.2709 11.3125 27.0832 11.2812 26.9268 11.2812H0.646276C0.521131 11.2812 0.395986 11.3125 0.302127 11.375C0.208268 11.4375 0.114409 11.5312 0.0518368 11.6562C-0.0107358 11.7812 -0.0107358 11.9062 0.0205505 12.0312C0.0518368 12.1562 0.0831231 12.2812 0.176982 12.375L5.71465 18.3125C5.8398 18.4375 5.96495 18.5312 6.15266 18.625C6.30909 18.6875 6.49681 18.7188 6.65324 18.7188H32.9337C33.0589 18.7188 33.184 18.6875 33.2779 18.625C33.3717 18.5625 33.4656 18.4688 33.5282 18.3438C33.5907 18.2188 33.5907 18.0938 33.5595 17.9688C33.5282 17.8438 33.4969 17.7188 33.403 17.625L27.8653 11.6875ZM0.646276 7.4375H26.9268C27.1145 7.4375 27.2709 7.40625 27.4273 7.34375C27.5838 7.28125 27.7402 7.15625 27.8653 7.03125L33.403 1.09375C33.4969 1 33.5595 0.875 33.5595 0.75C33.5595 0.625 33.5595 0.5 33.5282 0.375C33.4656 0.25 33.403 0.15625 33.2779 0.09375C33.184 0.03125 33.0589 0 32.9337 0H6.65324C6.46553 0 6.30909 0.03125 6.15266 0.09375C5.99623 0.15625 5.8398 0.28125 5.71465 0.40625L0.176982 6.34375C0.0831231 6.4375 0.0205505 6.5625 0.0205505 6.6875C-0.0107358 6.8125 0.0205505 6.9375 0.0518368 7.0625C0.114409 7.1875 0.176982 7.28125 0.302127 7.34375C0.395986 7.40625 0.521131 7.4375 0.646276 7.4375Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconSolana;
