import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";

// Wrapper component to isolate useRive logic that
// renders the <RiveComponent />
const XpEngine = () => {
	const { RiveComponent } = useRive({
		// src: "/animations/rubick.riv",
		// src: "/animations/skins_demo.riv",
		src: "/animations/xpengine.riv",
		stateMachines: "State Machine 1",
		autoplay: true,
		layout: new Layout({
			fit: Fit.Contain, // Adjust how the animation fits within its container
			alignment: Alignment.Center, // Adjust alignment within the container
		}),
	});

	return (
		<div className="w-[200px] h-[200px]">
			<RiveComponent />
		</div>
	);
};

export default XpEngine;
