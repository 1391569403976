import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/core/actions/dropdown-menu.tsx";
import {
	IconChevronLeft,
	IconChevronRight,
	IconDotsHor,
	IconFullscreen,
	IconScroll,
	IconShare,
	IconSwipe,
	IconTap,
} from "@/components/mvicons";
import ReaderDropdownItems from "@/components/reader/reader-dropdown-items.tsx";
import { IconX } from "@tabler/icons-react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import ReactDOM from "react-dom";

const Reader = ({ isOpen, onClose }) => {
	const [isActive, setIsActive] = useState(false);
	const [activeMode, setActiveMode] = useState("tap"); // Default mode is 'tap'
	const [animateDetails, setAnimateDetails] = useState(false); // Control animation state
	const [showDetails, setShowDetails] = useState(true);
	const [step, setStep] = useState(1);
	const [clicked, setClicked] = useState(false);
	const [showContent, setShowContent] = useState(false);

	const handleThirdButtonClick = () => {
		setClicked(true);
		setShowContent(true);
	};

	const handleButtonClick = (mode) => {
		setActiveMode(mode);
	};

	const handleStartReading = () => {
		setAnimateDetails(true);
		setTimeout(() => {
			setShowDetails(false); // Hide the details permanently
		}, 500); // Match this duration with the animation duration
	};

	const StepButtons = () => {
		return (
			<>
				{/* Step 1 Button */}
				{step === 1 && !clicked && (
					<motion.button
						type="button"
						className="z-10 absolute right-[10%] top-1/2 flex flex-row items-center w-auto"
						initial={{ x: 0, y: "-50%", opacity: 1 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{
							duration: 0.2,
							ease: [0.33, 1, 0.68, 1],
						}}
						onClick={() => setStep(2)}
					>
						<div className="inline-flex flex-col w-auto">
							<span className="text-right text-white">
								Click the RIGHT SIDE
							</span>
							<span className="text-right text-white">to Go Forward</span>
						</div>
						<div className="inline-flex text-white">
							<IconChevronRight size={70} stroke={5} />
						</div>
					</motion.button>
				)}

				{/* Step 2 Button */}
				{step === 2 && !clicked && (
					<motion.button
						type="button"
						className="z-10 absolute left-[10%] top-1/2 flex flex-row items-center w-auto"
						initial={{ x: -50, y: "-50%", opacity: 1 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{
							duration: 0.2,
							ease: [0.33, 1, 0.68, 1],
						}}
						onClick={() => setStep(3)}
					>
						<div className="inline-flex text-white">
							<IconChevronLeft size={70} stroke={5} />
						</div>
						<div className="inline-flex flex-col w-auto">
							<span className="text-left text-white">Click the LEFT SIDE</span>
							<span className="text-left text-white">to Go Back</span>
						</div>
					</motion.button>
				)}

				{/* Step 3 Button */}
				{step === 3 && !clicked && (
					<motion.button
						type="button"
						className="z-10 absolute right-[10%] top-1/2 flex flex-row items-center w-auto"
						initial={{ x: 50, y: "-50%", opacity: 1 }}
						animate={clicked ? { x: 50, opacity: 0 } : { x: 0, opacity: 1 }}
						transition={{
							duration: 0.2,
							ease: [0.33, 1, 0.68, 1],
						}}
						onClick={handleThirdButtonClick}
					>
						<div className="inline-flex flex-col w-auto">
							<span className="text-right text-lg font-bold text-white uppercase italic">
								Let's Go
							</span>
						</div>
						<div className="inline-flex text-white">
							<IconChevronRight size={70} stroke={5} />
						</div>
					</motion.button>
				)}
			</>
		);
	};

	const renderContent = () => {
		switch (activeMode) {
			case "scroll":
				return (
					<div className="flex flex-col justify-center items-center w-auto gap-4">
						<span className="text-white">Scroll Story Mode</span>
						<button
							type="button"
							onClick={handleStartReading}
							className="inline-flex self-center py-2 px-4 rounded-md w-auto bg-white text-black"
						>
							Start Reading
						</button>
					</div>
				);
			case "swipe":
				return (
					<div className="flex flex-col justify-center items-center w-auto gap-4">
						<span className="text-white">Swipe Story Mode</span>
						<button
							type="button"
							onClick={handleStartReading}
							className="inline-flex self-center py-2 px-4 rounded-md w-auto bg-white text-black"
						>
							Start Reading
						</button>
					</div>
				);
			case "tap":
				return (
					<div className="flex flex-col justify-center items-center w-auto gap-4">
						<span className="text-white">Tap Story Mode</span>
						<button
							type="button"
							onClick={handleStartReading}
							className="inline-flex self-center py-2 px-4 rounded-md w-auto bg-white text-black"
						>
							Start Reading
						</button>
					</div>
				);
		}
	};

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					className="font-aller fixed inset-0 flex items-center justify-center z-50"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.1 }}
				>
					{/* reader ui overlay */}
					<motion.div
						className="absolute inset-0 bg-mvdark-950/60"
						initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
						animate={{ opacity: 1, backdropFilter: "blur(3px)" }}
						exit={{ opacity: 0, backdropFilter: "blur(0px)" }}
						transition={{ duration: 0.1 }}
					/>

					{/* reader ui container */}
					<motion.div
						className="relative flex flex-col relative h-auto w-auto"
						initial={{ opacity: 0, scale: 0.95 }}
						animate={{ opacity: 1, scale: 1 }}
						exit={{ opacity: 0, scale: 0.95 }}
						transition={{ duration: 0.1 }}
					>
						{/* close reader */}
						<button
							onClick={() => {
								onClose();
							}}
							type="button"
							className="z-10 absolute left-8 top-8 inline-flex justify-center items-center w-[40px] h-[40px] rounded-full outline-none border-none ring-0 bg-white/20 hover:bg-white/30 backdrop-blur text-white"
						>
							<IconX size={28} stroke={2} />
						</button>

						{/* dropdown */}
						<div className="z-10 absolute right-8 top-8">
							<DropdownMenu
								modal={false}
								onOpenChange={(open) => setIsActive(open)}
							>
								<DropdownMenuTrigger asChild>
									<button
										type="button"
										className={clsx(
											"inline-flex flex-col justify-center items-center w-[38px] h-[38px] rounded-xl ",
											{
												"bg-white text-mvdark-950": isActive,
												"bg-white/20 hover:bg-white/30 text-white": !isActive,
											},
										)}
									>
										<IconDotsHor size={18} />
									</button>
								</DropdownMenuTrigger>
								<DropdownMenuContent sideOffset={-12} alignOffset={0}>
									<ReaderDropdownItems />
								</DropdownMenuContent>
							</DropdownMenu>
						</div>

						<StepButtons />

						{/* reader utility */}
						<div className="z-10 absolute right-8 bottom-8 inline-flex flex-row items-center w-auto gap-6">
							<button
								type="button"
								className="inline-flex flex-col items-center justify-center gap-2 text-white opacity-50 hover:opacity-100"
							>
								<IconShare size={24} />
							</button>
							<button
								type="button"
								className="inline-flex flex-col items-center justify-center gap-2 text-white opacity-50 hover:opacity-100"
							>
								<IconFullscreen size={22} />
							</button>
						</div>

						{/* reader ui contents */}
						<div className="relative flex flex-col items-center gap-8">
							{/* Reader comics details */}
							{showDetails && (
								<motion.div
									className="z-10 absolute top-16 left-1/2 inline-flex flex-col w-auto gap-6"
									initial={{ y: 0, x: "-50%", opacity: 1 }}
									animate={
										animateDetails
											? { y: -100, opacity: 0 }
											: { y: 0, opacity: 1 }
									} // Animate based on state
									transition={{
										duration: 0.5,
										ease: [0.33, 1, 0.68, 1],
									}}
								>
									<div className="flex flex-col justify-center items-center w-full gap-3">
										<div className="flex justify-center items-center w-full p-4 rounded-md bg-white/30 text-white">
											Logo
										</div>
										<div className="inline-flex flex-row items-center divide-x-2">
											<span className="px-2 uppercase text-sm text-white/70 leading-tight">
												Season 1
											</span>
											<span className="px-2 uppercase text-sm text-white/70 leading-tight">
												Episode 1
											</span>
										</div>
									</div>
									<div className="flex flex-row items-center w-full">
										<span className="font-bold text-3xl text-white">11+</span>
										<div className="inline-flex flex-col w-auto py-1 pl-4 ml-4 border-l-2 border-white">
											<span className="text-white/70 leading-tight">
												Cartoon Violence
											</span>
											<span className="text-white/70 leading-tight">
												Mature Themes
											</span>
											<span className="text-white/70 leading-tight">
												Some Rating Item
											</span>
											<span className="text-white/70 leading-tight">
												Another Rating item
											</span>
										</div>
									</div>
								</motion.div>
							)}

							{/* Reader options */}
							<div className="z-10 absolute left-1/2 -translate-x-1/2 bottom-16 inline-flex flex-row items-center w-auto gap-8">
								<button
									type="button"
									className={`inline-flex flex-col items-center justify-center gap-2 text-white ${activeMode === "tap" ? "opacity-100" : "opacity-50"} hover:opacity-100`}
									onClick={() => handleButtonClick("tap")}
								>
									<IconTap size={32} />
									<span className="text-xs uppercase tracking-widest">Tap</span>
								</button>
								<button
									type="button"
									className={`inline-flex flex-col items-center justify-center gap-2 text-white ${activeMode === "scroll" ? "opacity-100" : "opacity-50"} hover:opacity-100`}
									onClick={() => handleButtonClick("scroll")}
								>
									<IconScroll size={32} />
									<span className="text-xs uppercase tracking-widest">
										Scroll
									</span>
								</button>
								<button
									type="button"
									className={`inline-flex flex-col items-center justify-center gap-2 text-white ${activeMode === "swipe" ? "opacity-100" : "opacity-50"} hover:opacity-100`}
									onClick={() => handleButtonClick("swipe")}
								>
									<IconSwipe size={32} />
									<span className="text-xs uppercase tracking-widest">
										Swipe
									</span>
								</button>
							</div>

							{/* Main reader */}
							<motion.div className="relative flex flex-col justify-center items-center h-screen w-screen bg-black overflow-hidden">
								{showContent && renderContent()}
							</motion.div>
						</div>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>,
		document.body,
	);
};

export default Reader;
