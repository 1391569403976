import { apiClient } from "@/api/api-client.ts";
import type { Supply } from "@/types/supply.ts";

const supplyService = {
	claimSupply: async (payload: {
		code: string;
	}) => {
		const response = await apiClient.post<Supply>("/claim_supply", payload);

		return response.data;
	},
};

export default supplyService;
