import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconWatchlist = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M0.144263 15.8926C0.0493896 15.7031 0 15.4937 0 15.2818C0 15.0699 0.0493896 14.8614 0.144263 14.6719C0.360623 14.2369 5.58673 4 15.0002 4C24.4137 4 29.6362 14.2369 29.8557 14.6719C29.9506 14.8614 30 15.0699 30 15.2818C30 15.4937 29.9506 15.7031 29.8557 15.8926C29.6371 16.3276 24.411 26.5636 15.0002 26.5636C5.58945 26.5636 0.360623 16.3276 0.144263 15.8926ZM2.92032 15.2809C4.1253 17.33 8.46578 23.8346 14.9998 23.8346C21.5547 23.8346 25.8766 17.3344 27.0761 15.2836C25.8711 13.2345 21.5338 6.72723 14.9998 6.72723C8.44215 6.72723 4.11985 13.2296 2.92032 15.2809ZM10.0398 15.2809C10.0401 14.3003 10.3311 13.3414 10.8761 12.5262C11.421 11.7109 12.1953 11.076 13.1013 10.7009C14.0073 10.3258 15.0044 10.2272 15.9661 10.4186C16.9279 10.61 17.8113 11.0826 18.5047 11.776C19.1981 12.4694 19.6703 13.3524 19.8617 14.3141C20.053 15.2759 19.9549 16.273 19.5798 17.179C19.2047 18.085 18.5693 18.8596 17.7541 19.4046C16.9389 19.9496 15.9804 20.2406 14.9998 20.2409C14.3484 20.2409 13.7035 20.1128 13.1017 19.8636C12.5 19.6143 11.9532 19.2482 11.4926 18.7876C11.0321 18.327 10.6667 17.7808 10.4175 17.179C10.1684 16.5772 10.0397 15.9322 10.0398 15.2809Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconWatchlist;
