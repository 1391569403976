import questService from "@/api/services/quest-service.ts";
import { queryClient } from "@/query-client.ts";
import { queryOptions, useMutation } from "@tanstack/react-query";
import { z } from "zod";

export const questSearchQuery = z.object({
	tags: z.string().optional(),
});

export const questQueryOptions = (
	search?: z.infer<typeof questSearchQuery>,
) => {
	return queryOptions({
		queryKey: ["quests"],
		queryFn: () => questService.list(search),
	});
};

export const useQuestClaimMutation = () => {
	return useMutation({
		mutationKey: ["quests"],
		mutationFn: questService.claim,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["profile"] });
			queryClient.invalidateQueries({ queryKey: ["quests"] });
			queryClient.invalidateQueries({ queryKey: ["user"] });
		},
	});
};
