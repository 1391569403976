import {
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
} from "@/components/core/actions/dropdown-menu.tsx";

export default function ProfileFilterItems() {
	return (
		<DropdownMenuGroup>
			<div className="text-white">Currently Unavailable</div>
		</DropdownMenuGroup>
	);
}
