import type { User } from "@/types/user.ts";
import { type ReactNode, createContext, useContext } from "react";

type PortalContainerContextType = {
	user: User;
};

const PortalContainerContext = createContext<
	PortalContainerContextType | undefined
>(undefined);

export const usePortalContainer = () => {
	const context = useContext(PortalContainerContext);
	if (!context) {
		throw new Error(
			"usePortalContainer must be used within a PortalContainerProvider",
		);
	}
	return context;
};

export function PortalContainerProvider({
	children,
	user,
}: { children: ReactNode; user: User }) {
	return (
		<PortalContainerContext.Provider value={{ user }}>
			{children}
		</PortalContainerContext.Provider>
	);
}
