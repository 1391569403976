import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconCreditCard = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 49 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<g id="Icons/MV">
				<g id="Icon_CreditCard">
					<path
						d="M0.5 21.3383V39.5148C0.5 40.8234 1.56453 41.888 2.87305 41.888H46.127C47.4355 41.888 48.5 40.8234 48.5 39.5148V21.3383H0.5Z"
						fill={color}
					/>
					<path
						d="M46.127 6.39999H2.87305C1.56453 6.39999 0.5 7.46459 0.5 8.77318V11.6643H48.5V8.77318C48.5 7.46459 47.4355 6.39999 46.127 6.39999Z"
						fill={color}
					/>
				</g>
			</g>
		</svg>
	</div>
);

export default IconCreditCard;
