// tabs trigger classes

export const blockchainTabTrigger =
	"w-[60px] h-[60px] rounded-2xl text-sm bg-transparent text-white/50 hover:text-white data-[state=active]:bg-mvdark-900 data-[state=active]:text-white data-[state=active]:shadow-lg data-[state=active]:shadow-black/50 data-[state=active]:before:opacity-100 data-[state=active]:before:bg-primary-600";

export const settingsTabTrigger =
	"gap-2 text-sm bg-transparent text-white/50 hover:text-white data-[state=active]:text-white";

export const settingsSubTabTrigger =
	"min-w-[38px] min-h-[38px] rounded-2xl gap-2 text-sm bg-transparent text-white/50 hover:text-white hover:bg-opacity-5 data-[state=active]:px-3 data-[state=active]:bg-white/10 data-[state=active]:text-white data-[state=active]:shadow-sm data-[state=active]:shadow-black/50 data-[state=active]:before:opacity-100 data-[state=active]:before:bg-primary-600";

export const profileTabTrigger =
	"min-w-[38px] min-h-[38px] rounded-xl gap-2 text-sm bg-transparent text-white/50 hover:text-white hover:bg-opacity-5 data-[state=active]:px-3 data-[state=active]:bg-white/10 data-[state=active]:text-white data-[state=active]:shadow-sm data-[state=active]:shadow-black/50 data-[state=active]:before:opacity-100 data-[state=active]:before:bg-primary-600";
