import { useProfilePictureMutation } from "@/api/queries/profile-queries.ts";
import { Button, ButtonLabel } from "@/components/core/buttons/button.tsx";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import ProfilePictureCropper from "@/components/features/profile/profile-picture-cropper.tsx";
import type { User } from "@/types/user.ts";
import getCroppedImg from "@/utils/get-cropped-image.ts";
import { type ChangeEvent, useState } from "react";
import type { Area } from "react-easy-crop";

export default function ProfileAvatarModal({ profile }: { profile: User }) {
	const { closeModal } = useModalControl();
	const [image, setImage] = useState<string | ArrayBuffer | null>(
		profile.profilePictureUrl || "/macronaut-avatar.png",
	);
	const [croppedImage, setCroppedImage] = useState<Area | null>(null);

	const profilePictureMutation = useProfilePictureMutation();

	const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target?.files;

		if (files?.length) {
			const reader = new FileReader();

			reader.onloadend = () => {
				setImage(reader.result);
			};

			reader.readAsDataURL(files[0]);
		}
	};

	const handleCroppedImage = async () => {
		try {
			const imageBlob = await getCroppedImg(image, croppedImage);

			await profilePictureMutation.mutateAsync({ image: imageBlob });

			closeModal();
		} catch (e) {}
	};

	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="flex flex-col w-full gap-8">
				<div className="flex flex-col items-center w-full gap-8">
					<ProfilePictureCropper
						image={image}
						onCrop={(value) => {
							setCroppedImage(value);
						}}
					/>

					<div className="flex flex-col w-full max-w-[380px] gap-3">
						<Button
							className="relative font-quagmire uppercase w-full"
							type="button"
							variant="outline"
							color="blue"
						>
							<input
								id="profile-banner"
								type="file"
								accept="image/jpeg, image/jpg, image/png, image/gif, image/gif"
								onChange={handleFileSelect}
								className="z-[2] absolute w-full h-full opacity-0"
							/>
							<ButtonLabel>Upload Image</ButtonLabel>
						</Button>
						<Button
							onClick={handleCroppedImage}
							className="font-quagmire uppercase self-center bg-mvdark-950 w-full"
							variant="gradient"
						>
							<ButtonLabel>Update</ButtonLabel>
						</Button>
						<Button
							onClick={() => {
								closeModal();
							}}
							className="w-full"
							type="button"
							variant="unstyled"
							size="sm"
						>
							<ButtonLabel>Cancel</ButtonLabel>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
