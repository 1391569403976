import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconLock = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M25.4667 13.1033H24.0587V8.87921C24.0485 6.52077 23.1044 4.26241 21.4332 2.59833C19.7619 0.934262 17.4994 0 15.141 0C12.7825 0 10.5201 0.934262 8.84881 2.59833C7.17752 4.26241 6.23348 6.52077 6.22328 8.87921V13.1033H4.81514C4.06831 13.1035 3.35213 13.4002 2.82404 13.9283C2.29595 14.4564 1.9992 15.1726 1.99902 15.9194L1.99902 27.1839C1.9992 27.9307 2.29595 28.6469 2.82404 29.175C3.35213 29.7031 4.06831 29.9998 4.81514 30H25.4667C26.2135 29.9998 26.9297 29.7031 27.4578 29.175C27.9858 28.6469 28.2826 27.9307 28.2828 27.1839V15.9194C28.2826 15.1726 27.9858 14.4564 27.4578 13.9283C26.9297 13.4002 26.2135 13.1035 25.4667 13.1033ZM19.3652 13.1033H10.9168V8.87921C10.9168 7.75889 11.3619 6.68445 12.154 5.89227C12.9462 5.10008 14.0207 4.65503 15.141 4.65503C16.2613 4.65503 17.3357 5.10008 18.1279 5.89227C18.9201 6.68445 19.3652 7.75889 19.3652 8.87921V13.1033Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconLock;
