import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from "@/components/core/display/avatar.tsx";
import { NotFound } from "@/components/errors/not-found.tsx";
import { IconActivity, IconUser } from "@/components/mvicons";
import IconCheckCircle from "@/components/mvicons/icon-check-circle.tsx";
import IconExp from "@/components/mvicons/icon-exp.tsx";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_public/user_/$username/activities")({
	component: Page,
	errorComponent: () => <NotFound />,
});

function Page() {
	return (
		<div className="flex flex-col w-full gap-6">
			<div className="flex flex-col w-full gap-2">
				<div className="flex flex-row items-center gap-2">
					<span className="text-sm font-semibold text-white">
						Real Time Updates
					</span>
				</div>
				<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
					<div className="inline-flex text-white">
						<IconActivity size={38} />
					</div>
					<div className="flex flex-col w-full gap-2">
						<span className="text-sm font-semibold text-white leading-tight">
							Traveling the ‘verse.
						</span>
						<span className="text-sm text-white/70 leading-tight">
							As you explore, engage, participate and collect you can see a
							record of all your travels here. Sooo handy when you need to find
							that perfect thing from last Tuesday.
						</span>
					</div>
				</div>
				{/*<div className="flex flex-col w-full rounded-xl divide-y divide-white/10 py-3 px-6 bg-white bg-opacity-5">
					<div className="flex flex-row items-center w-full py-3 gap-3">
						<Avatar className="self-start shrink-0 w-[40px] h-[40px] rounded-full border-2 border-white bg-mvlogo-gradient">
							<AvatarImage src="/macronaut-avatar.png" />
							<AvatarFallback>AA</AvatarFallback>
						</Avatar>
						<div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full shrink grow gap-1 lg:gap-4">
							<p className="text-mvblue-300 leading-tight">
								You received{" "}
								<span className="inline mx-2 text-white">
									<IconExp className="translate-y-[2px] mr-1" size={16} />
									+25 XP
								</span>{" "}
								Reward
							</p>
							<span className="text-xs text-white/30  leading-tight">
								Just now
							</span>
						</div>
					</div>
					<div className="flex flex-row items-center w-full py-3 gap-3">
						<Avatar className="self-start shrink-0 w-[40px] h-[40px] rounded-full border-2 border-white bg-mvlogo-gradient">
							<AvatarImage src="/macronaut-avatar.png" />
							<AvatarFallback>AA</AvatarFallback>
						</Avatar>
						<div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full shrink grow gap-1 lg:gap-4">
							<p className="text-mvblue-300 leading-tight">
								You finished
								<span className="inline mx-2 text-white">
									<IconUser className="translate-y-[2px] mr-1" size={16} />
									Complete your profile
								</span>{" "}
								Quest
							</p>
							<span className="text-xs text-white/30  leading-tight">
								2 hours ago
							</span>
						</div>
					</div>
					<div className="flex flex-row items-center w-full py-3 gap-3">
						<Avatar className="self-start shrink-0 w-[40px] h-[40px] rounded-full border-2 border-white bg-mvlogo-gradient">
							<AvatarImage src="/macronaut-avatar.png" />
							<AvatarFallback>AA</AvatarFallback>
						</Avatar>
						<div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full shrink grow gap-1 lg:gap-4">
							<p className="text-mvblue-300 leading-tight">
								You started{" "}
								<span className="inline mx-2 text-white">
									<IconCheckCircle
										className="translate-y-[2px] mr-1"
										size={16}
									/>
									Following
								</span>{" "}
								@macroboy88
							</p>
							<span className="text-xs text-white/30 leading-tight">
								4 days ago
							</span>
						</div>
					</div>
				</div>*/}
			</div>
		</div>
	);
}
