import { createContext, useContext } from "react";

const ModalControlContext = createContext<
	{ closeModal: () => void } | undefined
>(undefined);

const useModalControl = () => {
	const context = useContext(ModalControlContext);
	if (!context) {
		throw new Error(
			"useModalControl must be used within a Modal that is provided by ModalProvider",
		);
	}
	return context;
};

export { ModalControlContext, useModalControl };
