import XpEngine from "@/components/animations/xpengine.tsx";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { IconExp } from "@/components/mvicons";
import { useEffect, useState } from "react";

function QuestRewardGain() {
	return (
		<div className="z-[2] quest-reward-gain inline-flex flex-col justify-center items-center text-white pointer-events-none">
			<IconExp className="z-[2]" size={100} />
			<span className="z-[2] text-3xl">+100</span>
			<div className="z-[1] absolute inset-0 w-full h-full rounded-full bg-mvmain-gradient blur-2xl opacity-80" />
		</div>
	);
}

const ExpTimer = ({ duration = 6 * 60 * 60 }) => {
	const [timeLeft, setTimeLeft] = useState({
		hours: 0,
		minutes: 0,
		seconds: 0,
	});
	const [isTimeUp, setIsTimeUp] = useState(false);
	const [targetTime, setTargetTime] = useState(Date.now() + duration * 1000);

	useEffect(() => {
		const updateTimer = () => {
			const now = Date.now();
			const distance = targetTime - now;

			const hours = Math.floor(distance / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((distance % (1000 * 60)) / 1000);

			if (distance < 0) {
				clearInterval(timerInterval);
				setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
				setIsTimeUp(true);
			} else {
				setTimeLeft({ hours, minutes, seconds });
			}
		};

		const timerInterval = setInterval(updateTimer, 1000);

		return () => clearInterval(timerInterval);
	}, [targetTime]);

	const handleClaimExp = () => {
		const questRewardGain = document.querySelector(
			"#exp-machine .quest-reward-gain",
		);

		questRewardGain?.classList.add("quest-reward-gain--animate");

		setTimeout(() => {
			questRewardGain?.classList.remove("quest-reward-gain--animate");
			setIsTimeUp(false);
			setTargetTime(Date.now() + duration * 1000);
		}, 1000);
	};

	return (
		<div id="exp-machine" className="flex flex-col w-full h-full">
			<QuestRewardGain />
			<div className="relative flex flex-col justify-center items-center w-full h-full scale-110">
				{isTimeUp ? (
					<div className="relative inline-flex w-[190px] h-[190px]">
						<img src="/svg/exp-engine-active.svg" alt="Macroverse" />
						<div className="z-[-1] absolute inset-0 rounded-full  blur-2xl bg-mv-gradient-default transition-all duration-4000 animate-spinAndScale" />
					</div>
				) : (
					<XpEngine />
				)}
			</div>
			<div className="flex flex-col w-full mt-auto">
				{isTimeUp ? (
					<div className="relative flex flex-col justify-center items-center w-full mt-auto">
						<span className="text-center text-sm text-white leading-tight">
							Your XP is Ready!
						</span>
						<div className="flex flex-col justify-center items-center w-full h-[64px]">
							<div className="inline-flex flex-row items-center p-1 gap-1 rounded-2xl text-white bg-white bg-opacity-5">
								<Button
									onClick={handleClaimExp}
									className="font-quagmire w-[160px] bg-transparent uppercase"
									type="button"
									size="md"
									variant="gradient"
									gradient="default"
								>
									<ButtonLabel>Claim</ButtonLabel>
								</Button>
							</div>
						</div>
					</div>
				) : (
					<div className="relative flex flex-col justify-center items-center w-full mt-auto">
						<span className="text-center text-sm text-white leading-tight">
							Claim your next XP in
						</span>
						<div className="flex flex-col justify-center items-center w-full h-[64px]">
							<div className="inline-flex flex-row items-center w-auto p-2 gap-1 rounded-2xl text-white bg-white bg-opacity-5">
								<div className="inline-flex px-2 py-1 rounded-xl bg-white bg-opacity-10">
									<span className="text-white">
										{String(timeLeft.hours).padStart(2, "0")}
									</span>
								</div>
								<span className="text-white">:</span>
								<div className="inline-flex px-2 py-1 rounded-xl bg-white bg-opacity-10">
									<span className="text-white">
										{String(timeLeft.minutes).padStart(2, "0")}
									</span>
								</div>
								<span className="text-white">:</span>
								<div className="inline-flex px-2 py-1 rounded-xl bg-white bg-opacity-10">
									<span className="text-white">
										{String(timeLeft.seconds).padStart(2, "0")}
									</span>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ExpTimer;
