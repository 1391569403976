import { Button, ButtonLabel } from "@/components/core/buttons/button.tsx";
import ReactTrixEditor from "@/components/core/forms/react-trix-editor.tsx";
import { IconMessage } from "@/components/mvicons";
import { Link, createFileRoute } from "@tanstack/react-router";
import { Controller, useForm } from "react-hook-form";

export const Route = createFileRoute("/_profile/updates")({
	component: Page,
});

function Page() {
	const form = useForm({
		defaultValues: {
			id: 1,
		},
	});

	return (
		<div className="flex flex-col w-full gap-6">
			<div className="flex flex-col w-full gap-2">
				<div className="flex flex-row items-center gap-2">
					<span className="text-sm font-semibold text-white">Your Updates</span>
				</div>
				<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
					<div className="inline-flex text-white">
						<IconMessage size={38} />
					</div>
					<div className="flex flex-col w-full gap-2">
						<span className="text-sm font-semibold text-white leading-tight">
							Share your thoughts and fandom!
						</span>
						<span className="text-sm text-white/70 leading-tight">
							Have your say and share what you’re up to, collecting and enjoying
							with the Macroverse. Your personal soapbox awaits....
						</span>
					</div>
				</div>

				{/* <div className="flex flex-col w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5"> */}
				{/* 	<div className="flex flex-col w-full gap-2"> */}
				{/* 		<span className="text-white text-sm">Update Status</span> */}
				{/* 		<div className="flex flex-col w-full"> */}
				{/* 			<Controller */}
				{/* 				name="bio" */}
				{/* 				control={form.control} */}
				{/* 				render={({ field }) => ( */}
				{/* 					<ReactTrixEditor */}
				{/* 						height={140} */}
				{/* 						size="sm" */}
				{/* 						placeholder="What should we know about your?" */}
				{/* 						value={field.value} */}
				{/* 						onChange={field.onChange} */}
				{/* 					/> */}
				{/* 				)} */}
				{/* 			/> */}
				{/* 		</div> */}
				{/* 	</div> */}
				{/* 	<div className="flex flex-col justify-center items-center w-full mt-4"> */}
				{/* 		<div className="inline-flex flex-col w-full max-w-[300px] gap-4"> */}
				{/* 			<Button */}
				{/* 				type="submit" */}
				{/* 				className="font-quagmire w-full uppercase" */}
				{/* 				size="md" */}
				{/* 				variant="gradient" */}
				{/* 			> */}
				{/* 				<ButtonLabel>Update</ButtonLabel> */}
				{/* 			</Button> */}
				{/* 			<Link to="/profile" type="button" className="w-full"> */}
				{/* 				<Button */}
				{/* 					type="submit" */}
				{/* 					className="w-full" */}
				{/* 					size="sm" */}
				{/* 					variant="unstyled" */}
				{/* 				> */}
				{/* 					<ButtonLabel>Cancel</ButtonLabel> */}
				{/* 				</Button> */}
				{/* 			</Link> */}
				{/* 		</div> */}
				{/* 	</div> */}
				{/* </div> */}
			</div>
		</div>
	);
}
