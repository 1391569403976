import { apiClient } from "@/api/api-client.ts";
import type { PaginatedResponse } from "@/api/base-service.ts";
import type { Quest } from "@/types/quest.ts";

const questService = {
	list: async (params: any) => {
		const response = await apiClient.get<PaginatedResponse<Quest>>("/quests", {
			params,
		});

		return response.data;
	},
	claim: async (payload: {
		questId: string;
	}) => {
		await apiClient.post<PaginatedResponse<Quest>>("/quest_claim", payload);
	},
};

export default questService;
