import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconScroll = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 33 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M3.12018 32.001C2.32453 32.001 1.56121 31.6847 0.998596 31.1221C0.435987 30.5595 0.120178 29.7966 0.120178 29.001V4.8252H32.1202V29.001C32.1202 29.7966 31.8039 30.5595 31.2413 31.1221C30.6787 31.6847 29.9158 32.001 29.1202 32.001H3.12018ZM14.8868 28.6069H17.3321V12.7451L22.4981 17.8799L24.209 16.1689L16.1089 8.06885L8.00885 16.1382L9.75104 17.8789L14.8858 12.7441L14.8868 28.6069ZM0.276917 2.04785C0.47643 1.45163 0.858087 0.933404 1.36823 0.565918C1.87836 0.198432 2.49146 0.000407072 3.12018 0H29.129C29.7524 0.00685853 30.3584 0.207672 30.8624 0.574707C31.3663 0.941742 31.7432 1.45661 31.941 2.04785H0.276917Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconScroll;
