import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import type { ReactElement } from "react";

interface TooltipProps {
	children: ReactElement;
	content: ReactElement | string;
	[key: string]: any;
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, ...props }) => {
	return (
		<TooltipPrimitive.Provider delayDuration={0} skipDelayDuration={500}>
			<TooltipPrimitive.Root>
				<TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
				<TooltipPrimitive.Portal>
					<TooltipPrimitive.Content
						className="z-10 max-w-[320px] data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade select-none rounded-xl bg-black bg-opacity-60 backdrop-blur px-3 py-2 shadow shadow-black/50 border-none text-xs font-semibold text-white leading-tight will-change-[transform,opacity]"
						side="top"
						align="center"
						sideOffset={0}
						{...props}
					>
						{content}
						{/*<TooltipPrimitive.Arrow
							className="translate-y-[-1px] fill-white"
							offset={0}
						/>*/}
					</TooltipPrimitive.Content>
				</TooltipPrimitive.Portal>
			</TooltipPrimitive.Root>
		</TooltipPrimitive.Provider>
	);
};

export default Tooltip;
