import axios from "axios";
import axiosThrottle from "axios-request-throttle";
import queryString from "query-string";

export const apiClient = axios.create({
	withCredentials: true,
	baseURL:
		import.meta.env.VITE_PUBLIC_API_BASE_URL ||
		"https://api.macroverse.dev/v1/",
	paramsSerializer: {
		serialize(params) {
			return queryString.stringify(params, { arrayFormat: "bracket" });
		},
	},
	timeout: 1000 * 60,
});

axiosThrottle.use(apiClient, { requestsPerSecond: 50 });
