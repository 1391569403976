import { Slider } from "@/components/core/forms/range-slider.tsx";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import React from "react";
import Cropper, { type Area } from "react-easy-crop";

const ProfilePictureCropper = ({
	image,
	onCrop,
}: { image: any; onCrop: (value: Area) => void }) => {
	const [crop, setCrop] = React.useState({ x: 0, y: 0 });
	const [zoom, setZoom] = React.useState(1);

	const onCropChange = (crop) => {
		setCrop(crop);
	};

	const onCropComplete = (croppedArea, croppedAreaPixels: Area) => {
		onCrop(croppedAreaPixels);
	};

	const onZoomChange = (zoom) => {
		setZoom(zoom);
	};

	const handleZoomChange = (value: number) => {
		if (value >= 0.1 && value <= 5) {
			setZoom(value);
		}
	};

	return (
		<div className="flex flex-col items-center w-full h-auto gap-6">
			<div className="relative flex w-[calc(100%+30px)] h-[320px] -mt-12">
				<Cropper
					image={image}
					crop={crop}
					zoom={zoom}
					aspect={2}
					cropShape="round"
					showGrid={false}
					cropSize={{ width: 170, height: 170 }}
					onCropComplete={onCropComplete}
					onCropChange={onCropChange}
					onZoomChange={onZoomChange}
					classes={{
						containerClassName: "cropper-backdrop",
						cropAreaClassName: "cropper-area",
					}}
				/>
				<div className="absolute bottom-4 left-1/2 -ml-[100px] flex flex-row justify-center items-center w-full max-w-[200px] py-2 px-3 bg-mvdark-950/70 backdrop-blur border border-white/20  rounded-full">
					<button
						type="button"
						onClick={() => handleZoomChange(zoom - 0.1)}
						className="text-white/50 hover:text-white"
					>
						<IconMinus size={16} stroke={3} />
					</button>
					<Slider
						value={[zoom]}
						onValueChange={(value) => handleZoomChange(value[0])}
						min={0.55}
						max={5}
						step={0.1}
						aria-label="Zoom"
						className="zoom-range block w-full mx-2"
					/>
					<button
						type="button"
						onClick={() => handleZoomChange(zoom + 0.1)}
						className="text-white/50 hover:text-white"
					>
						<IconPlus size={16} stroke={3} />
					</button>
				</div>
			</div>

			<div className="flex flex-col w-full max-w-[380px] gap-8">
				<div className="flex flex-col justify-center items-center w-full max-w-[380px] gap-4">
					<p className="font-semibold text-white leading-tight">
						Adjust the size and placement of your Profile Picture.
					</p>
					<p className="text-white/70 leading-tight">
						When it's perfect, update it and jump back into the Macroverse...
					</p>
				</div>
			</div>
		</div>
	);
};

export default ProfilePictureCropper;
