import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconAccounts = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M10.5817 14.0086C11.1454 13.4449 11.1454 12.5275 10.5817 11.9636C10.2997 11.6816 9.9294 11.5408 9.55906 11.5408C9.18873 11.5408 8.8184 11.6816 8.53665 11.9636C7.97268 12.5273 7.97268 13.4449 8.53665 14.0086C9.10039 14.5726 10.0177 14.5726 10.5817 14.0086Z"
				fill={color}
			/>
			<path
				d="M9.55918 16.2534C8.24929 16.2534 7.01997 16.9545 6.35095 18.0829C6.17974 18.3717 6.29167 18.6319 6.34797 18.7304C6.40588 18.8322 6.57869 19.0691 6.92819 19.0691H12.1902C12.5399 19.0691 12.7125 18.8322 12.7706 18.7304C12.8267 18.6319 12.9386 18.3717 12.7676 18.0829C12.0986 16.9545 10.8693 16.2534 9.55918 16.2534Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.44148 6H27.5585C28.9048 6 30 7.0952 30 8.44148V22.1678C30 23.514 28.9048 24.6092 27.5585 24.6092H2.44148C1.0952 24.6092 0 23.514 0 22.1678V8.44148C0 7.0952 1.0952 6 2.44148 6ZM12.1902 20.8904C13.0957 20.8904 13.9041 20.42 14.353 19.632C14.7979 18.8508 14.791 17.9245 14.3344 17.1543C13.7947 16.2438 13.0092 15.521 12.0927 15.0493C13.1385 13.7673 13.0641 11.8704 11.8696 10.6758C10.5959 9.40187 8.5231 9.40187 7.24892 10.6758C6.05438 11.8704 5.98 13.7673 7.02576 15.0493C6.10931 15.521 5.32402 16.2438 4.78432 17.1543C4.3277 17.9245 4.3206 18.8508 4.76555 19.632C5.21439 20.42 6.02303 20.8904 6.92825 20.8904H12.1902ZM18.9391 19.6588H23.9017C24.4045 19.6588 24.8124 19.2511 24.8124 18.748C24.8124 18.2452 24.4045 17.8375 23.9017 17.8375H18.9391C18.4362 17.8375 18.0286 18.2452 18.0286 18.748C18.0286 19.2511 18.4362 19.6588 18.9391 19.6588ZM18.9391 16.2152H25.248C25.7508 16.2152 26.1587 15.8076 26.1587 15.3045C26.1587 14.8017 25.7508 14.394 25.248 14.394H18.9391C18.4362 14.394 18.0286 14.8017 18.0286 15.3045C18.0286 15.8076 18.4362 16.2152 18.9391 16.2152ZM18.9391 12.7717H25.248C25.7508 12.7717 26.1587 12.3641 26.1587 11.861C26.1587 11.3581 25.7508 10.9505 25.248 10.9505H18.9391C18.4362 10.9505 18.0286 11.3581 18.0286 11.861C18.0286 12.3641 18.4362 12.7717 18.9391 12.7717Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconAccounts;
