import { usePortalContainer } from "@/components/layouts/portal-container-context.tsx";
import clsx from "clsx";
import { useState } from "react";

type ColorOption = {
	id: string;
	bgColorClass: string;
};

type ProfileBackgroundPickerProps = {
	onBackgroundChange: (newColor: string) => void;
};

const colorOptions: ColorOption[] = [
	{ id: "black", bgColorClass: "bg-mv-profile-black" },
	{ id: "navy", bgColorClass: "bg-mv-profile-navy" },
	{ id: "purple", bgColorClass: "bg-mv-profile-purple" },
	{ id: "blue", bgColorClass: "bg-mv-profile-blue" },
	{ id: "wine", bgColorClass: "bg-mv-profile-wine" },
];

export default function ProfileBackgroundPicker({
	onBackgroundChange,
}: ProfileBackgroundPickerProps) {
	const [activeColor, setActiveColor] = useState<string>(colorOptions[0].id);

	const handleClick = (color: ColorOption) => {
		setActiveColor(color.id);

		onBackgroundChange(color.bgColorClass);
	};

	return (
		<div className="flex flex-col justify-center items-center w-full gap-4">
			<span className="text-white text-sm">
				Choose your profile background color
			</span>
			<div className="inline-flex flex-row w-auto gap-4">
				{colorOptions.map((color) => (
					<button
						key={color.id}
						type="button"
						onClick={() => handleClick(color)}
						className={clsx(
							`inline-flex justify-center items-center w-[48px] h-[48px] rounded-2xl border-4 ${color.bgColorClass}`,
							{
								"border-white/40 hover:border-white": activeColor !== color.id,
								"border-white hover:border-white": activeColor === color.id,
							},
						)}
					/>
				))}
			</div>
		</div>
	);
}
