import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {
	IconCheck,
	IconPackage,
	IconPlus,
	IconPlusCircle,
} from "@/components/mvicons";
import clsx from "clsx";
import { Pagination } from "swiper/modules";

const shippingAddresses = [
	{
		nickname: "Work",
		name: "Jane Doe",
		companyName: "Doe Enterprises",
		addressLine1: "456 Another St.",
		addressLine2: "N/A",
		cityStatePostal: "Another City, State 67890",
		isActive: true,
	},
	{
		nickname: "Home",
		name: "John Smith",
		companyName: "Smith Co.",
		addressLine1: "789 New St.",
		addressLine2: "Suite 12",
		cityStatePostal: "New City, State 54321",
		isActive: false,
	},
];

export default function SettingsAddressSwiper() {
	return (
		<Swiper
			slidesPerView="auto"
			spaceBetween={10}
			pagination={{
				enabled: false,
				clickable: true,
			}}
			modules={[Pagination]}
			className="overflow-visible w-full"
		>
			{shippingAddresses.map((address, index) => (
				<SwiperSlide
					key={index}
					className=" min-w-[300px] w-[300px] max-w-[300px]"
				>
					<div
						className={clsx(
							"group flex flex-col justify-center items-center h-[240px] w-full rounded-xl gap-4 p-8",
							{
								"bg-white bg-opacity-10": address.isActive,
								"bg-white bg-opacity-5 hover:bg-opacity-10": !address.isActive,
							},
						)}
					>
						{address.isActive ? (
							<div className="absolute top-2 left-2 inline-flex flex-row items-center w-auto h-[24px] px-3 gap-1 rounded-lg bg-black bg-opacity-20 text-white">
								<IconCheck size={12} />
								<span className="text-xs">Default Address</span>
							</div>
						) : (
							<button
								type="button"
								className="absolute top-2 left-2 inline-flex flex-row items-center w-auto h-[24px] px-3 gap-1 rounded-lg bg-black bg-opacity-20 text-white opacity-0 group-hover:opacity-100"
							>
								<IconPlus size={12} />
								<span className="text-xs">Make Default</span>
							</button>
						)}
						<div className="flex flex-row justify-center items-center w-auto h-auto gap-4">
							<div className="inline-flex self-start justify-center items-center text-white">
								<IconPackage size={32} />
							</div>
							<div className="flex flex-col w-auto gap-1">
								<div className="flex flex-col w-full">
									<span className="text-sm text-white/50 leading-tight">
										Address Nickname
									</span>
									<span className="text-sm text-white leading-tight">
										First Name Last Name
									</span>
									<span className="text-sm text-white leading-tight">
										Company Name
									</span>
									<span className="text-sm text-white leading-tight">
										Address Line 1
									</span>
									<span className="text-sm text-white leading-tight">
										Address Line 2
									</span>
									<span className="text-sm text-white leading-tight">
										City, State 0000
									</span>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
			))}
			<SwiperSlide className=" min-w-[300px] w-[300px] max-w-[300px]">
				<button
					type="button"
					className="flex flex-row justify-center items-center h-[240px] w-full gap-2 rounded-xl border-2 border-dashed border-white/5 bg-white bg-opacity-[0.025] hover:bg-opacity-[0.05] hover:bg-opacity-10 text-white p-4"
				>
					<IconPlusCircle size={24} />
					<span className="text-sm text-white leading-tight">
						Add New Address
					</span>
				</button>
			</SwiperSlide>
		</Swiper>
	);
}
