import { Button } from "@/components/core/buttons/button.tsx";
import { IconAlertTriangle } from "@tabler/icons-react";

export function ErrorClaimedModal({
	handleConfirm,
}: {
	handleConfirm: () => void;
}) {
	return (
		<div className="flex flex-col w-full gap-8">
			<div className="flex flex-col w-full gap-6">
				<div className="flex flex-col w-full gap-3">
					<div className="flex flex-row justify-center items-center gap-2 text-mvred-600">
						<IconAlertTriangle size={32} stroke={2} />
						<span className="text-center font-semibold text-xl leading-none tracking-tight">
							QR Already Claimed
						</span>
					</div>
					<span className="font-quagmire text-center font-semibold text-2xl leading-none tracking-tight uppercase text-mvred-600">
						Digital Twin Already Redeemed
					</span>
				</div>
				<div className="flex flex-col w-full gap-3">
					<span className="text-white/70 text-center">
						That doesn’t seem to be a valid QR code. Please make sure it’s a
						Macroverse product.
					</span>
					<span className="text-white/70 text-center">
						If you think this should be valid, try scanning again. If it’s still
						not working contract us at support@macroverse.com
					</span>
				</div>
			</div>
			<div className="flex flex-col w-full">
				<Button
					onClick={handleConfirm}
					className="font-quagmire self-center bg-mvdark-950 w-full uppercase"
					variant="gradient"
					size="lg"
				>
					Try Scan Again
				</Button>
			</div>
		</div>
	);
}
