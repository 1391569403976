import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconVrs = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<g id="Icons/MV">
				<path
					id="Icon_VRS"
					d="M8.28884 19.9614H8.26964L15.3517 9.93278C15.6619 9.4945 15.7911 8.95339 15.7127 8.4222C15.6342 7.89101 15.354 7.41059 14.9303 7.08073L18.5095 7.97998L19.0836 8.1333C19.2401 8.18379 19.3705 8.29365 19.4469 8.43929H19.4663C19.5198 8.54092 19.5446 8.65535 19.5379 8.77002C19.5312 8.88469 19.4933 8.99529 19.4283 9.09001L10.1616 19.7314C9.61772 19.9082 9.0495 19.9981 8.47764 19.998L8.28884 19.9614ZM5.935 19.9233L5.76296 19.8273L5.62966 19.694C5.58722 19.636 5.55475 19.5714 5.53363 19.5028C5.51914 19.4466 5.51259 19.3887 5.51427 19.3307V11.828H1.13162C1.00131 11.8292 0.874058 11.7889 0.768335 11.7127L0.652938 11.6174L0.576928 11.5021C0.550959 11.4541 0.531711 11.4027 0.519637 11.3494C0.505094 11.2933 0.49855 11.2354 0.500268 11.1774C0.50915 11.111 0.528459 11.0464 0.55756 10.986L0.614201 10.8714L3.81033 6.35466L8.13552 0.22998L8.26899 0.0966797L8.30756 0.0581055L8.42296 0.00130208H8.82286L9.01427 0.0773112L9.14757 0.22998C9.22203 0.336772 9.26213 0.463885 9.26231 0.594076V8.09668H13.645L13.8749 8.17269L14.0662 8.30599H14.0849L14.1236 8.34408L14.2003 8.4401C14.2263 8.48815 14.2455 8.53951 14.2576 8.59277V8.61198C14.2742 8.69399 14.2744 8.77861 14.2581 8.86068C14.2417 8.94275 14.2091 9.02066 14.1622 9.09001L10.7556 13.894L8.57432 16.9748L6.64089 19.6546C6.58237 19.7371 6.50488 19.8045 6.41498 19.8509C6.32508 19.8973 6.22546 19.9215 6.12429 19.9214L5.935 19.9233ZM10.7195 6.64062V0.61263C10.7183 0.404807 10.6863 0.198355 10.6243 0H10.873C11.1397 0.076 12.4809 0.325424 12.4809 0.593424L13.3609 6.64062H10.7195Z"
					fill={color}
				/>
			</g>
		</svg>
	</div>
);

export default IconVrs;
