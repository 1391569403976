import AuthModal from "@/components/auth-modal.tsx";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import Alert from "@/components/core/feedbacks/alert.tsx";
import {
	Form,
	FormField,
	FormGroup,
	FormItem,
	FormMessage,
} from "@/components/core/forms/form.tsx";
import { Input } from "@/components/core/forms/input.tsx";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { useAuth } from "@/hooks/use-auth.tsx";
import { useValidationError } from "@/hooks/use-validation-errors.ts";
import { IconAlertTriangle, IconMail } from "@tabler/icons-react";
import { AxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function ForgotPasswordModal() {
	const { closeModal } = useModalControl();
	const { forgotPassword } = useAuth();
	const [isSubmitted, setIsSubmitted] = useState(false);
	const modal = useModal();
	const [errors, setErrors, clearError, getFirstError] = useValidationError();
	const form = useForm({
		defaultValues: {
			username: "",
		},
	});

	const handleSubmit = async (values: {
		username: string;
	}) => {
		try {
			clearError();

			await forgotPassword({
				username: values.username,
			});

			setIsSubmitted(true);
			form.setValue("username", "");
		} catch (e) {
			if (e instanceof AxiosError) {
				setErrors(e.response?.data.errors);
			}
		}
	};
	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<div className="flex flex-col justify-center items-center w-full">
					<div className="flex flex-col w-full max-w-[380px] gap-8">
						<div className="flex flex-col justify-center items-center w-full gap-4">
							<div className="inline-flex flex-row items-center gap-2">
								<span className="text-lg sm:text-xl text-white">
									Forgot Password?
								</span>
							</div>
							<div className="flex flex-col justify-center items-center w-full gap-2">
								<span className="font-quagmire text-center font-bold text-2xl text-white leading-7">
									Stay calm...
								</span>
								<span className="font-quagmire text-center font-bold text-2xl text-mvblue-300 uppercase  leading-7">
									We've got this.
								</span>
							</div>
						</div>

						{isSubmitted && (
							<Alert
								type="success"
								allowClose={false}
								title="Check your inbox"
								icon={<IconMail size={20} stroke={2} />}
							>
								We've just sent you an email to reset your password.
							</Alert>
						)}

						{!!errors.length && (
							<Alert
								type="error"
								allowClose={false}
								title="Ohh no!"
								icon={<IconAlertTriangle size={20} stroke={2} />}
							>
								{getFirstError()}
							</Alert>
						)}

						<div className="flex flex-col w-full divide-y divide-white/20">
							<div className="flex flex-col justify-center items-center w-full gap-4 pb-6">
								<div className="grid grid-cols-12 w-full gap-3">
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="username"
											render={({ field }) => (
												<FormItem>
													<FormGroup inputSize="lg">
														<Input
															autoComplete="off"
															placeholder="Email Address"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full mt-2">
										<Button
											className="font-quagmire bg-mvdark-950 uppercase w-full"
											type="submit"
											variant="gradient"
											size="lg"
										>
											<ButtonLabel>Submit</ButtonLabel>
										</Button>
									</div>
								</div>
								<div className="flex flex-row justify-center items-center w-full mt-2 divide-x divide-x-2 divide-white/20">
									<button
										onClick={() => {
											closeModal();
											modal.openModal({
												title: "Login Modal",
												component: <AuthModal />,
											});
										}}
										type="button"
										className="text-md text-white hover:text-mvblue-300 px-4 leading-4"
									>
										Login
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</Form>
	);
}
