import { apiClient } from "@/api/api-client.ts";
import type { PaginatedResponse } from "@/api/base-service.ts";
import type { Collection } from "@/types/collection.ts";

const collectionService = {
	list: async (params: any) => {
		const response = await apiClient.get<PaginatedResponse<Collection>>(
			"/collections",
			{ params },
		);

		return response.data;
	},
};

export default collectionService;
