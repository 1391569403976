import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconTicket = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 33 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M29.6916 8.86038L30.5513 8.67764L29.3444 2.99995L0 9.2373L1.20683 14.915L2.06653 14.7323C3.03197 14.527 4.1284 15.6748 4.46077 17.2384C4.79314 18.8021 4.25832 20.2966 3.29288 20.5018L2.43318 20.6845L3.64001 26.3622L32.9844 20.1248L31.7776 14.4471L30.9179 14.6299C29.9525 14.8351 28.856 13.6874 28.5237 12.1237C28.1913 10.56 28.7261 9.06559 29.6916 8.86038ZM26.943 18.3251L8.42718 22.2608L6.04149 11.037L24.5573 7.10134L26.943 18.3251Z"
				fill={color}
			/>
			<path
				d="M23.2033 9.18597L8.12641 12.3907L9.78113 20.1755L24.858 16.9708L23.2033 9.18597Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconTicket;
