import { apiClient } from "@/api/api-client.ts";
import type { User } from "@/types/user.ts";
import { formatError } from "@/utils/helper.ts";
import axios, { AxiosError } from "axios";
import { type ReactNode, createContext, useContext, useReducer } from "react";

export type AuthContextType = AuthState & {
	login: (payload: { username: string; password: string }) => Promise<void>;
	csrf: () => Promise<void>;
	verify: (payload: {
		sid: string;
		code: string;
		callbackUrl: string;
	}) => Promise<void>;
	authorize: ({
		callbackUrl,
	}: { callbackUrl: string }) => Promise<{ url: string }>;
	logout: () => Promise<void>;
	register: (payload: {
		username: string;
		password: string;
		password_confirmation: string;
	}) => Promise<void>;
	fetchProfile: () => Promise<User>;
	forgotPassword: (payload: { email: string }) => Promise<void>;
};

type AuthState = {
	user: User | null;
	isLoading: boolean;
	error: Error | null;
};

type AuthAction =
	| { type: "REQUEST" }
	| { type: "SUCCESS"; payload: User }
	| { type: "FAILURE"; payload: AxiosError }
	| { type: "INVALID_CREDENTIALS"; payload: AxiosError }
	| { type: "LOGOUT" }
	| { type: "IDLE" };

const initialState = {
	user: null,
	isLoading: true,
	error: null,
};

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
	switch (action.type) {
		case "REQUEST":
			return { ...state, isLoading: true, error: null };
		case "SUCCESS":
			return { ...state, isLoading: false, user: action.payload, error: null };
		case "INVALID_CREDENTIALS":
			return { ...state, isLoading: false, error: action.payload };
		case "FAILURE":
			return { ...state, isLoading: false, error: action.payload };
		case "LOGOUT":
			return { ...initialState };
		case "IDLE":
			return { ...state, isLoading: false };
		default:
			return state;
	}
};

const baseUrl = (import.meta.env.VITE_PUBLIC_API_BASE_URL || "").replace(
	/\/v1$/,
	"",
);

const baseClient = axios.create({
	baseURL: baseUrl,
	headers: {
		"X-Requested-With": "XMLHttpRequest",
		"Content-Type": "application/json",
		Accept: "application/json",
	},
	timeout: 1000 * 15,
	withCredentials: true,
	withXSRFToken: true,
});

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(authReducer, initialState);

	const fetchProfile = async () => {
		try {
			const response = await apiClient.get<{ data: User }>("/profile");

			dispatch({ type: "SUCCESS", payload: response.data.data });

			return response.data.data;
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				if (error.response.status !== 409) throw error;

				dispatch({ type: "FAILURE", payload: formatError(error) });
			}

			throw new Error("Failed to fetch user profile");
		}
	};

	const csrf = async () => {
		try {
			await baseClient.get("/auth/csrf");

			// await fetchProfile();
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				dispatch({ type: "INVALID_CREDENTIALS", payload: formatError(error) });

				throw error;
			}

			throw new Error(
				"An error has occurred and we're working to fix the problem! We'll be up and running shortly.",
			);
		}
	};

	const login = async (payload: { username: string; password: string }) => {
		try {
			await baseClient.post("/login", payload);

			// await fetchProfile();
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				dispatch({ type: "INVALID_CREDENTIALS", payload: formatError(error) });

				throw error;
			}

			throw new Error(
				"An error has occurred and we're working to fix the problem! We'll be up and running shortly.",
			);
		}
	};

	const authorize = async ({
		callbackUrl,
	}: {
		callbackUrl: string;
	}) => {
		try {
			await csrf();

			const response = await baseClient.post("/auth/signin", {
				callbackUrl,
			});

			return response.data;
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				if (error.response.status !== 409) throw error;

				dispatch({ type: "FAILURE", payload: formatError(error) });
			}

			throw new Error("Failed to fetch user profile");
		}
	};

	const verify = async ({
		sid,
		code,
		callbackUrl,
	}: {
		sid: string;
		code: string;
		callbackUrl: string;
	}) => {
		try {
			const response = await baseClient.post("/verify", {
				sid,
				code,
				callbackUrl,
			});

			return response.data;
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				if (error.response.status !== 409) throw error;

				dispatch({ type: "FAILURE", payload: formatError(error) });
			}

			throw new Error("Failed to fetch user profile");
		}
	};

	const register = async (payload: {
		username: string;
		password: string;
		password_confirmation: string;
	}) => {
		try {
			await baseClient.post("/register", payload);

			// await fetchProfile();
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				dispatch({ type: "INVALID_CREDENTIALS", payload: formatError(error) });

				throw error;
			}

			throw new Error(
				"An error has occurred and we're working to fix the problem! We'll be up and running shortly.",
			);
		}
	};

	const forgotPassword = async (payload: {
		email: string;
	}) => {
		try {
			await baseClient.post("/forgot_password", payload);
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				dispatch({ type: "INVALID_CREDENTIALS", payload: formatError(error) });

				throw error;
			}

			throw new Error(
				"An error has occurred and we're working to fix the problem! We'll be up and running shortly.",
			);
		}
	};

	const logout = async () => {
		dispatch({ type: "LOGOUT" });

		await baseClient.post("/logout");
	};

	const contextValue: AuthContextType = {
		...state,
		csrf,
		login,
		verify,
		authorize,
		register,
		logout,
		fetchProfile,
		forgotPassword,
	};

	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	);
};

const useAuth = () => {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};

export { AuthProvider, useAuth };
