import type { User } from "@/types/user.ts";

export function ProfileHeader({ profile }: { profile: User }) {
	return (
		<div className="z-[2] absolute left-1/2 -bottom-[55px] md:-bottom-[80px] -translate-x-1/2 ">
			<div className="relative inline-flex justify-center items-center flex-col w-auto">
				<div
					style={{ background: profile.bannerPictureUrl }}
					className="inline-flex justify-center items-end w-[110px] md:w-[160px] h-[110px] md:h-[160px] rounded-full bg-mvclaimcard-gradient-a border-4 border-white overflow-hidden"
				>
					{profile.profilePictureUrl ? (
						<img src={profile.profilePictureUrl} alt={profile.username} />
					) : (
						<img src="/macronaut-avatar.png" alt={profile.username} />
					)}
				</div>
			</div>
		</div>
	);
}
