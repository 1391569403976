import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
	stroke?: number;
};

const IconChevronLeft = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	stroke = 2,
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="40"
			height="70"
			viewBox="0 0 40 70"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path d="M37 67L5 35L37 3" stroke={color} strokeWidth={stroke} />
		</svg>
	</div>
);

export default IconChevronLeft;
