import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconAptos = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M23.2351 10.0366H20.5814C20.2722 10.0366 19.9781 9.9044 19.7734 9.67434L18.697 8.46294C18.5367 8.28214 18.3058 8.17927 18.0642 8.17927C17.8226 8.17927 17.5917 8.28276 17.4315 8.46294L16.5083 9.50226C16.206 9.84206 15.7723 10.0372 15.3167 10.0372H0.791088C0.377394 11.2118 0.107648 12.4525 0 13.7393H13.7126C13.9536 13.7393 14.1845 13.6415 14.351 13.4681L15.6277 12.1408C15.7873 11.9749 16.0076 11.8814 16.238 11.8814H16.2905C16.5327 11.8814 16.7631 11.9849 16.9233 12.1657L17.9991 13.3771C18.2038 13.6078 18.4979 13.7393 18.8071 13.7393H30C29.8924 12.4519 29.6226 11.2112 29.2089 10.0372H23.2344L23.2351 10.0366Z"
				fill={color}
			/>
			<path
				d="M8.29828 21.524C8.53924 21.524 8.77018 21.4261 8.93666 21.2528L10.2134 19.9254C10.373 19.7595 10.5933 19.666 10.8236 19.666H10.8762C11.1184 19.666 11.3487 19.7695 11.5089 19.9497L12.5848 21.1611C12.7895 21.3918 13.0836 21.5233 13.3928 21.5233H28.5611C29.1294 20.3537 29.5456 19.0987 29.7935 17.7838H15.2341C14.9249 17.7838 14.6307 17.6516 14.4261 17.4215L13.3502 16.2101C13.19 16.0293 12.9591 15.9265 12.7175 15.9265C12.4759 15.9265 12.245 16.03 12.0847 16.2101L11.1616 17.2495C10.8593 17.5893 10.4256 17.7844 9.96933 17.7844H0.206534C0.454375 19.0993 0.871198 20.3543 1.43885 21.524H8.29828Z"
				fill={color}
			/>
			<path
				d="M19.0593 5.99027C19.3003 5.99027 19.5312 5.89239 19.6977 5.71907L20.9745 4.3917C21.1341 4.22586 21.3544 4.13234 21.5847 4.13234H21.6372C21.8795 4.13234 22.1098 4.23584 22.27 4.41664L23.3459 5.62804C23.5505 5.85872 23.8447 5.99027 24.1538 5.99027H27.0384C24.2915 2.35297 19.9224 0 15.0006 0C10.0789 0 5.70973 2.35297 2.9622 5.99027H19.0593Z"
				fill={color}
			/>
			<path
				d="M13.2651 25.2299H9.32032C9.01114 25.2299 8.71699 25.0977 8.51233 24.8676L7.43648 23.6562C7.27626 23.4754 7.04531 23.3725 6.80373 23.3725C6.56215 23.3725 6.33121 23.476 6.17098 23.6562L5.24784 24.6955C4.94555 25.0353 4.51183 25.2305 4.05558 25.2305H3.99424C6.7424 28.1639 10.6559 30 15.0013 30C19.3466 30 23.2595 28.1639 26.0083 25.2305H13.2651V25.2299Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconAptos;
