import {
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
} from "@/components/core/actions/dropdown-menu.tsx";
import { IconShare, IconUser, IconWallet } from "@/components/mvicons";

export default function ReaderDropdownItems() {
	return (
		<DropdownMenuGroup>
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconWallet size={16} />
					<span className="translate-y-[-1px] leading-1">Connect Wallet</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconUser size={16} />
					<span className="translate-y-[-1px] leading-1">Full Bio</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconShare size={16} />
					<span className="translate-y-[-1px] leading-1">Share Profile</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconUser size={16} />
					<span className="translate-y-[-1px] leading-1">Full Bio</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconShare size={16} />
					<span className="translate-y-[-1px] leading-1">Share Profile</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconUser size={16} />
					<span className="translate-y-[-1px] leading-1">Full Bio</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconShare size={16} />
					<span className="translate-y-[-1px] leading-1">Share Profile</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconUser size={16} />
					<span className="translate-y-[-1px] leading-1">Full Bio</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconShare size={16} />
					<span className="translate-y-[-1px] leading-1">Share Profile</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconUser size={16} />
					<span className="translate-y-[-1px] leading-1">Full Bio</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconShare size={16} />
					<span className="translate-y-[-1px] leading-1">Share Profile</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconUser size={16} />
					<span className="translate-y-[-1px] leading-1">Full Bio</span>
				</div>
			</DropdownMenuItem>
			<DropdownMenuSeparator />
			<DropdownMenuItem>
				<div className="flex flex-row items-center gap-2 py-1">
					<IconShare size={16} />
					<span className="translate-y-[-1px] leading-1">Share Profile</span>
				</div>
			</DropdownMenuItem>
		</DropdownMenuGroup>
	);
}
