import {
	Button,
	ButtonIcon,
	ButtonLabel,
} from "@/components/core/buttons/button";
import Alert from "@/components/core/feedbacks/alert";
import {
	Form,
	FormField,
	FormGroup,
	FormItem,
	FormMessage,
} from "@/components/core/forms/form.tsx";
import { Input } from "@/components/core/forms/input.tsx";
import {
	IconAptos,
	IconCheck,
	IconEtherium,
	IconExp,
	IconPetra,
	IconPlusCircle,
	IconSolana,
} from "@/components/mvicons";
import { useForm } from "react-hook-form";

export default function SettingsAccountsWallets() {
	const form = useForm({
		defaultValues: {
			username: "",
			password: "",
		},
	});

	return (
		<Form {...form}>
			<form>
				<div className="flex flex-col w-full gap-4">
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-col">
							<span className="text-sm font-semibold text-white leading-tight">
								Your Aptos Wallets
							</span>
							<span className="text-sm text-white/30  leading-tight">
								Connect and confirm your wallets to earn bonus XP, rewards and
								unlock new features over time
							</span>
						</div>
						<div className="flex flex-col w-full gap-2">
							<div className="flex flex-row items-center w-full rounded-xl bg-white bg-opacity-5 p-2 pr-3 gap-2">
								<div className="inline-flex justify-center items-center shrink-0 w-[32px] h-[32px] rounded-full bg-white/20 text-white">
									<IconAptos size={18} />
								</div>
								<FormField
									control={form.control}
									name="username"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="sm" inputStyle="unstyled">
												<Input
													className=""
													autoComplete="off"
													placeholder="0x4f0a9b2eab8e6c0f1a92b6de7c3f32d0e7b9f6a5b9b8c3e5e4d6f2a3c8b7e9d0"
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
								<div className="inline-flex flex-row w-auto gap-1.5">
									<Button
										isLoading={false}
										className=""
										type="submit"
										variant="default"
										color="white"
										size="sm"
									>
										<ButtonLabel>Copy</ButtonLabel>
									</Button>
									<div className="inline-flex justify-center items-center w-[100px] h-[24px] px-[12px] gap-1 rounded-[10px] bg-green-600 text-white">
										<IconCheck size={12} />
										<span className="text-[14px]">Verified</span>
									</div>
								</div>
							</div>

							<div className="flex flex-row items-center w-full rounded-xl bg-white bg-opacity-5 p-2 pr-3 gap-2">
								<div className="inline-flex justify-center items-center shrink-0 w-[32px] h-[32px] rounded-full bg-white/20 text-white">
									<IconPetra size={18} />
								</div>
								<FormField
									control={form.control}
									name="username"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="sm" inputStyle="unstyled">
												<Input
													className=""
													autoComplete="off"
													placeholder="0x3b1fA60eCc04A8D2f3f8F2162b9c83a97C5E4b2A"
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
								<div className="inline-flex flex-row w-auto gap-1.5">
									<Button
										isLoading={false}
										className=""
										type="submit"
										variant="default"
										color="white"
										size="sm"
									>
										<ButtonLabel>Copy</ButtonLabel>
									</Button>
									<Button
										isLoading={false}
										className="w-[100px]"
										type="submit"
										variant="outline"
										color="white"
										size="sm"
									>
										<ButtonIcon>
											<IconCheck size={12} />
										</ButtonIcon>
										<ButtonLabel>Confirm</ButtonLabel>
									</Button>
								</div>
							</div>

							<button
								type="button"
								className="flex flex-row justify-center items-center w-full rounded-xl bg-white bg-opacity-5 p-2 pr-3 gap-2 border-2 border-dashed border-white/5 bg-white bg-opacity-[0.025] hover:bg-opacity-[0.05] hover:bg-opacity-10 text-white"
							>
								<IconPlusCircle size={18} />
								<span className="text-sm text-white leading-tight">
									Add Wallet
								</span>
							</button>
						</div>
					</div>

					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-col">
							<span className="text-sm font-semibold text-white leading-tight">
								Your Solana Wallets
							</span>
							<span className="text-sm text-white/30  leading-tight">
								Connect and confirm your wallets to earn bonus XP, rewards and
								unlock new features over time
							</span>
						</div>
						<div className="flex flex-col w-full gap-2">
							<div className="flex flex-row items-center w-full rounded-xl bg-white bg-opacity-5 p-2 pr-3 gap-2">
								<div className="inline-flex justify-center items-center shrink-0 w-[32px] h-[32px] rounded-full bg-white/20 text-white">
									<IconSolana size={16} />
								</div>
								<FormField
									control={form.control}
									name="username"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="sm" inputStyle="unstyled">
												<Input
													className=""
													autoComplete="off"
													placeholder="5FfXqPdTvgzvRXp9Jy7uEYp5SX5aWvH5fVBYvJvHTJtQ"
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
								<div className="inline-flex flex-row w-auto gap-1.5">
									<Button
										isLoading={false}
										className=""
										type="submit"
										variant="default"
										color="white"
										size="sm"
									>
										<ButtonLabel>Copy</ButtonLabel>
									</Button>
									<div className="inline-flex justify-center items-center w-[100px] h-[24px] px-[12px] gap-1 rounded-[10px] bg-green-600 text-white">
										<IconCheck size={12} />
										<span className="text-[14px]">Verified</span>
									</div>
								</div>
							</div>

							<div className="flex flex-row items-center w-full rounded-xl bg-white bg-opacity-5 p-2 pr-3 gap-2">
								<div className="inline-flex justify-center items-center shrink-0 w-[32px] h-[32px] rounded-full bg-white/20 text-white">
									<IconSolana size={16} />
								</div>
								<FormField
									control={form.control}
									name="username"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="sm" inputStyle="unstyled">
												<Input
													className=""
													autoComplete="off"
													placeholder="5FfXqPdTvgzvRXp9Jy7uEYp5SX5aWvH5fVBYvJvHTJtQ"
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
								<div className="inline-flex flex-row w-auto gap-1.5">
									<Button
										isLoading={false}
										className=""
										type="submit"
										variant="default"
										color="white"
										size="sm"
									>
										<ButtonLabel>Copy</ButtonLabel>
									</Button>
									<Button
										isLoading={false}
										className="w-[100px]"
										type="submit"
										variant="outline"
										color="white"
										size="sm"
									>
										<ButtonIcon>
											<IconCheck size={12} />
										</ButtonIcon>
										<ButtonLabel>Confirm</ButtonLabel>
									</Button>
								</div>
							</div>

							<button
								type="button"
								className="flex flex-row justify-center items-center w-full rounded-xl bg-white bg-opacity-5 p-2 pr-3 gap-2 border-2 border-dashed border-white/5 bg-white bg-opacity-[0.025] hover:bg-opacity-[0.05] hover:bg-opacity-10 text-white"
							>
								<IconPlusCircle size={18} />
								<span className="text-sm text-white leading-tight">
									Add Wallet
								</span>
							</button>
						</div>
					</div>

					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-col">
							<span className="text-sm font-semibold text-white leading-tight">
								Your Etherium Wallets
							</span>
							<span className="text-sm text-white/30  leading-tight">
								Connect and confirm your wallets to earn bonus XP, rewards and
								unlock new features over time
							</span>
						</div>
						<div className="flex flex-col w-full gap-2">
							<div className="flex flex-row items-center w-full rounded-xl bg-white bg-opacity-5 p-2 pr-3 gap-2">
								<div className="inline-flex justify-center items-center shrink-0 w-[32px] h-[32px] rounded-full bg-white/20 text-white">
									<IconEtherium size={18} />
								</div>
								<FormField
									control={form.control}
									name="username"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="sm" inputStyle="unstyled">
												<Input
													className=""
													autoComplete="off"
													placeholder="0xAb5801a7D398351b8bE11C439e05C5B3259aeC9B"
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
								<div className="inline-flex flex-row w-auto gap-1.5">
									<Button
										isLoading={false}
										className=""
										type="submit"
										variant="default"
										color="white"
										size="sm"
									>
										<ButtonLabel>Copy</ButtonLabel>
									</Button>
									<div className="inline-flex justify-center items-center w-[100px] h-[24px] px-[12px] gap-1 rounded-[10px] bg-green-600 text-white">
										<IconCheck size={12} />
										<span className="text-[14px]">Verified</span>
									</div>
								</div>
							</div>

							<div className="flex flex-row items-center w-full rounded-xl bg-white bg-opacity-5 p-2 pr-3 gap-2">
								<div className="inline-flex justify-center items-center shrink-0 w-[32px] h-[32px] rounded-full bg-white/20 text-white">
									<IconEtherium size={18} />
								</div>
								<FormField
									control={form.control}
									name="username"
									render={({ field }) => (
										<FormItem>
											<FormGroup inputSize="sm" inputStyle="unstyled">
												<Input
													className=""
													autoComplete="off"
													placeholder="0xAb5801a7D398351b8bE11C439e05C5B3259aeC9B"
													{...field}
												/>
											</FormGroup>
											<FormMessage />
										</FormItem>
									)}
								/>
								<div className="inline-flex flex-row w-auto gap-1.5">
									<Button
										isLoading={false}
										className=""
										type="submit"
										variant="default"
										color="white"
										size="sm"
									>
										<ButtonLabel>Copy</ButtonLabel>
									</Button>
									<Button
										isLoading={false}
										className="w-[100px]"
										type="submit"
										variant="outline"
										color="white"
										size="sm"
									>
										<ButtonIcon>
											<IconCheck size={12} />
										</ButtonIcon>
										<ButtonLabel>Confirm</ButtonLabel>
									</Button>
								</div>
							</div>

							<button
								type="button"
								className="flex flex-row justify-center items-center w-full rounded-xl bg-white bg-opacity-5 p-2 pr-3 gap-2 border-2 border-dashed border-white/5 bg-white bg-opacity-[0.025] hover:bg-opacity-[0.05] hover:bg-opacity-10 text-white"
							>
								<IconPlusCircle size={18} />
								<span className="text-sm text-white leading-tight">
									Add Wallet
								</span>
							</button>
						</div>
					</div>
				</div>
			</form>
		</Form>
	);
}
