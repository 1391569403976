import {
	useFollowMutation,
	useFollowingQueryOptions,
	useUnfollowMutation,
} from "@/api/queries/follow-queries.ts";
import {
	Button,
	ButtonIcon,
	ButtonLabel,
} from "@/components/core/buttons/button.tsx";
import { IconCheck } from "@/components/mvicons";
import type { User } from "@/types/user.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export function FollowButton({ user }: { user: User }) {
	const queryClient = useQueryClient();
	const followMutation = useFollowMutation();
	const unfollowMutation = useUnfollowMutation();

	const { data: followingData, isLoading } = useQuery(
		useFollowingQueryOptions([user.id]),
	);
	const isFollowing = followingData?.[user.id] ?? false;
	const isMutating = followMutation.isLoading || unfollowMutation.isLoading;

	const handleToggleFollow = async () => {
		try {
			if (isFollowing) {
				await unfollowMutation.mutateAsync(
					{ username: user.username || user.macronautId },
					{
						onSuccess: () => {
							queryClient.setQueryData(
								useFollowingQueryOptions([user.id]).queryKey,
								(prev) => ({ ...prev, [user.id]: false }),
							);
						},
					},
				);
			} else {
				await followMutation.mutateAsync(
					{ username: user.username || user.macronautId },
					{
						onSuccess: () => {
							queryClient.setQueryData(
								useFollowingQueryOptions([user.id]).queryKey,
								(prev) => ({ ...prev, [user.id]: true }),
							);
						},
					},
				);
			}
		} catch (err) {
			console.error(`Failed to ${isFollowing ? "unfollow" : "follow"}:`, err);
		}
	};

	return (
		<Button
			size="sm"
			className="self-center !rounded-2xl !h-[34px] !max-h-[34px] !px-6 !gap-1.5"
			variant={isFollowing ? "outline" : "default"}
			color="white"
			disabled={isMutating || isLoading}
			onClick={handleToggleFollow}
		>
			<ButtonIcon>
				<IconCheck size={16} />
			</ButtonIcon>
			<ButtonLabel className="font-semibold">
				{isLoading ? "Loading..." : isFollowing ? "Following" : "Follow"}
			</ButtonLabel>
		</Button>
	);
}
