import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconTwitterx = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 23 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M17.4287 0H20.8221L13.4085 8.47179L22.13 20H15.3011L9.9525 13.0082L3.83246 20H0.436999L8.36658 10.9385L0 0H7.00224L11.8369 6.39077L17.4287 0ZM16.2377 17.9692H18.118L5.98053 1.9241H3.96274L16.2377 17.9692Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconTwitterx;
