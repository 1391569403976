import { Button, ButtonLabel } from "@/components/core/buttons/button";
import Alert from "@/components/core/feedbacks/alert";
import {
	Form,
	FormField,
	FormGroup,
	FormItem,
	FormMessage,
} from "@/components/core/forms/form.tsx";
import { Input } from "@/components/core/forms/input.tsx";
import { IconCheckCircle, IconSecurity } from "@/components/mvicons";
import { useForm } from "react-hook-form";

export default function ProfileSecurity() {
	const form = useForm({
		defaultValues: {
			current_email: "",
			new_email: "",
			current_password: "",
			new_password: "",
			confirm_new_password: "",
		},
	});

	const usingSocialMedia = false;

	return (
		<>
			{usingSocialMedia ? (
				<div className="flex flex-col w-full gap-2">
					<div className="flex flex-row items-center gap-2">
						<span className="text-sm font-semibold text-white">
							Your Security
						</span>
					</div>
					<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
						<div className="inline-flex text-white">
							<IconSecurity />
						</div>
						<div className="flex flex-col w-full max-w-[280px] gap-2">
							<span className="text-sm font-semibold text-white leading-tight">
								You can't change settings
							</span>
							<span className="text-sm text-white/70 leading-tight">
								You're connected via Apple &mdash; manage your account within
								your Apple ID settings.
							</span>
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-col w-full max-w-[560px] mx-auto mt-2 gap-8">
					<div className="flex flex-col w-full gap-4">
						<div className="flex flex-row items-center justify-center gap-2">
							<span className="text-sm font-semibold text-white">
								Update your email
							</span>
						</div>
						<Form {...form}>
							<form className="flex flex-col w-full gap-4">
								<Alert
									type="info"
									allowClose={false}
									icon={<IconCheckCircle size={28} stroke={2} />}
								>
									A confirmation message has been sent to your email address.
									Please verify your new email.
								</Alert>
								<div className="grid grid-cols-12 w-full gap-3">
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="current_email"
											render={({ field }) => (
												<FormItem>
													<FormGroup inputSize="md">
														<Input
															autoComplete="off"
															placeholder="Current Email Address"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="new_email"
											render={({ field }) => (
												<FormItem>
													<FormGroup inputSize="md">
														<Input
															autoComplete="off"
															placeholder="New Email Address"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full">
										<Button
											className="font-quagmire bg-mvdark-950 uppercase w-full"
											type="button"
											variant="gradient"
											size="md"
										>
											<ButtonLabel>Update Email</ButtonLabel>
										</Button>
									</div>
								</div>
							</form>
						</Form>
					</div>

					<div className="flex flex-col w-full gap-4">
						<div className="flex flex-row items-center justify-center gap-2">
							<span className="text-sm font-semibold text-white">
								Update or Recover your password
							</span>
						</div>
						<Form {...form}>
							<form className="flex flex-col w-full gap-4">
								<Alert
									type="info"
									allowClose={false}
									icon={<IconCheckCircle size={28} stroke={2} />}
								>
									A confirmation message has been sent to your email address.
									Please verify your new password.
								</Alert>
								<div className="grid grid-cols-12 w-full gap-3">
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="current_password"
											render={({ field }) => (
												<FormItem>
													<FormGroup inputSize="md">
														<Input
															autoComplete="off"
															type="password"
															placeholder="Current Password"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="new_password"
											render={({ field }) => (
												<FormItem>
													<FormGroup inputSize="md">
														<Input
															autoComplete="off"
															type="password"
															placeholder="New Password"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="confirm_new_password"
											render={({ field }) => (
												<FormItem>
													<FormGroup inputSize="md">
														<Input
															autoComplete="off"
															type="password"
															placeholder="Confirm New Password"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full">
										<Button
											className="font-quagmire bg-mvdark-950 uppercase w-full"
											type="button"
											variant="gradient"
											size="md"
										>
											<ButtonLabel>Update Password</ButtonLabel>
										</Button>
									</div>
								</div>
							</form>
						</Form>
					</div>
				</div>
			)}
		</>
	);
}
