import { format, parseISO } from "date-fns";

export function formatDate(
	value?: string | null | undefined,
	formatString = "PPpp",
) {
	if (!value) {
		return;
	}

	const date = parseISO(value);

	return format(date, formatString);
}
