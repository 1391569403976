import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { IconCheckCircle, IconComic, IconQrcode } from "@/components/mvicons";
import { IconAlertTriangle, IconX } from "@tabler/icons-react";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { Html5QrcodeScanner } from "html5-qrcode";
import { useEffect, useRef, useState } from "react";

export const Route = createFileRoute("/scanner")({
	loader: ({ context: { queryClient } }) => {
		return queryClient.ensureQueryData(profileQueryOptions());
	},
	onError: () => {
		throw redirect({
			to: "/",
		});
	},
	component: () => {
		const modal = useModal();
		const scannerRef = useRef<Html5QrcodeScanner | null>(null);
		const [currentStep, setCurrentStep] = useState("splash");
		const [hasDetected, setDetected] = useState(false);

		const handleOnScanSuccess = (decodedText, decodedResult) => {
			setDetected(true);

			scannerRef.current
				.clear()
				.then(() => {
					console.log("Scanner stopped.");
					setTimeout(() => {
						setCurrentStep("scan_progress"); // Optionally, go back to splash or show the next step
					}, 3_000);

					setTimeout(() => {
						setCurrentStep("scan_progress_1"); // Optionally, go back to splash or show the next step
					}, 5_000);

					setTimeout(() => {
						setCurrentStep("scan_progress_2"); // Optionally, go back to splash or show the next step
					}, 7_000);
				})
				.catch((error) => {
					console.error("Failed to clear QR Scanner:", error);
				});
		};

		const handleOnError = () => {};

		useEffect(() => {
			if (currentStep === "scanner") {
				scannerRef.current = new Html5QrcodeScanner("scanner", {
					fps: 60,
					qrbox: 250,
				});

				scannerRef.current.render(handleOnScanSuccess, handleOnError);
			}
		}, [currentStep]);

		const steps = [
			{
				content: (
					<>
						<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
							<div className="flex flex-row justify-center items-center w-full max-w-[360px] h-auto mx-auto p-4 pt-12">
								<div className="inline-flex w-[72px]">
									<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
								</div>
							</div>
							<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-6">
								<div className="inline-flex flex-col justify-center items-center w-auto gap-2">
									<div className="inline-flex w-[300px]">
										<img
											className="block w-full"
											src="/svg/brand-authentic-editions.svg"
											alt="Macroverse"
										/>
									</div>
									<div className="inline-flex w-[90px]">
										<img
											className="block w-full"
											src="/svg/indicator-comics-scan.svg"
											alt="Macroverse"
										/>
									</div>
								</div>
								<span className="text-center font-semibold text-md text-white">
									Scan Authentic Edition QR Codes and redeem your free Digital
									Twins plus Bonus Perks and Rewards!
								</span>
								<Button
									className="font-quagmire w-full uppercase"
									onClick={() => {
										handleNext();
										// modal.openModal({
										// 	title: "Login Modal",
										// 	component: <AuthModal />,
										// });
									}}
									type="button"
									variant="default"
									color="white"
									size="lg"
								>
									<ButtonLabel>Scan Now</ButtonLabel>
									{/*<ButtonLabel>Login and Scan</ButtonLabel>*/}
								</Button>
							</div>
						</div>
						<div className="z-[2] absolute w-[380px] h-[380px] rounded-full blur-[100px] bg-black opacity-50" />
						<div className="z-[1] absolute inset-0 bg-mvdark-800 opacity-50" />
					</>
				),
			},
			{
				content: (
					<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-4">
						<div className="inline-flex flex-row items-center gap-2">
							<IconQrcode className="text-white" size={32} />
							<span className="text-white text-xl">Scan QR Code</span>
						</div>
						<div className="inline-flex flex-col justiy-center items-center">
							<span className="font-quagmire text-xl font-bold text-white uppercase leading-7">
								Get your Digial Twin
							</span>
							<span className="font-quagmire text-xl font-bold text-mvred-600 uppercase leading-7">
								+ Bonus Rewards
							</span>
						</div>
						<span className="text-center text-sm text-white">
							Each printed issue of Authentic Editions include a QR code
							connected to a unique Digital Twin.
						</span>
						<span className="text-center text-sm text-white">
							Scan and claim this unique Digital Edition and add it to your
							collection. The physical copy is paired with the digital to create
							a bonded set.{" "}
						</span>
						<span className="text-center text-sm text-white">
							Only the owner of the physical can claim it and any additional
							perks and rewards that come with it.
						</span>
						<span className="text-center text-sm text-white">
							Let’s get started!
						</span>
						<div className="flex flex-col w-full mt-2 gap-2">
							<Button
								className="font-quagmire w-full backdrop-blur uppercase"
								type="button"
								variant="gradient"
								size="md"
							>
								<ButtonLabel>Scan Now</ButtonLabel>
							</Button>
							<Button
								className="font-quagmire w-full backdrop-blur uppercase"
								type="button"
								variant="outline"
								size="md"
								color="blue"
							>
								<ButtonLabel>Not Now</ButtonLabel>
							</Button>
						</div>
					</div>
				),
			},
			{
				content: (
					<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
						<div className="absolute bottom-12 self-center inline-flex flex-row items-center w-[320px] rounded-xl p-6 gap-3 bg-black bg-opacity-30 backdrop-blur">
							<ProgressBar duration={1000} />
							{/*<div className="inline-flex text-white">
              <IconComic size={44} />
            </div>*/}
							<div className="flex flex-col gap-1">
								<div className="flex flex-row p-2 gap-1 rounded-lg bg-mvblue-300/20">
									<IconQrcode className="text-mvblue-300" size={18} />
									<span className="text-xs text-mvblue-300">
										Finding Code...
									</span>
								</div>
								<span className="text-xs text-white">
									Align QR Code in Scan Area
								</span>
							</div>
						</div>
						<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-4">
							<div className="inline-flex flex-row items-center gap-1">
								<div className="flex flex-col gap-12">
									<div className="flex flex-row w-full gap-12">
										<div className="flex w-[40px] h-[40px] border-t-4 border-l-4 border-white" />
										<div className="flex w-[40px] h-[40px] border-t-4 border-r-4 border-white" />
									</div>
									<div className="flex flex-row w-full gap-12">
										<div className="flex w-[40px] h-[40px] border-b-4 border-l-4 border-white" />
										<div className="flex w-[40px] h-[40px] border-b-4 border-r-4 border-white" />
									</div>
								</div>
							</div>
						</div>
					</div>
				),
			},
			{
				content: (
					<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
						<div className="absolute bottom-12 self-center inline-flex flex-row items-center w-[320px] rounded-xl p-6 gap-3 bg-black bg-opacity-30 backdrop-blur">
							<div className="relative flex justify-center items-center h-[100px] w-[60px] border-2 border-white/30 text-white">
								<IconComic className="z-[2]" size={24} />
								<div className="z-[1] absolute left-[2px] right-[2px] bottom-[2px] h-1/2 bg-mvlogo-gradient" />
							</div>
							<div className="flex flex-col gap-1">
								<div className="flex flex-row p-2 gap-1 rounded-lg bg-mvblue-300/20">
									<IconQrcode className="text-mvblue-300" size={18} />
									<span className="text-xs text-mvblue-300">
										Identifying Issue...
									</span>
								</div>
								<div className="flex flex-col">
									<span className="text-xs text-white">Finding Title...</span>
									<span className="text-xs text-white">
										Locating Edition...
									</span>
									<span className="text-xs text-white">
										Confirming Details...
									</span>
								</div>
							</div>
						</div>
						<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-4">
							<div className="inline-flex flex-row items-center gap-1">
								<div className="flex flex-col gap-12">
									<div className="flex flex-row w-full gap-12">
										<div className="flex w-[40px] h-[40px] border-t-4 border-l-4 border-white" />
										<div className="flex w-[40px] h-[40px] border-t-4 border-r-4 border-white" />
									</div>
									<div className="flex flex-row w-full gap-12">
										<div className="flex w-[40px] h-[40px] border-b-4 border-l-4 border-white" />
										<div className="flex w-[40px] h-[40px] border-b-4 border-r-4 border-white" />
									</div>
								</div>
							</div>
						</div>
					</div>
				),
			},
			{
				content: (
					<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
						<div className="absolute bottom-12 self-center inline-flex flex-row items-center w-[320px] rounded-xl p-6 gap-3 bg-black bg-opacity-30 backdrop-blur">
							<div className="inline-flex w-[60px] grayscale">
								<img
									className="block w-full"
									src="/sample-comics-cover.jpg"
									alt="Macroverse"
								/>
							</div>
							<div className="flex flex-col gap-1">
								<div className="flex flex-row p-2 gap-1 rounded-lg bg-mvblue-300/20">
									<IconQrcode className="text-mvblue-300" size={18} />
									<span className="text-xs text-mvblue-300">
										Identifying Issue...
									</span>
								</div>
								<div className="flex flex-col">
									<span className="text-xs text-white">
										Chamber of Chills #1
									</span>
									<span className="text-xs text-white">Authentic Edition</span>
									<span className="text-xs text-white">
										1st Printing | October 2024
									</span>
								</div>
							</div>
						</div>
						<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-4">
							<div className="inline-flex flex-row items-center gap-1">
								<div className="flex flex-col gap-12">
									<div className="flex flex-row w-full gap-12">
										<div className="flex w-[40px] h-[40px] border-t-4 border-l-4 border-white" />
										<div className="flex w-[40px] h-[40px] border-t-4 border-r-4 border-white" />
									</div>
									<div className="flex flex-row w-full gap-12">
										<div className="flex w-[40px] h-[40px] border-b-4 border-l-4 border-white" />
										<div className="flex w-[40px] h-[40px] border-b-4 border-r-4 border-white" />
									</div>
								</div>
							</div>
						</div>
					</div>
				),
			},
			{
				content: (
					<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
						<div className="absolute bottom-12 self-center inline-flex flex-row items-center w-[320px] rounded-xl p-6 gap-3 bg-black bg-opacity-30 backdrop-blur">
							<div className="inline-flex w-[60px]">
								<img
									className="block w-full"
									src="/sample-comics-cover.jpg"
									alt="Macroverse"
								/>
							</div>
							<div className="flex flex-col gap-1">
								<div className="flex flex-row p-2 gap-1 rounded-lg bg-mvblue-300/20">
									<IconCheckCircle className="text-mvblue-300" size={18} />
									<span className="text-xs text-mvblue-300">
										Issue Confirmed
									</span>
								</div>
								<div className="flex flex-col">
									<span className="text-xs text-white">
										Chamber of Chills #1
									</span>
									<span className="text-xs text-white">Authentic Edition</span>
									<span className="text-xs text-white">
										1st Printing | October 2024
									</span>
								</div>
							</div>
						</div>
						<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-4">
							<div className="inline-flex flex-row items-center gap-1">
								<div className="flex flex-col gap-12">
									<div className="flex flex-row w-full gap-12">
										<div className="flex w-[40px] h-[40px] border-t-4 border-l-4 border-white" />
										<div className="flex w-[40px] h-[40px] border-t-4 border-r-4 border-white" />
									</div>
									<div className="flex flex-row w-full gap-12">
										<div className="flex w-[40px] h-[40px] border-b-4 border-l-4 border-white" />
										<div className="flex w-[40px] h-[40px] border-b-4 border-r-4 border-white" />
									</div>
								</div>
							</div>
						</div>
					</div>
				),
			},
			{
				content: (
					<>
						<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
							<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-6">
								<div className="inline-flex flex-col justify-center items-center w-auto gap-2">
									<div className="inline-flex w-[180px]">
										<img
											className="block w-full"
											src="/svg/brand-macroverse-authentic-editions.svg"
											alt="Macroverse"
										/>
									</div>
								</div>
								<div className="relative inline-flex w-[200px] border-2 border-white">
									<img
										className="block w-full"
										src="/sample-comics-cover.jpg"
										alt="Macroverse"
									/>
									<div className="z-[-1] absolute inset-0 rounded-full bg-mvmain-gradient blur-2xl transition-all duration-4000 animate-spinAndScale" />
								</div>
								<div className="flex flex-col gap-1">
									<div className="flex flex-row items-center p-4 gap-2 rounded-lg bg-mvblue-300/20">
										<div className="flex justify-center items-center flex-row w-[56px] h-[56px] bg-white">
											<IconQrcode className="text-black" size={44} />
										</div>
										<div className="flex flex-col gap-1">
											<div className="flex flex-row items-center gap-1">
												<IconCheckCircle
													className="text-mvblue-300"
													size={16}
												/>
												<span className="text-xs text-mvblue-300">
													Digital Twin Paired
												</span>
											</div>
											<div className="flex flex-row items-center gap-1 p-1 px-2 rounded-md bg-black backdrop-blur bg-opacity-20">
												{/*<IconQrcode className="text-mvblue-300" size={14} />*/}
												<span className="text-xs text-mvblue-300">
													#10089749
												</span>
											</div>
										</div>
									</div>
									<div className="flex flex-col py-2 px-4">
										<span className="text-center text-xs text-white">
											Chamber of Chills #1
										</span>
										<span className="text-center text-xs text-white">
											Authentic Edition
										</span>
										<span className="text-center text-xs text-white">
											1st Printing | October 2024
										</span>
									</div>
								</div>
								<div className="flex flex-col w-full mt-2 gap-2">
									<div className="flex flex-row gap-2">
										<Button
											className="flex-1 font-quagmire backdrop-blur uppercase"
											type="button"
											variant="outline"
											size="md"
											color="blue"
										>
											<ButtonLabel>Secure</ButtonLabel>
										</Button>
										<Button
											className="flex-1 font-quagmire backdrop-blur uppercase"
											type="button"
											variant="outline"
											size="md"
											color="blue"
										>
											<ButtonLabel>Read</ButtonLabel>
										</Button>
									</div>
									<Button
										className="font-quagmire w-full backdrop-blur uppercase"
										type="button"
										variant="outline"
										size="md"
										color="blue"
									>
										<ButtonLabel>Share</ButtonLabel>
									</Button>
									<Button
										className="font-quagmire w-full backdrop-blur uppercase"
										type="button"
										variant="gradient"
										size="md"
									>
										<ButtonLabel>Scan More</ButtonLabel>
									</Button>
								</div>
							</div>
						</div>
						<div className="z-[1] absolute inset-0  bg-mvdark-800 opacity-50" />
					</>
				),
			},
		];

		const handleNext = () => {
			setCurrentStep("intro");
		};

		return (
			<div className="relative flex flex-col items-center h-screen w-full bg-mvdark-950 min-h-screen gap-8">
				<div className="relative flex flex-col justify-center items-center w-full h-full overflow-hidden">
					<div className="z-50 fixed inset-0 hidden sm:flex flex-col justify-center items-center w-full h-full gap-4 bg-mvdark-950">
						<IconAlertTriangle className="text-white" size={44} />
						<span className="text-white/50">
							Sorry this view is for mobile only
						</span>
					</div>
					<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
						{currentStep > 0 && (
							<>
								<div className="absolute top-4 left-4 flex justify-center items-center w-[32px] h-[32px] rounded-full bg-white/20 hover:bg-white/30 backdrop-blur text-white">
									<IconX size={28} />
								</div>
								<div className="absolute top-12 self-center inline-flex flex-row items-center h-[24px] px-2 gap-1 rounded-lg bg-black bg-opacity-30 backdrop-blur">
									<div className="inline-flex w-[14px] h-[14px] rounded-full bg-mvblue-300" />
									<span className="tracking-wider text-xs text-white uppercase">
										Camera is Active
									</span>
								</div>
							</>
						)}
						<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-4">
							{currentStep === "splash" && (
								<>
									<div
										id="scanner"
										className="z-[1] absolute inset-0 flex h-screen"
									/>
									<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
										<div className="flex flex-row justify-center items-center w-full max-w-[360px] h-auto mx-auto p-4 pt-12">
											<div className="inline-flex w-[72px]">
												<img
													src="/svg/macroverse-logo-white.svg"
													alt="Macroverse"
												/>
											</div>
										</div>
										<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-6">
											<div className="inline-flex flex-col justify-center items-center w-auto gap-2">
												<div className="inline-flex w-[300px]">
													<img
														className="block w-full"
														src="/svg/brand-authentic-editions.svg"
														alt="Macroverse"
													/>
												</div>
												<div className="inline-flex w-[90px]">
													<img
														className="block w-full"
														src="/svg/indicator-comics-scan.svg"
														alt="Macroverse"
													/>
												</div>
											</div>
											<span className="text-center font-semibold text-md text-white">
												Scan Authentic Edition QR Codes and redeem your free
												Digital Twins plus Bonus Perks and Rewards!
											</span>
											<Button
												className="font-quagmire w-full uppercase"
												onClick={() => {
													handleNext();
													// modal.openModal({
													// 	title: "Login Modal",
													// 	component: <AuthModal />,
													// });
												}}
												type="button"
												variant="default"
												color="white"
												size="lg"
											>
												<ButtonLabel>Scan Now</ButtonLabel>
												{/*<ButtonLabel>Login and Scan</ButtonLabel>*/}
											</Button>
										</div>
									</div>
									<div className="z-[2] absolute w-[380px] h-[380px] rounded-full blur-[100px] bg-black opacity-50" />
									<div className="z-[1] absolute inset-0 bg-mvdark-800 opacity-50" />
								</>
							)}

							{currentStep === "intro" && (
								<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-4">
									<div className="inline-flex flex-row items-center gap-2">
										<IconQrcode className="text-white" size={32} />
										<span className="text-white text-xl">Scan QR Code</span>
									</div>
									<div className="inline-flex flex-col justiy-center items-center">
										<span className="font-quagmire text-xl font-bold text-white uppercase leading-7">
											Get your Digial Twin
										</span>
										<span className="font-quagmire text-xl font-bold text-mvred-600 uppercase leading-7">
											+ Bonus Rewards
										</span>
									</div>
									<ProgressBar duration={1000} />
									<span className="text-center text-sm text-white">
										Each printed issue of Authentic Editions include a QR code
										connected to a unique Digital Twin.
									</span>
									<span className="text-center text-sm text-white">
										Scan and claim this unique Digital Edition and add it to
										your collection. The physical copy is paired with the
										digital to create a bonded set.{" "}
									</span>
									<span className="text-center text-sm text-white">
										Only the owner of the physical can claim it and any
										additional perks and rewards that come with it.
									</span>
									<span className="text-center text-sm text-white">
										Let’s get started!
									</span>
									<div className="flex flex-col w-full mt-2 gap-2">
										<Button
											className="font-quagmire w-full backdrop-blur uppercase"
											type="button"
											variant="gradient"
											size="md"
											onClick={() => {
												setCurrentStep("scanner");
											}}
										>
											<ButtonLabel>Scan Now</ButtonLabel>
										</Button>
										<Button
											className="font-quagmire w-full backdrop-blur uppercase"
											type="button"
											variant="outline"
											size="md"
											color="blue"
										>
											<ButtonLabel>Not Now</ButtonLabel>
										</Button>
									</div>
								</div>
							)}

							{currentStep === "scanner" && (
								<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
									<div
										id="scanner"
										className="z-[1] absolute inset-0 flex h-screen"
									/>
									<div className="z-[2] absolute bottom-12 self-center inline-flex flex-row items-center w-[320px] rounded-xl p-6 gap-3 bg-black bg-opacity-30 backdrop-blur">
										{hasDetected ? (
											<ProgressBar duration={5000} />
										) : (
											<div className="inline-flex text-white">
												<IconComic size={44} />
											</div>
										)}
										<div className="flex flex-col gap-1">
											<div className="flex flex-row p-2 gap-1 rounded-lg bg-mvblue-300/20">
												<IconQrcode className="text-mvblue-300" size={18} />
												<span className="text-xs text-mvblue-300">
													Finding Code...
												</span>
											</div>
											<span className="text-xs text-white">
												Align QR Code in Scan Area
											</span>
										</div>
									</div>
									<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-4">
										<div className="inline-flex flex-row items-center gap-1">
											<div className="flex flex-col gap-12">
												<div className="flex flex-row w-full gap-12">
													<div className="flex w-[40px] h-[40px] border-t-4 border-l-4 border-white" />
													<div className="flex w-[40px] h-[40px] border-t-4 border-r-4 border-white" />
												</div>
												<div className="flex flex-row w-full gap-12">
													<div className="flex w-[40px] h-[40px] border-b-4 border-l-4 border-white" />
													<div className="flex w-[40px] h-[40px] border-b-4 border-r-4 border-white" />
												</div>
											</div>
										</div>
									</div>
								</div>
							)}

							{currentStep === "scan_progress" && (
								<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
									<div className="absolute bottom-12 self-center inline-flex flex-row items-center w-[320px] rounded-xl p-6 gap-3 bg-black bg-opacity-30 backdrop-blur">
										<div className="relative flex justify-center items-center h-[100px] w-[60px] border-2 border-white/30 text-white">
											<IconComic className="z-[2]" size={24} />
											<div className="z-[1] absolute left-[2px] right-[2px] bottom-[2px] h-1/2 bg-mvlogo-gradient" />
										</div>
										<div className="flex flex-col gap-1">
											<div className="flex flex-row p-2 gap-1 rounded-lg bg-mvblue-300/20">
												<IconQrcode className="text-mvblue-300" size={18} />
												<span className="text-xs text-mvblue-300">
													Identifying Issue...
												</span>
											</div>
											<div className="flex flex-col">
												<span className="text-xs text-white">
													Finding Title...
												</span>
												<span className="text-xs text-white">
													Locating Edition...
												</span>
												<span className="text-xs text-white">
													Confirming Details...
												</span>
											</div>
										</div>
									</div>
									<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-4">
										<div className="inline-flex flex-row items-center gap-1">
											<div className="flex flex-col gap-12">
												<div className="flex flex-row w-full gap-12">
													<div className="flex w-[40px] h-[40px] border-t-4 border-l-4 border-white" />
													<div className="flex w-[40px] h-[40px] border-t-4 border-r-4 border-white" />
												</div>
												<div className="flex flex-row w-full gap-12">
													<div className="flex w-[40px] h-[40px] border-b-4 border-l-4 border-white" />
													<div className="flex w-[40px] h-[40px] border-b-4 border-r-4 border-white" />
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{currentStep === "scan_progress_1" && (
								<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
									<div className="absolute bottom-12 self-center inline-flex flex-row items-center w-[320px] rounded-xl p-6 gap-3 bg-black bg-opacity-30 backdrop-blur">
										<div className="inline-flex w-[60px] grayscale">
											<img
												className="block w-full"
												src="/sample-comics-cover.jpg"
												alt="Macroverse"
											/>
										</div>
										<div className="flex flex-col gap-1">
											<div className="flex flex-row p-2 gap-1 rounded-lg bg-mvblue-300/20">
												<IconQrcode className="text-mvblue-300" size={18} />
												<span className="text-xs text-mvblue-300">
													Identifying Issue...
												</span>
											</div>
											<div className="flex flex-col">
												<span className="text-xs text-white">
													Chamber of Chills #1
												</span>
												<span className="text-xs text-white">
													Authentic Edition
												</span>
												<span className="text-xs text-white">
													1st Printing | October 2024
												</span>
											</div>
										</div>
									</div>
									<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-4">
										<div className="inline-flex flex-row items-center gap-1">
											<div className="flex flex-col gap-12">
												<div className="flex flex-row w-full gap-12">
													<div className="flex w-[40px] h-[40px] border-t-4 border-l-4 border-white" />
													<div className="flex w-[40px] h-[40px] border-t-4 border-r-4 border-white" />
												</div>
												<div className="flex flex-row w-full gap-12">
													<div className="flex w-[40px] h-[40px] border-b-4 border-l-4 border-white" />
													<div className="flex w-[40px] h-[40px] border-b-4 border-r-4 border-white" />
												</div>
											</div>
										</div>
									</div>
								</div>
							)}

							{currentStep === "scan_progress_2" && (
								<>
									<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
										<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-6">
											<div className="inline-flex flex-col justify-center items-center w-auto gap-2">
												<div className="inline-flex w-[180px]">
													<img
														className="block w-full"
														src="/svg/brand-macroverse-authentic-editions.svg"
														alt="Macroverse"
													/>
												</div>
											</div>
											<div className="relative inline-flex w-[200px] border-2 border-white">
												<img
													className="block w-full"
													src="/sample-comics-cover.jpg"
													alt="Macroverse"
												/>
												<div className="z-[-1] absolute inset-0 rounded-full bg-mvmain-gradient blur-2xl transition-all duration-4000 animate-spinAndScale" />
											</div>
											<div className="flex flex-col gap-1">
												<div className="flex flex-row items-center p-4 gap-2 rounded-lg bg-mvblue-300/20">
													<div className="flex justify-center items-center flex-row w-[56px] h-[56px] bg-white">
														<IconQrcode className="text-black" size={44} />
													</div>
													<div className="flex flex-col gap-1">
														<div className="flex flex-row items-center gap-1">
															<IconCheckCircle
																className="text-mvblue-300"
																size={16}
															/>
															<span className="text-xs text-mvblue-300">
																Digital Twin Paired
															</span>
														</div>
														<div className="flex flex-row items-center gap-1 p-1 px-2 rounded-md bg-black backdrop-blur bg-opacity-20">
															{/*<IconQrcode className="text-mvblue-300" size={14} />*/}
															<span className="text-xs text-mvblue-300">
																#10089749
															</span>
														</div>
													</div>
												</div>
												<div className="flex flex-col py-2 px-4">
													<span className="text-center text-xs text-white">
														Chamber of Chills #1
													</span>
													<span className="text-center text-xs text-white">
														Authentic Edition
													</span>
													<span className="text-center text-xs text-white">
														1st Printing | October 2024
													</span>
												</div>
											</div>
											<div className="flex flex-col w-full mt-2 gap-2">
												<div className="flex flex-row gap-2">
													<Button
														className="flex-1 font-quagmire backdrop-blur uppercase"
														type="button"
														variant="outline"
														size="md"
														color="blue"
													>
														<ButtonLabel>Secure</ButtonLabel>
													</Button>
													<Button
														className="flex-1 font-quagmire backdrop-blur uppercase"
														type="button"
														variant="outline"
														size="md"
														color="blue"
													>
														<ButtonLabel>Read</ButtonLabel>
													</Button>
												</div>
												<Button
													className="font-quagmire w-full backdrop-blur uppercase"
													type="button"
													variant="outline"
													size="md"
													color="blue"
												>
													<ButtonLabel>Share</ButtonLabel>
												</Button>
												<Button
													className="font-quagmire w-full backdrop-blur uppercase"
													type="button"
													variant="gradient"
													size="md"
												>
													<ButtonLabel>Scan More</ButtonLabel>
												</Button>
											</div>
										</div>
									</div>
									<div className="z-[1] absolute inset-0  bg-mvdark-800 opacity-50" />
								</>
							)}
							{/*{steps[currentStep].content}*/}
						</div>
					</div>
					<div className="z-[1] absolute inset-0 bg-mvdark-800 opacity-50" />
					{/*<div className="z-40 absolute bottom-0 flex justify-between w-full p-2 gap-2">*/}
					{/*	<Button*/}
					{/*		className="font-quagmire flex-1 backdrop-blur uppercase"*/}
					{/*		type="button"*/}
					{/*		variant="outline"*/}
					{/*		size="md"*/}
					{/*		disabled={currentStep === 0}*/}
					{/*	>*/}
					{/*		<ButtonLabel>Previous</ButtonLabel>*/}
					{/*	</Button>*/}
					{/*	<Button*/}
					{/*		className="font-quagmire flex-1 backdrop-blur uppercase"*/}
					{/*		type="button"*/}
					{/*		variant="gradient"*/}
					{/*		size="md"*/}
					{/*		onClick={handleNext}*/}
					{/*		disabled={currentStep === steps.length - 1}*/}
					{/*	>*/}
					{/*		<ButtonLabel>Next</ButtonLabel>*/}
					{/*	</Button>*/}
					{/*</div>*/}
				</div>
			</div>
		);
	},
});

const ProgressBar = ({ duration }) => {
	const [progress, setProgress] = useState(0);
	const [isAnimating, setIsAnimating] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsAnimating(true);
		}, 1000);

		const interval = setInterval(() => {
			if (isAnimating) {
				setProgress((prev) => {
					const nextProgress = prev + 1;
					if (nextProgress >= 100) {
						clearInterval(interval);
						return 100;
					}
					return nextProgress;
				});
			}
		}, duration / 100);

		return () => {
			clearInterval(interval);
			clearTimeout(timeout);
		};
	}, [duration, isAnimating]);

	return (
		<>
			<div className="relative inline-flex items-center h-[80px] w-[56px]">
				<div className="z-[2] scan-progress-details absolute flex flex-col items-center justify-center w-full gap-1 text-white">
					<IconComic size={48} />
				</div>
				<div className="z-[1] scan-progress-container relative inline-flex items-end h-full w-full p-1 border-2 border-white/30 overflow-hidden">
					<div
						className="flex w-full items-center justify-center h-auto bg-mvmain-gradient transition-all duration-300 ease-linear"
						style={{ height: `${progress}%` }}
					/>
				</div>
			</div>

			{/*<div className="flex justify-center items-center bg-gray-100">
        <div className="relative w-[500px] h-[100px]">
          {Array.from({ length: 10 }).map((_, index) => (
            <div
              key={index}
              className={`absolute w-8 h-8 bg-blue-500 top-1/2 transform -translate-y-1/2 animate-move`}
              style={{
                left: `${index * 10}%`,
                animationDelay: `${index * -0.2}s`,
              }}
            ></div>
          ))}
        </div>
      </div>*/}

			<div className="relative inline-flex flex-col items-center h-auto w-[56px]">
				<div className="relative inline-flex flex-col h-full w-full border-2 border-white/30 overflow-hidden">
					<div className="comics-block flex flex-row w-full h-full overflow-visible">
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
						<div className="inline-flex shrink-0 grow min-w-full min-h-full p-1">
							<img
								className="block w-[44px] h-auto"
								src="/sample-comics-cover.jpg"
								alt="Macroverse"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
