import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconSwipe = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 34 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M8.85488 33.7812C8.01622 33.7812 7.21164 33.4474 6.61862 32.8536C6.02559 32.2598 5.69272 31.4548 5.69272 30.6151V3.16611C5.69272 2.32641 6.02559 1.52082 6.61862 0.927057C7.21164 0.333296 8.01622 0 8.85488 0H25.3487C26.1873 0 26.9914 0.333296 27.5844 0.927057C28.1774 1.52082 28.5108 2.32641 28.5108 3.16611V30.6151C28.5108 31.4548 28.1774 32.2598 27.5844 32.8536C26.9914 33.4474 26.1873 33.7812 25.3487 33.7812H8.85488ZM31.7275 3.36348C32.318 3.45284 32.8606 3.74119 33.2649 4.18129C33.6692 4.62139 33.9108 5.18679 33.9504 5.78342V27.9101C33.9005 28.5032 33.655 29.0625 33.2525 29.5004C32.8501 29.9383 32.3136 30.23 31.7275 30.3291V3.36348ZM0.253632 27.8787V5.75044C0.303405 5.15737 0.54904 4.59761 0.951531 4.15965C1.35402 3.72169 1.89036 3.43043 2.47651 3.33153V30.3301C1.8856 30.229 1.34573 29.9324 0.942781 29.4881C0.539829 29.0437 0.297152 28.4773 0.253632 27.8787Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconSwipe;
