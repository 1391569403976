import type { Asset } from "@/types/asset.ts";

const assetUrl =
	import.meta.env.VITE_PUBLIC_ASSET_URL || "http://localhost:8080/files";

export function getAssetUrl(filename: string) {
	return `${assetUrl}/${filename}`;
}

export function getAssetByCollectionName(
	assets: Asset[],
	collectionName: string,
) {
	const asset = assets.find((asset) => asset.collectionName === collectionName);

	if (asset) {
		return `${assetUrl}/${asset.name}`;
	}

	return null;
}
