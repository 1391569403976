import { useState } from "react";

type Errors = string[];
type SetErrorFn = (err: {
	message?: string;
	errors?: Record<string, string[]>;
}) => void;
type ClearErrorFn = () => void;
type GetFirstErrorFn = () => string | false;

export function useValidationError(): [
	Errors,
	SetErrorFn,
	ClearErrorFn,
	GetFirstErrorFn,
] {
	const [errors, setErrors] = useState<Errors>([]);

	const setErrorFn: SetErrorFn = (err) => {
		const newErrors: string[] = [];

		if (err.message) {
			newErrors.push(err.message);
		}

		if (err.errors) {
			for (const key in err.errors) {
				if (err.errors[key] && err.errors[key]?.length > 0) {
					newErrors.push(...err.errors[key]);
				}
			}
		}

		setErrors(newErrors);
	};

	const clearErrorFn: ClearErrorFn = () => {
		setErrors([]);
	};

	// Returns the first error, or false if no errors exist
	const getFirstError: GetFirstErrorFn = () => {
		return errors.length > 0 ? errors[0] : false;
	};

	return [errors, setErrorFn, clearErrorFn, getFirstError];
}
