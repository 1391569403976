import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconGift = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 21 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<g id="Icons/MV">
				<g id="Icon_Gift">
					<path
						d="M1.45578 18.1282C1.45578 18.6246 1.65317 19.1007 2.00453 19.4518C2.35588 19.8028 2.83243 20 3.32932 20H17.6887C18.1856 20 18.6621 19.8028 19.0135 19.4518C19.3648 19.1007 19.5622 18.6246 19.5622 18.1282V10.9456H1.45578V18.1282Z"
						fill={color}
					/>
					<path
						d="M18.6418 4.56528H17.0771C17.3011 4.0733 17.3978 3.53302 17.3585 2.99396C17.3192 2.45491 17.1451 1.93434 16.8521 1.48C16.5591 1.02565 16.1566 0.652066 15.6815 0.393498C15.2064 0.134931 14.674 -0.000343637 14.133 7.87654e-05C12.2782 7.87654e-05 11.1503 1.54736 10.5 2.98002C9.86904 1.54736 8.74051 7.87654e-05 6.88631 7.87654e-05C6.34364 -0.00370808 5.80874 0.129109 5.33098 0.386276C4.85322 0.643443 4.44798 1.01668 4.15265 1.47155C3.85732 1.92641 3.68141 2.44827 3.64114 2.98896C3.60088 3.52965 3.69756 4.07176 3.92226 4.56528H2.37354C1.87665 4.56528 1.4001 4.76249 1.04875 5.11352C0.69739 5.46455 0.5 5.94064 0.5 6.43707V9.03586H20.5V6.43707C20.5 5.94064 20.3026 5.46455 19.9513 5.11352C19.5999 4.76249 19.1234 4.56528 18.6265 4.56528M14.133 1.90985C14.321 1.88716 14.5117 1.90456 14.6924 1.96091C14.8732 2.01727 15.0399 2.11129 15.1816 2.23677C15.3233 2.36226 15.4367 2.51634 15.5144 2.68886C15.5921 2.86138 15.6323 3.0484 15.6323 3.23757C15.6323 3.42674 15.5921 3.61376 15.5144 3.78628C15.4367 3.95879 15.3233 4.11288 15.1816 4.23836C15.0399 4.36385 14.8732 4.45787 14.6924 4.51422C14.5117 4.57058 14.321 4.58798 14.133 4.56528H11.932C12.3142 3.38117 13.0599 1.90985 14.133 1.90985ZM5.54769 3.24723C5.54769 2.89254 5.68872 2.55237 5.93976 2.30156C6.1908 2.05076 6.53129 1.90985 6.88631 1.90985C7.95681 1.90985 8.70249 3.3805 9.08734 4.56528H6.88631C6.53129 4.56528 6.1908 4.42438 5.93976 4.17357C5.68872 3.92277 5.54769 3.5826 5.54769 3.22791"
						fill={color}
					/>
				</g>
			</g>
		</svg>
	</div>
);

export default IconGift;
