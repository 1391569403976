import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconDotsHor = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 31 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M15.936 18.6172C18.0395 18.6172 19.7446 16.912 19.7446 14.8086C19.7446 12.7052 18.0395 11 15.936 11C13.8326 11 12.1274 12.7052 12.1274 14.8086C12.1274 16.912 13.8326 18.6172 15.936 18.6172Z"
				fill={color}
			/>
			<path
				d="M27.1274 18.6172C29.2309 18.6172 30.936 16.912 30.936 14.8086C30.936 12.7052 29.2309 11 27.1274 11C25.024 11 23.3188 12.7052 23.3188 14.8086C23.3188 16.912 25.024 18.6172 27.1274 18.6172Z"
				fill={color}
			/>
			<path
				d="M4.74463 18.6172C6.84806 18.6172 8.55322 16.912 8.55322 14.8086C8.55322 12.7052 6.84806 11 4.74463 11C2.6412 11 0.936035 12.7052 0.936035 14.8086C0.936035 16.912 2.6412 18.6172 4.74463 18.6172Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconDotsHor;
