import { Button, ButtonLabel } from "@/components/core/buttons/button";
import Alert from "@/components/core/feedbacks/alert";
import {
	Form,
	FormField,
	FormGroup,
	FormItem,
	FormMessage,
} from "@/components/core/forms/form.tsx";
import { Input } from "@/components/core/forms/input.tsx";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import { useModal } from "@/components/core/modals/use-modal.ts";
import ForgotPasswordModal from "@/components/forgot-password-modal.tsx";
import LoginSocialsModal from "@/components/login-socials-modal.tsx";
import { IconWarningTriangle } from "@/components/mvicons";
import RegisterModal from "@/components/register-modal.tsx";
import { useAuth } from "@/hooks/use-auth.tsx";
import { useValidationError } from "@/hooks/use-validation-errors.ts";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";

export default function AuthModal() {
	const modal = useModal();
	const { login } = useAuth();
	const [errors, setErrors, clearError, getFirstError] = useValidationError();
	const navigate = useNavigate({
		from: "/",
	});
	const { closeModal } = useModalControl();
	const form = useForm({
		defaultValues: {
			username: "",
			password: "",
		},
	});

	const handleSubmit = async (values: {
		username: string;
		password: string;
	}) => {
		try {
			clearError();

			await login(values);

			await navigate({
				to: "/welcome",
			});

			closeModal();
		} catch (e) {
			form.setValue("password", "");

			if (e instanceof AxiosError) {
				setErrors(e.response?.data);
			}
		}
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<div className="flex flex-col justify-center items-center w-full">
					<div className="flex flex-col w-full max-w-[380px] gap-8">
						<div className="flex flex-col justify-center items-center w-full gap-4">
							<div className="inline-flex flex-row items-center gap-2">
								<span className="text-lg sm:text-xl text-white">Welcome</span>
								<div className="w-[38px] sm:w-[44px]">
									<img
										className="block w-full"
										src="/svg/profile-macronaut.svg"
										alt=""
									/>
								</div>
								<span className="text-lg sm:text-xl text-white">Macronaut</span>
							</div>
							<div className="flex flex-col justify-center items-center w-full gap-2">
								<span className="font-quagmire text-center font-bold text-2xl text-white uppercase leading-7">
									Suit up and Let's Go...
								</span>
								<span className="font-quagmire text-center font-bold text-2xl text-mvred-600 uppercase  leading-7">
									Adventure awaits!
								</span>
							</div>
						</div>

						{!!errors.length && (
							<Alert
								type="error"
								allowClose={false}
								icon={<IconWarningTriangle size={28} />}
							>
								{getFirstError()}
							</Alert>
						)}

						<div className="flex flex-col w-full divide-y divide-white/20">
							<div className="flex flex-col w-full gap-4 pb-6">
								<div className="grid grid-cols-12 w-full gap-3">
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="username"
											render={({ field }) => (
												<FormItem>
													<FormGroup>
														<Input
															autoComplete="off"
															placeholder="Email Address"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full">
										<FormField
											control={form.control}
											name="password"
											render={({ field }) => (
												<FormItem>
													<FormGroup>
														<Input
															type="password"
															placeholder="Password"
															{...field}
														/>
													</FormGroup>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="col-span-12 flex flex-col w-full mt-2">
										<Button
											isLoading={form.formState.isSubmitting}
											className="font-quagmire bg-mvdark-950 uppercase w-full"
											type="submit"
											variant="gradient"
											size="lg"
										>
											<ButtonLabel>Login</ButtonLabel>
										</Button>
									</div>
								</div>
								<div className="flex flex-col justify-center items-center w-full mt-2">
									<button
										onClick={() => {
											closeModal();

											modal.openModal({
												title: "Forgot Passowrd",
												component: <ForgotPasswordModal />,
											});
										}}
										type="button"
										className="font-semibold text-md text-white"
									>
										Forgot Password?
									</button>
									<p className="">
										<span className="text-md text-white/70">
											Need an account?{" "}
										</span>
										<button
											onClick={() => {
												closeModal();

												modal.openModal({
													title: "Register Modal",
													component: <RegisterModal />,
												});
											}}
											type="button"
											className="font-semibold text-md text-white uppercase"
										>
											Register
										</button>
									</p>
								</div>
							</div>
							<div className="flex flex-col w-full gap-3 pt-6">
								<Button
									onClick={() => {
										closeModal();
										modal.openModal({
											title: "Login Socials Modal",
											component: <LoginSocialsModal />,
										});
									}}
									className="font-quagmire w-full uppercase"
									type="button"
									variant="outline"
									color="blue"
								>
									<ButtonLabel>Login with socials</ButtonLabel>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</Form>
	);
}
