import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconMacronaut = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M4.79435 27.5064L11.7034 29.7543V25.4751C9.78706 24.0667 8.54031 21.7982 8.54031 19.2432C8.54031 14.98 12.0091 11.5117 16.2727 11.5117H16.3076V1.62347C12.6901 3.21675 9.65223 5.89125 7.6072 9.23625V0H4.79435V27.5064Z"
				fill={color}
			/>
			<path
				d="M28.9595 11.5118H25.4463V0.0515671C26.6513 0.139036 27.8256 0.338161 28.9595 0.638161V11.5118Z"
				fill={color}
			/>
			<path
				d="M31.8085 26.9745H16.2727C15.6684 26.9745 15.0809 26.9026 14.5162 26.7709V31.1606C16.9634 33.8289 20.406 35.3495 24.04 35.3495C27.6747 35.3495 31.118 33.8283 33.5652 31.1592V26.7708C33.0003 26.9026 32.4129 26.9745 31.8085 26.9745Z"
				fill={color}
			/>
			<path
				d="M25.4463 46.8355V38.0983C29.2499 37.7587 32.7968 36.0506 35.439 33.2726L38.0897 33.9871C39.6945 34.4198 41.1043 35.2665 42.218 36.3923L25.4463 46.8355Z"
				fill={color}
			/>
			<path
				d="M44.8799 42.8492V48H28.8973L43.8709 38.6763C44.518 39.9396 44.8799 41.3619 44.8799 42.8492Z"
				fill={color}
			/>
			<path
				d="M4.1941 38.7054C3.55634 39.9614 3.19995 41.3733 3.19995 42.8492V48H19.121L4.1941 38.7054Z"
				fill={color}
			/>
			<path
				d="M22.6335 38.0983V46.8739L5.83791 36.4158C6.95517 35.2781 8.37397 34.4229 9.99013 33.9872L12.6408 33.2727C15.2831 36.0506 18.8299 37.7588 22.6335 38.0983Z"
				fill={color}
			/>
			<path
				d="M22.6335 0.0516586C21.4286 0.139127 20.2542 0.338252 19.1204 0.638252V11.5117H22.6335V0.0516586Z"
				fill={color}
			/>
			<path
				d="M31.8086 24.1621H16.2727C13.56 24.1621 11.3531 21.9555 11.3532 19.2431C11.3532 16.5308 13.56 14.3241 16.2727 14.3241H31.8086C34.5213 14.3241 36.7282 16.5307 36.7282 19.2431C36.7282 21.9554 34.5213 24.1621 31.8086 24.1621Z"
				fill={color}
			/>
			<path
				d="M31.7724 1.62337C35.3898 3.21675 38.4276 5.89115 40.4727 9.23625L40.4728 0H43.2856V27.5065L36.378 29.7539V25.4751C38.2942 24.0666 39.541 21.7981 39.541 19.2431C39.541 14.9799 36.0723 11.5117 31.8086 11.5117H31.7724V1.62337Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconMacronaut;
