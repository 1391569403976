import { userQueryOptions } from "@/api/queries/user-queries.ts";
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from "@/components/core/display/avatar.tsx";
import { NotFound } from "@/components/errors/not-found.tsx";
import {
	IconCheckCircle,
	IconCheckCircleFilled,
	IconCollections,
	IconExp,
	IconGift,
	IconLock,
	IconUser,
	IconUserBio,
} from "@/components/mvicons";
import { formatDate } from "@/utils/format-date.ts";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_public/user_/$username/about")({
	component: Page,
	errorComponent: () => <NotFound />,
});

const UsersList = () => {
	const usersData = [
		{ name: "John", lastname: "Doe", avatar: "/avatar/1.png" },
		{ name: "Jane", lastname: "Smith", avatar: null },
		{ name: "Alex", lastname: "Johnson", avatar: "/avatar/10.png" },
		{ name: "Emily", lastname: "Davis", avatar: null },
	];

	return (
		<div className="inline-flex flex-row w-full shrink grow pl-4">
			{usersData.map((user, index) => (
				<Avatar
					key={index}
					className="w-[44px] h-[44px] -ml-4 rounded-full border-2 border-white bg-mvlogo-gradient shadow shadow-black"
					style={{ zIndex: usersData.length - index }}
				>
					{user.avatar ? (
						<AvatarImage src={user.avatar} />
					) : (
						<AvatarFallback>
							{user.name[0]}
							{user.lastname[0]}
						</AvatarFallback>
					)}
				</Avatar>
			))}
		</div>
	);
};

function Page() {
	const params = Route.useParams();
	const userQuery = useSuspenseQuery(userQueryOptions(params.username));
	const user = userQuery.data;

	const CollectionStats = () => {
		const statsData = [
			{
				value: 0,
				label: "Quests",
				icon: <IconExp size={18} />,
				isLocked: false,
			},
			{
				value: 0,
				label: "Rewards",
				icon: <IconGift size={16} />,
				isLocked: true,
			},
			{
				value: 0,
				label: "Collections",
				icon: <IconCollections size={16} />,
				isLocked: false,
			},
			{
				value: 0,
				label: "Fan of",
				icon: <IconUser size={16} />,
				isLocked: true,
			},
			{
				value: 0,
				label: "Followers",
				icon: <IconCheckCircleFilled size={16} />,
				isLocked: false,
			},
			{
				value: 0,
				label: "Following",
				icon: <IconCheckCircle size={16} />,
				isLocked: false,
			},
		];

		return (
			<div className="grid grid-cols-12 shrink-0 w-full sm:w-[320px] md:w-[380px] gap-2">
				{statsData.map((item, index) => (
					<div
						key={index}
						className="relative col-span-6 flex flex-col justify-center items-center w-full p-4 gap-1 rounded-xl bg-white bg-opacity-5 overflow-hidden"
					>
						{item.isLocked ? (
							<IconLock className="text-white" size={24} />
						) : (
							<span className="text-lg font-bold leading-6 text-white">
								{item.value}
							</span>
						)}
						{item.isLocked ? (
							<span className="text-sm text-white/50">Coming Soon</span>
						) : (
							<div className="inline-flex flex-row items-center w-auto gap-1 text-white opacity-50">
								{item.icon}
								<span className="text-sm">{item.label}</span>
							</div>
						)}
						{item.isLocked && (
							<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[80px] h-[80px] rounded-full blur-2xl bg-white/50" />
						)}
					</div>
				))}
			</div>
		);
	};

	return (
		<div className="flex flex-col w-full gap-6">
			<div className="flex flex-col w-full gap-2">
				<div className="flex flex-row items-center justify-between gap-2">
					<span className="text-sm font-semibold text-white">Bio</span>
					<span className="text-sm text-white/50">
						Joined {formatDate(user.createdAt, "PP")}
					</span>
				</div>

				<div className="flex flex-col sm:flex-row w-full gap-2">
					<div className="flex flex-row w-full rounded-xl min-h-[240px] gap-4 p-6 bg-white bg-opacity-5">
						{user.bio ? (
							<div
								// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
								dangerouslySetInnerHTML={{
									__html: user.bio || "I'm a macronaut.",
								}}
								className="flex flex-col w-full gap-2 text-white"
							/>
						) : (
							<div className="flex flex-col w-full gap-4">
								<div className="flex flex-row w-full gap-4">
									<div className="inline-flex text-white">
										<IconUserBio size={38} />
									</div>
									<div className="flex flex-col w-full gap-4">
										<div className="flex flex-col w-full gap-3">
											<span className="text-sm font-semibold text-white leading-tight">
												{user.username || user.macronautId} hasn't added a Bio
												yet...
											</span>
											<span className="text-sm text-white/70 leading-tight">
												They are clearly endlessly fascinating, smart,
												attractive and at the moment... quite mysterious.
											</span>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>

					<CollectionStats />
				</div>

				{!!user?.followerCount && (
					<div className="flex flex-col w-full rounded-xl gap-2 p-6 bg-white bg-opacity-5">
						<div className="inline-flex flex-row items-center gap-2">
							<IconCheckCircleFilled className="text-white" size={18} />
							<span className="text-white text-sm">{user.followerCount}</span>
							<span className="text-white/50 text-sm">Followers</span>
						</div>
						<div className="flex flex-row items-center w-full gap-4">
							<UsersList />
							<div className="inline-flex justify-center items-center h-[44px] px-4 rounded-2xl bg-white bg-opacity-5">
								<span className="text-white text-xs">0</span>
							</div>
						</div>
					</div>
				)}

				{!!user?.followingCount && (
					<div className="flex flex-col w-full rounded-xl gap-2 p-6 bg-white bg-opacity-5">
						<div className="inline-flex flex-row items-center gap-2">
							<IconCheckCircle className="text-white" size={18} />
							<span className="text-white text-sm">0</span>
							<span className="text-white/50 text-sm">Following</span>
						</div>
						<div className="flex flex-row items-center w-full gap-4">
							<UsersList />
							<div className="inline-flex justify-center items-center h-[44px] px-4 rounded-2xl bg-white bg-opacity-5">
								<span className="text-white text-xs">0</span>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
