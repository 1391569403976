import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconCollections = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M2.781 25.141V5.189H2.322C1.70617 5.189 1.11556 5.43364 0.680098 5.8691C0.244639 6.30456 0 6.89517 0 7.511V27.689C0 27.9939 0.0600603 28.2959 0.176752 28.5776C0.293443 28.8593 0.46448 29.1153 0.680098 29.3309C0.895716 29.5465 1.15169 29.7176 1.43341 29.8342C1.71513 29.9509 2.01707 30.011 2.322 30.011H22.475C22.7802 30.0114 23.0824 29.9516 23.3645 29.8351C23.6466 29.7186 23.9029 29.5476 24.1188 29.332C24.3348 29.1163 24.5061 28.8602 24.623 28.5783C24.7398 28.2964 24.8 27.9942 24.8 27.689V27.173H4.816C4.27628 27.173 3.75867 26.9586 3.37704 26.577C2.9954 26.1953 2.781 25.6777 2.781 25.138"
				fill={color}
			/>
			<path
				d="M27.606 1.93816e-06H7.453C6.83717 1.93816e-06 6.24656 0.244641 5.8111 0.6801C5.37564 1.11556 5.131 1.70617 5.131 2.322V22.475C5.13061 22.7802 5.19037 23.0824 5.30689 23.3645C5.4234 23.6466 5.59438 23.9029 5.81004 24.1188C6.02569 24.3348 6.2818 24.5061 6.56372 24.623C6.84563 24.7398 7.14782 24.8 7.453 24.8H27.606C27.9114 24.8004 28.2139 24.7405 28.4962 24.6238C28.7785 24.5071 29.0349 24.3359 29.2509 24.1199C29.4669 23.9039 29.6381 23.6475 29.7548 23.3652C29.8715 23.0829 29.9314 22.7804 29.931 22.475V2.322C29.931 2.01682 29.8708 1.71463 29.754 1.43272C29.6371 1.1508 29.4658 0.894695 29.2498 0.679038C29.0339 0.463382 28.7776 0.292407 28.4955 0.175892C28.2134 0.059377 27.9112 -0.000392354 27.606 1.93816e-06Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconCollections;
