import type { AxiosError } from "axios";

function formatError(error: AxiosError) {
	if (error.response) {
		return error.response.data.message;
	}

	return error.message;
}

export { formatError };
