import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import { questQueryOptions } from "@/api/queries/quest-queries.ts";
import ExpTimer from "@/components/exp-timer.tsx";
import SingleQuestCard from "@/components/single-quest-card.tsx";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function WelcomeModal() {
	const profileQuery = useSuspenseQuery(profileQueryOptions());
	const profile = profileQuery.data;
	const questQuery = useQuery(
		questQueryOptions({
			tags: "onboarding",
		}),
	);
	const quests = questQuery.data;

	return (
		<div className="flex flex-col w-full px-4 sm:px-8 py-4 gap-8 overflow-hidden">
			<div className="flex flex-col justify-center items-center w-full gap-4">
				<div className="inline-flex flex-row items-center gap-2 text-white">
					<span className="font-semibold text-lg sm:text-2xl text-white">
						Welcome
					</span>
					<div className="w-[36px] sm:w-[44px]">
						<img
							className="block w-full"
							src="/svg/profile-macronaut.svg"
							alt=""
						/>
					</div>
					<span className="font-semibold text-lg sm:text-2xl text-white">
						Macronaut
					</span>
				</div>
			</div>

			<div className="flex flex-col w-full gap-4">
				<p className="font-bold text-md text-white">
					Thanks for joining the mission!
				</p>
				<div className="flex flex-col w-full">
					<p className=" text-md text-white/50">
						Together, we&apos;re building a universe of storytelling and we
						can&apos;t do it without you!
					</p>
					<p className=" text-md text-white/50">
						Let&apos;s get you some rewards while you set-up your Macroverse
						account.
					</p>
				</div>
				<p className="font-bold text-md text-white">
					Where do you want to start?
				</p>
			</div>

			<div className="flex flex-row justify-start sm:justify-center w-full gap-4 overflow-visible">
				<Swiper
					slidesPerView="auto"
					spaceBetween={20}
					pagination={{
						enabled: false,
						clickable: true,
					}}
					modules={[Pagination]}
					className="welcome-swiper overflow-visible w-full"
				>
					<SwiperSlide>
						<div className="flex flex-col justify-center items-center min-w-[210px] w-[210px] max-w-[210px] min-h-[300px] h-full rounded-4xl bg-white bg-opacity-5 px-4 text-white/50">
							<ExpTimer duration={10} />
						</div>
					</SwiperSlide>
					{quests?.items?.map((quest) => (
						<SwiperSlide
							key={quest.id}
							className=" min-w-[210px] w-[210px] max-w-[210px]"
						>
							<SingleQuestCard
								quest={quest}
								onAction={async (quest) => {
									await navigate({
										to: quest.metadata.actionLink,
									});
								}}
								onClaim={async () => {
									// await navigate({
									//  to: "/profile",
									// });
								}}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>

			<div className="flex flex-col w-full gap-4">
				<p className="text-md text-white/50">
					We&apos;ll be adding a ton of exciting new content, features and
					creators over the next weeks and months and we look forward to sharing
					the adventure.
				</p>
				<p className="font-bold text-md text-white">
					Suit up and Let&apos;s Go!
				</p>
				<p className="font-bold italic text-md text-white">
					Eben, Adam & Ricci
				</p>
			</div>
		</div>
	);
}
