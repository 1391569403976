import type React from "react";

interface CustomToastProps {
	message: string;
	icon?: React.ReactNode;
}

function CustomToast({ message, icon }: CustomToastProps) {
	return (
		<div className="inline-flex flex-col justify-center items-center min-w-[200px] min-h-[200px] p-6 gap-4 rounded-4xl border border-white/20 bg-black bg-opacity-95 backdrop-blur shadow-xl">
			<div className="inline-flex text-white">{icon}</div>
			<span className="font-aller text-center text-white text-sm">
				{message}
			</span>
		</div>
	);
}

export default CustomToast;
