import * as SwitchPrimitives from "@radix-ui/react-switch";
import clsx from "clsx";
import React from "react";

const Switch = React.forwardRef<
	React.ElementRef<typeof SwitchPrimitives.Root>,
	React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
	<SwitchPrimitives.Root
		className={clsx(
			"w-[40px] h-[16px] bg-neutral-600 rounded-full relative data-[state=checked]:bg-mvblue-300 data-[disabled]:grayscale data-[disabled]:opacity-50 outline-none cursor-default",
			className,
		)}
		{...props}
		ref={ref}
	>
		<SwitchPrimitives.Thumb
			className={clsx(
				"block w-[24px] h-[24px] -ml-[4px]  -translate-y-[4px] bg-white rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[24px] ",
			)}
		/>
	</SwitchPrimitives.Root>
));

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
