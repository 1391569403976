import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconComic = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M3.87892 3.6323H3V27.1845C9.63187 21.6118 11.5047 21.5255 20.5785 21.2254V0C18.5184 0.748433 15.9753 3.6323 3.87892 3.6323ZM17.5315 10.8846C13.6641 11.9531 8.625 12.0703 5.46104 12.3418V6.5808C9.21094 6.32812 13.8616 5.84344 17.5315 4.62197V10.8846ZM3 22.5838V30H3.87892C14.7728 30 19.4616 26.5591 20.5785 26.1486V20.6401C14.6126 20.8521 7.12036 18.0055 3 22.5838Z"
				fill={color}
			/>
			<path
				d="M22.3363 1.87446V27.2355C20.706 28.0132 18.9828 29.0471 16.1288 30H24.0942V1.87446H22.3363Z"
				fill={color}
			/>
			<path d="M25.852 3.6323V30H27.6099V3.6323H25.852Z" fill={color} />
		</svg>
	</div>
);

export default IconComic;
