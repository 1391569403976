import { queryClient } from "@/query-client.ts";
import { routeTree } from "@/route-tree.gen.ts";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/app.css";
// import { NotFound } from "@/components/errors/not-found.tsx";
import { ExpProvider } from "@/components/exp-gain/exp-gain-context.tsx";
import { AuthProvider, useAuth } from "@/hooks/use-auth.tsx";
import * as Sentry from "@sentry/react";

const sentryDsn = import.meta.env.VITE_PUBLIC_SENTRY_DSN;

const router = createRouter({
	routeTree,
	context: {
		queryClient,
		auth: undefined!,
	},
	notFoundMode: "root",
	defaultPreload: "intent",
	defaultPreloadStaleTime: 0,
});

if (sentryDsn) {
	Sentry.init({
		dsn: import.meta.env.VITE_PUBLIC_SENTRY_DSN,
		integrations: [
			Sentry.tanstackRouterBrowserTracingIntegration(router),
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

function Router() {
	const auth = useAuth();

	return <RouterProvider context={{ auth }} router={router} />;
}

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<ExpProvider>
					<Router />
				</ExpProvider>
			</AuthProvider>
		</QueryClientProvider>
	);
}

const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	);
}
