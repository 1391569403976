import { apiClient } from "@/api/api-client.ts";
import type { User } from "@/types/user.ts";

const waitlistService = {
	create: async (payload: {
		email: string;
	}) => {
		const response = await apiClient.post<User>("/waitlist", payload);

		return response.data;
	},
};

export default waitlistService;
