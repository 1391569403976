import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import { ProfileHeader } from "@/components/features/profile/profile-header.tsx";
import SettingsContent from "@/components/features/settings/settings-content.tsx";
import {
	PortalContainer,
	PortalContainerBody,
	PortalContainerContent,
	PortalContainerHead,
} from "@/components/layouts/portal-container.tsx";
import { IconSettings } from "@/components/mvicons";
import PortalTopbar from "@/components/portal-topbar.tsx";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_profile/settings")({
	component: SettingsLandingComponent,
});

function SettingsLandingComponent() {
	const profileQuery = useSuspenseQuery(profileQueryOptions());
	const profile = profileQuery.data;

	return (
		<PortalContainer>
			<PortalContainerHead>
				<PortalTopbar profile={profile} />

				<ProfileHeader profile={profile} />
			</PortalContainerHead>

			<PortalContainerBody>
				<PortalContainerContent>
					<div className="relative flex flex-col w-full gap-2 mt-4">
						<div className="flex flex-row justify-center items-center w-full">
							<div className="inline-flex flex-row items-center gap-2 text-white">
								<IconSettings size={24} />
								<span className="text-xl">Settings</span>
							</div>
						</div>
						<SettingsContent />
					</div>
				</PortalContainerContent>
			</PortalContainerBody>
		</PortalContainer>
	);
}
