import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconPhantom = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="36"
			height="30"
			viewBox="0 0 36 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M4.14508 30C8.61526 30 11.9747 26.0018 13.9794 22.8425C13.7356 23.5414 13.6002 24.2404 13.6002 24.9115C13.6002 26.7568 14.6296 28.0709 16.6616 28.0709C19.452 28.0709 22.4322 25.5545 23.9764 22.8425C23.8681 23.2339 23.8139 23.5974 23.8139 23.9329C23.8139 25.219 24.5183 26.0298 25.9541 26.0298C30.4785 26.0298 35.03 17.782 35.03 10.5685C35.03 4.94874 32.2665 0 25.3311 0C13.1396 0 0 15.3216 0 25.219C0 29.1054 2.0319 30 4.14508 30ZM21.1317 9.9534C21.1317 8.55545 21.8903 7.57689 23.0012 7.57689C24.0848 7.57689 24.8434 8.55545 24.8434 9.9534C24.8434 11.3514 24.0848 12.3579 23.0012 12.3579C21.8903 12.3579 21.1317 11.3514 21.1317 9.9534ZM26.9295 9.9534C26.9295 8.55545 27.6881 7.57689 28.7988 7.57689C29.8824 7.57689 30.641 8.55545 30.641 9.9534C30.641 11.3514 29.8824 12.3579 28.7988 12.3579C27.6881 12.3579 26.9295 11.3514 26.9295 9.9534Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconPhantom;
