import { profileQueryOptions } from "@/api/queries/profile-queries.ts";
import { useSupplyClaimMutation } from "@/api/queries/supply-queries.ts";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { IconCheckCircle, IconComic, IconQrcode } from "@/components/mvicons";
import ScanProgress from "@/components/scan-progress.tsx";
import { ErrorCameraPermissionModal } from "@/components/scanner/error-camera-permission-modal.tsx";
import { ErrorClaimedModal } from "@/components/scanner/error-claimed-modal.tsx";
import { ErrorInvalidCodeModal } from "@/components/scanner/error-invalid-code-modal.tsx";
import { RequestPermissionModal } from "@/components/scanner/request-permission-modal.tsx";
import { useAuth } from "@/hooks/use-auth.tsx";
import type { Issue } from "@/types/issue.ts";
import { IconDeviceDesktopX } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { Link, createFileRoute } from "@tanstack/react-router";
import { Scanner } from "@yudiel/react-qr-scanner";
import { type ReactNode, useEffect, useState } from "react";

export const Route = createFileRoute("/agamotto_/$code")({
	loader: ({ context: { queryClient } }) => {
		queryClient.prefetchQuery(profileQueryOptions());
	},
	component: Page,
});

type ErrorResponse = {
	response: {
		data: {
			errors: {
				code: string;
			};
		};
	};
};

function Page() {
	const modal = useModal();
	const { authorize } = useAuth();
	const profile = useQuery(profileQueryOptions());
	const supplyClaimMutation = useSupplyClaimMutation();
	const [permissionGranted, setPermissionGranted] = useState(false);
	const [animationEnded, setAnimationEnded] = useState(false);
	const [issue, setIssue] = useState<Issue | null>(null);
	const [step, setStep] = useState(1);
	const [scannerPaused, setScannerPaused] = useState(false);

	// Check if the user is logged in
	useEffect(() => {
		if (profile.data && step === 1) {
			setStep(2);
		}
	}, [profile, step]);

	useEffect(() => {
		if (step > 1) {
			checkCameraPermission();
		}
	}, [step]);

	const handleResetState = () => {
		setIssue(null);
		setStep(3);
		setAnimationEnded(true);
	};

	const checkCameraPermission = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ video: true });
			if (stream) {
				setPermissionGranted(true);
			}
		} catch (error) {
			console.error("Failed to check camera permissions:", error);
		}
	};

	const requestCameraPermissions = async () => {
		try {
			await navigator.mediaDevices.getUserMedia({ video: true });
			setPermissionGranted(true);
		} catch (error) {
			modal.openModal({
				type: "confirm",
				onConfirm: async () => {},
				children: <ErrorCameraPermissionModal />,
			});
		}
	};

	const handleLogin = async () => {
		const response = await authorize();
		window.location.href = response.authUrl;
	};

	const handleScan = async (results: { rawValue: string }[]) => {
		if (results?.length && !scannerPaused) {
			const scannedCode = results[0];
			setScannerPaused(true);

			try {
				const response = await supplyClaimMutation.mutateAsync({
					code: scannedCode.rawValue,
				});
				setIssue(response.issue);
			} catch (error) {
				const typedError = error as ErrorResponse;
				const errCode = typedError.response.data.errors.code;
				let modalComponent:
					| ((handleConfirm: () => void) => ReactNode)
					| undefined;

				switch (errCode) {
					case "user_redeemed":
						modalComponent = (handleConfirm: () => void) => (
							<ErrorClaimedModal handleConfirm={handleConfirm} />
						);
						break;
					case "invalid_qr":
						modalComponent = (handleConfirm: () => void) => (
							<ErrorInvalidCodeModal handleConfirm={handleConfirm} />
						);
						break;
					default:
						return;
				}

				modal.openModal({
					type: "confirm",
					onConfirm: async () => {
						setScannerPaused(false);
					},
					children: (handleConfirm: () => void) =>
						modalComponent ? modalComponent(handleConfirm) : null,
					onClose: () => setScannerPaused(false),
				});
			}
		}
	};

	const handleRequestCameraPermission = () => {
		if (permissionGranted) {
			setStep(3);
		} else {
			modal.openModal({
				type: "confirm",
				onConfirm: async () => {
					await requestCameraPermissions();
				},
				children: (handleConfirm) => (
					<RequestPermissionModal handleConfirm={handleConfirm} />
				),
			});
		}
	};

	const customClassNames = {
		container: "scanner",
		video: "object-cover",
		finderBorder: 0,
	};

	return (
		<div className="relative flex flex-col items-center h-screen w-full bg-mvdark-950 min-h-screen gap-8">
			{/* Web view blocker */}
			<div className="z-50 fixed inset-0 hidden sm:flex flex-col justify-center items-center w-full h-full gap-4 bg-mvdark-950">
				<div className="flex flex-col justify-center items-center w-full max-w-[320px] gap-4">
					<div className="inline-flex justify-center items-center w-[64px] h-[64px] rounded-full bg-white/10 text-white">
						<IconDeviceDesktopX size={32} stroke={2} />
					</div>
					<span className="text-center text-white/70">
						This view is optimized for mobile devices. Please switch to a mobile
						device for the best experience.
					</span>
				</div>
			</div>

			{step === 1 && (
				// Step 1: Login
				<div className="relative flex flex-col justify-center items-center w-full min-h-screen overflow-hidden">
					<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
						<div className="flex flex-row justify-center items-center w-full max-w-[360px] h-auto mx-auto p-4 pt-12">
							<div className="inline-flex w-[72px]">
								<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
							</div>
						</div>
						<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-6">
							<div className="inline-flex flex-col justify-center items-center w-auto gap-2">
								<div className="inline-flex w-[300px]">
									<img
										className="block w-full"
										src="/svg/brand-authentic-editions.svg"
										alt="Macroverse"
									/>
								</div>
								<div className="inline-flex w-[90px]">
									<img
										className="block w-full"
										src="/svg/indicator-comics-scan.svg"
										alt="Macroverse"
									/>
								</div>
							</div>
							<span className="text-center font-semibold text-md text-white">
								Scan Authentic Edition QR Codes and redeem your free Digital
								Twins plus Bonus Perks and Rewards!
							</span>
							<Button
								onClick={handleLogin}
								className="font-quagmire w-full uppercase"
								type="button"
								variant="default"
								color="white"
								size="lg"
							>
								<ButtonLabel>Login and Scan</ButtonLabel>
							</Button>
						</div>
					</div>
					<div className="z-[2] absolute w-[380px] h-[380px] rounded-full blur-[100px] bg-black opacity-50" />
					<div className="z-[1] absolute inset-0 bg-mvdark-800 opacity-50" />
				</div>
			)}

			{step === 2 && (
				// Step 2: Request Camera Permission
				<div className="relative flex flex-col justify-center items-center w-full min-h-screen overflow-hidden">
					<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[420px] mx-auto p-4 gap-4">
						<div className="inline-flex flex-row items-center gap-2">
							<IconQrcode className="text-white" size={32} />
							<span className="text-white text-xl">Scan QR Code</span>
						</div>
						<div className="inline-flex flex-col justiy-center items-center">
							<span className="font-quagmire text-xl font-bold text-white uppercase leading-7">
								Get your Digital Twin
							</span>
							<span className="font-quagmire text-xl font-bold text-mvred-600 uppercase leading-7">
								+ Bonus Rewards
							</span>
						</div>
						<span className="text-center text-sm text-white">
							Each printed issue of Authentic Editions includes a QR code
							connected to a unique Digital Twin.
						</span>
						<span className="text-center text-sm text-white">
							Scan and claim this unique Digital Edition and add it to your
							collection. The physical copy is paired with the digital to create
							a bonded set.
						</span>
						<span className="text-center text-sm text-white">
							Only the owner of the physical can claim it and any additional
							perks and rewards that come with it.
						</span>
						<span className="text-center text-sm text-white">
							Let’s get started!
						</span>
						<div className="flex flex-col w-full mt-2 gap-2">
							<Button
								onClick={handleRequestCameraPermission}
								className="font-quagmire w-full backdrop-blur uppercase"
								type="button"
								variant="gradient"
								size="md"
							>
								<ButtonLabel>Scan Now</ButtonLabel>
							</Button>
							<Link to="/profile">
								<Button
									className="font-quagmire w-full backdrop-blur uppercase"
									type="button"
									variant="outline"
									size="md"
									color="blue"
								>
									<ButtonLabel>Not Now</ButtonLabel>
								</Button>
							</Link>
						</div>
					</div>
				</div>
			)}

			{step === 3 && (
				// Step 3: QR Scanner
				<div className="relative flex flex-col justify-center items-center w-full min-h-screen overflow-hidden">
					{/* Camera state overlay */}
					<div className="z-20 absolute top-12 left-1/2 -translate-x-1/2">
						<div className="flex flex-row items-center w-auto gap-2 bg-black/30 backdrop-blur py-2 px-4 rounded-full">
							<div className="inline-flex w-[14px] h-[14px] rounded-full bg-mvred-600">
								<div className="animate-ping inline-flex w-[14px] h-[14px] rounded-full bg-mvred-600" />
							</div>
							<span className="tracking-wider text-xs text-white uppercase">
								Camera is Active
							</span>
						</div>
					</div>

					{/* Scan progress overlay */}
					<div className="z-[2] absolute bottom-12 flex flex-row justify-center w-full ">
						<div className="flex flex-row items-center w-auto p-6 gap-3 rounded-lg bg-black/30 backdrop-blur">
							{issue ? (
								<ScanProgress
									onAnimationEnd={() => {
										setAnimationEnded(true);
										// This gives an extra time before loading the last screen
										setTimeout(() => {
											setStep(4);
										}, 5000);
									}}
								/>
							) : (
								<div className="relative inline-flex justify-center items-center min-h-[80px] w-[56px]">
									<div className="z-[2] inline-flex text-white">
										<IconComic size={54} />
									</div>
								</div>
							)}

							{animationEnded ? (
								<div className="flex flex-col gap-2">
									<div className="flex flex-row items-center gap-1">
										<IconQrcode className="text-mvblue-300" size={14} />
										<span className="text-xs text-mvblue-300">
											Issue Confirmed
										</span>
									</div>
									<div className="flex flex-col">
										<span className="text-xs text-white leading-tight">
											{issue?.name} #{issue?.issueNumber}
										</span>
										<span className="text-xs text-white leading-tight">
											Authentic Edition
										</span>
										<span className="text-xs text-white leading-tight">
											{issue?.releasedAt}
										</span>
									</div>
								</div>
							) : (
								<div className="flex flex-col gap-2">
									<div className="flex flex-row items-center gap-1">
										<IconQrcode className="text-mvblue-300" size={14} />
										<span className="text-xs text-mvblue-300">
											Identifying Issue...
										</span>
									</div>
									<div className="flex flex-col">
										<span className="text-xs text-white leading-tight">
											Finding Title...
										</span>
										<span className="text-xs text-white leading-tight">
											Locating Edition...
										</span>
										<span className="text-xs text-white leading-tight">
											Confirming Details...
										</span>
									</div>
								</div>
							)}
						</div>
					</div>

					{/* Main scanner UI */}
					<Scanner
						paused={scannerPaused || !!issue}
						onScan={handleScan}
						onError={() => setScannerPaused(true)}
						allowMultiple={false}
						scanDelay={2000}
						classNames={customClassNames}
					/>
				</div>
			)}

			{step === 4 && (
				// Step 4: Issue Confirmed
				<div className="relative flex flex-col justify-center items-center w-full min-h-screen overflow-hidden">
					<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
						<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-6">
							<div className="inline-flex flex-col justify-center items-center w-auto gap-2">
								<div className="inline-flex w-[180px]">
									<img
										className="block w-full"
										src="/svg/brand-macroverse-authentic-editions.svg"
										alt="Macroverse"
									/>
								</div>
							</div>
							<div className="relative inline-flex w-[160px] border-2 border-white">
								<img
									className="block w-full"
									src="/sample-comics-cover.jpg"
									alt="Macroverse"
								/>
								<div className="z-[-1] absolute inset-0 rounded-full bg-mvmain-gradient blur-2xl transition-all duration-4000 animate-spinAndScale" />
							</div>
							<div className="flex flex-col gap-1">
								<div className="flex flex-row items-center p-4 gap-2 rounded-lg bg-white/10 backdrop-blur">
									<div className="flex justify-center items-center flex-row w-[44px] h-[44px] bg-white">
										<IconQrcode className="text-black" size={32} />
									</div>
									<div className="flex flex-col gap-1">
										<div className="flex flex-row items-center gap-1">
											<IconCheckCircle className="text-mvblue-300" size={16} />
											<span className="text-xs text-mvblue-300">
												Digital Twin Paired
											</span>
										</div>
										<div className="flex flex-row items-center gap-1 p-1 px-2 rounded-md bg-white/10">
											<span className="text-xs text-mvblue-300">
												{issue?.id}
											</span>
										</div>
									</div>
								</div>
								<div className="flex flex-col py-2 px-4">
									<span className="text-center text-xs text-white leading-tight">
										{issue?.name} #{issue?.issueNumber}
									</span>
									<span className="text-center text-xs text-white leading-tight">
										Authentic Edition
									</span>
									<span className="text-center text-xs text-white leading-tight">
										{issue?.releasedAt}
									</span>
								</div>
							</div>
							<div className="flex flex-col w-full mt-2 gap-2">
								<div className="flex flex-row gap-2">
									<Button
										disabled
										className="flex-1 font-quagmire backdrop-blur uppercase"
										type="button"
										variant="outline"
										size="md"
										color="blue"
									>
										<ButtonLabel>Secure</ButtonLabel>
									</Button>
									<Button
										disabled
										className="flex-1 font-quagmire backdrop-blur uppercase"
										type="button"
										variant="outline"
										size="md"
										color="blue"
									>
										<ButtonLabel>Read</ButtonLabel>
									</Button>
								</div>
								<Button
									className="font-quagmire w-full backdrop-blur uppercase"
									type="button"
									variant="outline"
									size="md"
									color="blue"
								>
									<ButtonLabel>Share</ButtonLabel>
								</Button>
								<Button
									onClick={handleResetState}
									className="font-quagmire w-full backdrop-blur uppercase"
									type="button"
									variant="gradient"
									size="md"
								>
									<ButtonLabel>Scan More</ButtonLabel>
								</Button>
							</div>
						</div>
					</div>
					<div className="z-[1] absolute inset-0  bg-mvdark-800 opacity-50" />
				</div>
			)}
		</div>
	);
}

export default Page;
