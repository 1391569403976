import { IconComic } from "@/components/mvicons";
import { motion } from "framer-motion";

const ScanProgress = ({
	cover,
	baseDelay = 2000,
	baseDuration = 5000,
	onAnimationEnd,
}: {
	cover: string;
	baseDelay?: number;
	baseDuration?: number;
	onAnimationEnd?: () => void;
}) => {
	const iconAnimationDuration = baseDuration * 0.5; // 2500 ms
	const containerAnimationDuration = baseDuration * 0.025; // 125 ms
	const progressAnimationDuration = baseDuration * 0.5; // 2500 ms
	const comicsAnimationDuration = baseDuration * 0.5; // 2500 ms

	const iconVariants = {
		initial: {
			opacity: 1,
			scale: 1,
		},
		animate: {
			opacity: [1, 1, 1, 0],
			scale: [1, 0.5, 0.5, 0.1],
			transition: {
				duration: iconAnimationDuration / 1000,
				delay: baseDelay / 1000,
				times: [0, 0.05, 0.95, 1],
				ease: "linear",
			},
		},
		exit: {
			opacity: 0,
			scale: 0.1,
		},
	};

	const containerVariants = {
		initial: {
			opacity: 0,
			scale: 1.2,
		},
		animate: {
			opacity: [0, 1],
			scale: [1.2, 1],
			transition: {
				duration: containerAnimationDuration / 1000,
				delay: baseDelay / 1000,
				times: [0, 1],
				ease: "linear",
			},
		},
		exit: {
			opacity: 0,
			scale: 0.1,
		},
	};

	const progressVariants = {
		initial: {
			opacity: 0,
			height: 0,
		},
		animate: {
			opacity: [1, 1, 1, 1, 0],
			height: ["0%", "0%", "100%", "100%", "100%"],
			transition: {
				duration: progressAnimationDuration / 1000,
				delay: baseDelay / 1000,
				times: [0, 0.25, 0.75, 0.95, 1],
				ease: "linear",
			},
		},
		exit: {
			opacity: 0,
		},
	};

	const comicsVariants = {
		initial: {
			opacity: 0,
			filter: "grayscale(100%)",
			x: 0,
		},
		animate: {
			opacity: [0, 1, 1, 1, 1],
			filter: [
				"grayscale(100%)",
				"grayscale(100%)",
				"grayscale(100%)",
				"grayscale(100%)",
				"grayscale(0%)",
			],
			x: [0, 0, 0, "-1000%", "-1000%"],
			transition: {
				duration: comicsAnimationDuration / 1000,
				delay: (baseDelay + progressAnimationDuration) / 1000,
				times: [0, 0.1, 0.6, 0.8, 1, 1],
				ease: [0.5, 0, 0.5, 1],
			},
		},
	};

	return (
		<div className="relative inline-flex justify-center items-center min-h-[80px] w-[56px] overflow-hidden">
			<motion.div
				className="comics-icon z-[2] inline-flex text-white"
				variants={iconVariants}
				initial="initial"
				animate="animate"
				exit="exit"
			>
				<IconComic size={54} />
			</motion.div>

			<motion.div
				className="progress-container z-[1] absolute inset-0 flex flex-row items-end w-full h-full rounded-lg border-2 border-white"
				variants={containerVariants}
				initial="initial"
				animate="animate"
				exit="exit"
			>
				<motion.div className="flex flex-row items-end w-full h-full p-1 overflow-hidden">
					<motion.div
						className="progress-bar flex w-full items-center justify-center min-w-[44px] rounded bg-mvmain-gradient"
						variants={progressVariants}
						initial="initial"
						animate="animate"
						exit="exit"
					/>
					<motion.div
						className="comics-group absolute inset-0 flex flex-row h-full"
						variants={comicsVariants}
						initial="initial"
						animate="animate"
						onAnimationComplete={() => {
							if (onAnimationEnd) {
								onAnimationEnd();
							}
						}}
					>
						{Array.from({ length: 11 }).map((_, index) => (
							<div
								key={index}
								className="inline-flex shrink-0 grow min-w-[44px] min-h-full p-1"
							>
								<img
									className="block w-[44px] h-auto rounded-md"
									src={cover}
									alt="Macroverse"
								/>
							</div>
						))}
					</motion.div>
				</motion.div>
			</motion.div>
		</div>
	);
};

export default ScanProgress;
