import { blockchainTabTrigger } from "@/components/constants/tabs.ts";
import {
	Button,
	ButtonIcon,
	ButtonLabel,
} from "@/components/core/buttons/button";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/components/core/navigations/tabs.tsx";
import {
	IconAptos,
	IconInfo,
	IconMetamask,
	IconPetra,
	IconPhantom,
	IconSolana,
	IconWalletConnect,
} from "@/components/mvicons";
import IconEthereum from "@/components/mvicons/icon-ethereum.tsx";

const BlockchainSelection = () => {
	return (
		<Tabs className="pb-6" defaultValue="aptos">
			<TabsList className="border-none">
				<TabsTrigger value="aptos">
					<IconAptos size={32} />
				</TabsTrigger>
				<TabsTrigger value="solana">
					<IconSolana size={32} />
				</TabsTrigger>
				<TabsTrigger value="ethereum">
					<IconEthereum size={32} />
				</TabsTrigger>
				<TabsTrigger value="info">
					<IconInfo size={32} />
				</TabsTrigger>
			</TabsList>
			<TabsContent value="aptos">
				<div className="flex flex-col justify-center items-center w-full">
					<span className="text-md text-white mb-2">Aptos Wallet</span>
					<div className="flex flex-col w-full mt-2 gap-3">
						<Button
							className="bg-mvdark-950 w-full !gap-2"
							type="button"
							variant="default"
							size="md"
						>
							<ButtonIcon>
								<IconPetra size={24} />
							</ButtonIcon>
							<ButtonLabel>Petra</ButtonLabel>
						</Button>
					</div>
				</div>
			</TabsContent>

			<TabsContent value="solana">
				<div className="flex flex-col justify-center items-center w-full">
					<span className="text-md text-white mb-2">Solana Wallet</span>
					<div className="flex flex-col w-full mt-2 gap-3">
						<Button
							className="bg-mvdark-950 w-full !gap-2"
							type="button"
							variant="default"
							size="md"
						>
							<ButtonIcon>
								<IconPhantom size={24} />
							</ButtonIcon>
							<ButtonLabel>Phantom</ButtonLabel>
						</Button>
					</div>
				</div>
			</TabsContent>

			<TabsContent value="ethereum">
				<div className="flex flex-col justify-center items-center w-full">
					<span className="text-md text-white mb-2">Ethereum Wallet</span>
					<div className="flex flex-col w-full mt-2 gap-3">
						<Button
							className="bg-mvdark-950 w-full !gap-2"
							type="button"
							variant="default"
							size="md"
						>
							<ButtonIcon>
								<IconPhantom size={24} />
							</ButtonIcon>
							<ButtonLabel>Phantom</ButtonLabel>
						</Button>
						<Button
							className="bg-mvdark-950 w-full !gap-2"
							type="button"
							variant="default"
							size="md"
						>
							<ButtonIcon>
								<IconMetamask size={24} />
							</ButtonIcon>
							<ButtonLabel>MetaMask</ButtonLabel>
						</Button>
						<Button
							className="bg-mvdark-950 w-full !gap-2"
							type="button"
							variant="default"
							size="md"
						>
							<ButtonIcon>
								<IconWalletConnect size={24} />
							</ButtonIcon>
							<ButtonLabel>Wallet Connect</ButtonLabel>
						</Button>
					</div>
				</div>
			</TabsContent>

			<TabsContent value="info">
				<div className="flex flex-col w-full p-6 rounded-2xl bg-white bg-opacity-5 gap-3">
					<p className="font-bold text-white leading-tight">
						Connect your wallets to add them to your Macroverse profile.
					</p>
					<p className="text-white/70 leading-tight">
						Participating in and holding collectibles from across our ecosystem
						will unlock perks and rewards over time.
					</p>
					<p className="font-bold text-white leading-tight">
						More participation = more fun ;)
					</p>
				</div>
			</TabsContent>
		</Tabs>
	);
};

export default BlockchainSelection;
