import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconKickstarter = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M18.9973 15.5315C18.9973 17.9851 16.949 20.0413 14.3976 20.0094C12.9882 20.0094 11.5428 19.3466 10.6704 18.0849L9.73013 16.7593C9.29292 18.6838 7.61397 20.0094 5.46588 20.0094C2.9804 20.0094 1 18.019 1 15.5655V4.45368C0.998005 2.00015 2.94646 0.00976562 5.46388 0.00976562C7.64592 0.00976562 9.3588 1.43517 9.76207 3.39162L10.8361 1.93227C11.7105 0.738441 13.12 0.0437039 14.5314 0.0437039C16.949 0.0437039 18.9973 1.93427 18.9973 4.3878C18.9973 5.31612 18.7278 6.24443 18.1568 7.00704L16.0087 9.95768L18.1568 12.9083C18.7278 13.7049 18.9973 14.5992 18.9973 15.5275V15.5315Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconKickstarter;
