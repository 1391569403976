import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconFilter = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M17.502 30V26.472H12.5025V30H17.502ZM22.5 21.18V17.652H7.5V21.18H22.5ZM26.25 12.3555V8.8245H3.75V12.3555H26.25ZM30 3.531V0H0V3.531H30Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconFilter;
