import ErrorWorldsSwiper from "@/components/swipers/error-worlds-swiper.tsx";
import { IconAlertTriangleFilled } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/error403")({
	component: Error403,
});

function Error403() {
	return (
		<div className="font-aller relative flex w-full min-h-[100svh] bg-mvdark-950">
			<div className="z-[3] flex flex-col w-full max-w-[960px] gap-8 sm:gap-12 py-8 sm:py-24 px-6 mx-auto">
				<div className="flex flex-col w-auto">
					<span className="font-quagmire text-[28px] leading-[28px] font-bold uppercase italic text-mvred-600">
						Error
					</span>
					<span className="custom-shadow custom-shadow--403A font-quagmire text-[56px] leading-[56px] font-bold uppercase italic text-mvred-600">
						403
					</span>
					<span className="custom-shadow custom-shadow--403B font-quagmire text-[56px] leading-[56px] font-bold uppercase italic text-mvblue-300">
						Access Denied!
					</span>
				</div>
				<div className="flex flex-col w-auto gap-4">
					<div className="flex flex-row items-center gap-2">
						<IconAlertTriangleFilled className="text-yellow-400" size={28} />
						<span className="text-lg font-semibold text-white">
							Oops, Macronaut!
						</span>
					</div>
					<p className="text-md text-white/50">
						It seems this part of the Macroverse is currently locked or requires
						special permissions. Not all explorers are cleared for every sector
						just yet…
					</p>
					<p className="text-md text-white/50">
						But don’t let that stop your journey while we recalibrate access...
					</p>
				</div>
				<hr className="border-b border-mvblue-300/50" />
				<div className="flex flex-col w-full gap-6">
					<p className="text-lg font-semibold text-mvblue-300">
						Jump to one of these Worlds just waiting to be discovered:
					</p>
					<ErrorWorldsSwiper />
				</div>
				<div className="flex flex-col w-auto gap-4">
					<p className="font-semibold text-md text-white">
						Chart a new course from here, Macronaut
					</p>
					<p className="text-md text-white/50">
						<a href="/" className="text-mvblue-300 underline">
							Jump to Home Base
						</a>{" "}
						or{" "}
						<a href="/" className="text-mvblue-300 underline">
							Explore more Worlds
						</a>{" "}
						and keep your adventure going!
					</p>
				</div>
			</div>

			<div className="z-[2] absolute error-page-gradient inset-0 blur-[25px] overflow-hidden" />
		</div>
	);
}
