import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconExp = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M19.8852 13.2261H17.9428V11.0714H19.8852C20.1187 11.0714 20.3097 11.1138 20.4583 11.1987C20.614 11.2836 20.7308 11.4039 20.8086 11.5596C20.8865 11.7153 20.9254 11.9099 20.9254 12.1434C20.9254 12.4831 20.8334 12.7484 20.6494 12.9395C20.4725 13.1305 20.2178 13.2261 19.8852 13.2261Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.3527 2.98445C9.46319 2.98445 8.64108 3.45831 8.19524 4.22797L3.60184 12.1576C3.15407 12.9306 3.15407 13.8841 3.60184 14.6571L8.19524 22.5867C8.64108 23.3564 9.46318 23.8303 10.3527 23.8303H19.5581C20.4476 23.8303 21.2697 23.3564 21.7155 22.5867L26.3089 14.6571C26.7567 13.8841 26.7567 12.9306 26.3089 12.1576L21.7155 4.22797C21.2697 3.45831 20.4476 2.98445 19.5581 2.98445L10.3527 2.98445ZM10.7776 13.2261L8.15584 17.0578H9.98149L11.7222 14.3618H11.7753L13.5267 17.0578H15.4585L12.8367 13.2261L15.2143 9.77642H13.3993L11.9027 12.0797H11.8496L10.3424 9.77642H8.41059L10.7776 13.2261ZM16.3612 9.77642V17.0578H17.9428V14.521H20.0975C20.6211 14.521 21.0634 14.4219 21.4242 14.2238C21.7851 14.0257 22.0576 13.7497 22.2415 13.3959C22.4326 13.035 22.5281 12.614 22.5281 12.1328C22.5281 11.6516 22.4361 11.2377 22.2522 10.8909C22.0753 10.5371 21.8099 10.2647 21.4561 10.0736C21.1094 9.87549 20.6742 9.77642 20.1505 9.77642H16.3612Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.5634 11.7842C30.144 12.7885 30.144 14.0264 29.5634 15.0307L23.6872 25.195C23.1076 26.1975 22.0374 26.815 20.8794 26.815H9.11944C7.96142 26.815 6.89125 26.1975 6.31165 25.195L0.435455 15.0307C-0.145151 14.0264 -0.145152 12.7885 0.435454 11.7842L6.31166 1.61999C6.89125 0.617448 7.96142 0 9.11944 0H20.8794C22.0374 0 23.1076 0.617448 23.6872 1.61999L29.5634 11.7842ZM6.89728 3.4761C7.61135 2.24339 8.92806 1.48445 10.3527 1.48445L19.5581 1.48445C20.9827 1.48445 22.2994 2.24339 23.0135 3.4761L27.6069 11.4058C28.324 12.6438 28.324 14.1709 27.6069 15.4089L23.0135 23.3386C22.2994 24.5713 20.9827 25.3303 19.5581 25.3303H10.3527C8.92806 25.3303 7.61135 24.5713 6.89728 23.3386L2.30388 15.4089C1.58672 14.1709 1.58672 12.6438 2.30388 11.4058L6.89728 3.4761Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconExp;
