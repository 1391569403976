import { IconPlus, IconQrcode } from "@/components/mvicons";
import IconCollections from "@/components/mvicons/icon-collections.tsx";
import Reader from "@/components/reader/reader.tsx";
import Scan from "@/components/scanner/scanner.tsx";
import type { Collection } from "@/types/collection.ts";
import { getAssetByCollectionName } from "@/utils/asset-url.ts";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Masonry from "react-masonry-css";

export const CollectionItems = ({
	items = [],
	showAllItems,
}: {
	items?: Collection[];
	showAllItems: boolean;
}) => {
	const [isScanOpen, setScanOpen] = useState(false);
	const [isReaderOpen, setReaderOpen] = useState(false);

	const filteredItems = showAllItems
		? items
		: items?.filter((item) => item.isOwned);

	const notAvailableAnimation = {
		initial: { opacity: 0, scale: 0.7 },
		animate: { opacity: 1, scale: 1 },
		exit: { opacity: 0, scale: 0.7 },
		transition: { duration: 0.3 },
	};

	const breakpointColumnsObj = {
		default: 5,
		1024: 5,
		769: 4,
		641: 3,
	};

	return (
		<div className="flex flex-col w-full">
			{isReaderOpen && (
				<Reader isOpen={isReaderOpen} onClose={() => setReaderOpen(false)} />
			)}
			{isScanOpen && (
				<Scan isOpen={isScanOpen} onClose={() => setScanOpen(false)} />
			)}
			<AnimatePresence>
				<Masonry
					breakpointCols={breakpointColumnsObj}
					className="my-masonry-grid"
					columnClassName="my-masonry-grid_column"
				>
					{filteredItems?.map(({ id, name, assets, isOwned }) => (
						<motion.div
							key={id}
							className={`relative group inline-flex bg-white bg-opacity-5 rounded-xl ${isOwned ? "grayscale-0" : "grayscale"}`}
							initial={isOwned ? undefined : notAvailableAnimation.initial}
							animate={isOwned ? undefined : notAvailableAnimation.animate}
							exit={isOwned ? undefined : notAvailableAnimation.exit}
							transition={
								isOwned ? undefined : notAvailableAnimation.transition
							}
							layout
						>
							<div className="absolute inset-0 flex flex-col w-full h-full p-2 gap-2 bg-black/50 transition-all opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
								{isOwned ? (
									<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
										<IconCollections size={14} />
										<span className="text-xs font-semibold uppercase">
											You own this
										</span>
									</div>
								) : (
									<>
										<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
											<IconPlus size={12} />
											<span className="text-xs font-semibold uppercase">
												Available Now
											</span>
										</div>
										<button
											onClick={() => setScanOpen(true)}
											type="button"
											className="inline-flex flex-row items-center w-auto mt-auto ml-auto rounded-md p-1 bg-white/20 backdrop-blur text-white"
										>
											<IconQrcode size={32} />
										</button>
									</>
								)}
							</div>
							{assets && assets?.length !== 0 && (
								<img
									className="block w-full"
									src={getAssetByCollectionName(assets, "cover_image")}
									alt={name}
								/>
							)}
						</motion.div>
					))}
				</Masonry>
			</AnimatePresence>
		</div>
	);
};
