import Scroller from "@/components/core/display/scroller.tsx";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { IconX } from "@tabler/icons-react";
import React from "react";
import type { ForwardedRef } from "react";

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverArrow = PopoverPrimitive.Arrow;
export const PopoverClose = PopoverPrimitive.Close;

type PopoverContentProps = React.ComponentPropsWithoutRef<
	typeof PopoverPrimitive.Content
> & {
	allowClose?: boolean;
	enableArrow?: boolean;
};

export const PopoverContent = React.forwardRef(
	(
		{ children, allowClose, enableArrow, ...props }: PopoverContentProps,
		forwardedRef: ForwardedRef<HTMLDivElement>,
	) => (
		<PopoverPrimitive.Portal>
			<PopoverPrimitive.Content
				className="z-[20] relative rounded-2xl w-auto border border-white/10 bg-mvdark-950 bg-opacity-95 backdrop-blur-sm shadow-xl focus:shadow-black/10 will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
				sideOffset={5}
				{...props}
				ref={forwardedRef}
			>
				<Scroller className="p-6" height={600}>
					{children}
				</Scroller>

				{enableArrow && <PopoverPrimitive.Arrow className="fill-white" />}

				{allowClose && (
					<PopoverClose className="absolute top-2 right-2 text-gray-400 hover:text-gray-600">
						<IconX size={14} stroke={2} />
					</PopoverClose>
				)}
			</PopoverPrimitive.Content>
		</PopoverPrimitive.Portal>
	),
);

PopoverContent.displayName = "PopoverContent";
