import { apiClient } from "@/api/api-client.ts";
import type { User } from "@/types/user.ts";

const profileService = {
	getProfile: async () => {
		const response = await apiClient.get<{ data: User }>("/profile");

		return response.data.data;
	},
	update: async (payload: User) => {
		const response = await apiClient.put<{ data: User[] }>("/profile", payload);

		return response.data;
	},
	updateBgColor: async (payload: {
		profileBgColor: string;
	}) => {
		const response = await apiClient.put<{ data: User[] }>(
			"/profile/bg_color",
			payload,
		);

		return response.data;
	},
	updateProfileBanner: async (payload: {
		image: File;
	}) => {
		const formData = new FormData();

		formData.append("image", payload.image, "image.jpg");

		const response = await apiClient.post<{ data: User[] }>(
			"/profile/banner",
			formData,
		);

		return response.data;
	},
	updateProfilePicture: async (payload: {
		image: File;
	}) => {
		const formData = new FormData();

		formData.append("image", payload.image, "image.jpg");

		const response = await apiClient.post<{ data: User[] }>(
			"/profile/avatar",
			formData,
		);

		return response.data;
	},
};

export default profileService;
