import { collectionsQueryOptions } from "@/api/queries/collection-queries.ts";
import { CollectionItems } from "@/components/collections/collection-items.tsx";
import { IconCollections, IconPlus, IconQrcode } from "@/components/mvicons";
import Reader from "@/components/reader/reader.tsx";
import Scan from "@/components/scanner/scanner.tsx";
import { useScreenWidth } from "@/hooks/use-screen-width.ts";
import type { Collection } from "@/types/collection.ts";
import { getAssetByCollectionName } from "@/utils/asset-url.ts";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export function FtueCollectionItems({
	items = [],
	showAllItems,
}: {
	items?: Collection[];
	showAllItems: boolean;
}) {
	// const [isScanOpen, setScanOpen] = useState(false);
	// const [isReaderOpen, setReaderOpen] = useState(false);

	// const [showAllItems, setShowAllItems] = useState(true);
	const collectionQuery = useQuery(collectionsQueryOptions());
	const collections = collectionQuery.data;

	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < 640;
	const isTablet = screenWidth < 768;
	const itemCount = isMobile ? 3 : isTablet ? 4 : 2;

	const filteredItems = showAllItems
		? items
		: items?.filter((item) => item.isOwned);

	return (
		<div className="flex flex-col w-full gap-2 mb-24">
			{/*{isReaderOpen && (*/}
			{/*	<Reader isOpen={isReaderOpen} onClose={() => setReaderOpen(false)} />*/}
			{/*)}*/}
			{/*{isScanOpen && (*/}
			{/*	<Scan isOpen={isScanOpen} onClose={() => setScanOpen(false)} />*/}
			{/*)}*/}

			<div className="flex flex-row w-full gap-2">
				{/* Actual items */}
				{filteredItems?.map(({ id, name, assets, isOwned }) => (
					<div
						key={id}
						className={`relative group flex grow-0 w-auto max-w-[260px] md:max-w-[180px] bg-white bg-opacity-5 rounded-xl ${isOwned ? "grayscale-0" : "grayscale"}`}
					>
						<div className="z-[2] absolute inset-0 flex flex-col w-full h-full p-2 gap-2 bg-black/50 transition-all opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
							{isOwned ? (
								<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
									<IconCollections size={14} />
									<span className="text-xs font-semibold uppercase">
										You own this
									</span>
								</div>
							) : (
								<>
									<div className="inline-flex self-start flex-row items-center w-auto rounded-full py-1 px-2 gap-1 bg-white/20 backdrop-blur text-white">
										<IconPlus size={12} />
										<span className="text-xs font-semibold uppercase">
											Available Now
										</span>
									</div>
									<button
										onClick={() => setScanOpen(true)}
										type="button"
										className="inline-flex flex-row items-center w-auto mt-auto ml-auto rounded-md p-1 bg-white/20 backdrop-blur text-white"
									>
										<IconQrcode size={32} />
									</button>
								</>
							)}
						</div>
						{assets && assets?.length !== 0 && (
							<img
								className="z-[1] opacity-100 block w-full h-auto"
								src={getAssetByCollectionName(assets, "cover_image")}
								alt={name}
							/>
						)}
					</div>
				))}

				{/* collection item placeholder */}
				{Array.from({
					length: Math.max(itemCount - filteredItems?.length, 0),
				}).map((_, index) => (
					<div
						key={index}
						className="relative flex grow-0 w-auto max-w-[260px] md:max-w-[180px]"
					>
						<button
							type="button"
							className="z-[2] absolute inset-0 flex flex-1 shrink-0 flex-col justify-center items-center w-full h-full rounded-xl gap-4 p-2 md:p-4 bg-white bg-opacity-5 hover:bg-opacity-10 border-2 border-white/20 border-dashed transition-all"
						>
							<IconQrcode className="text-white" size={32} />
							<span className="max-w-[180px] text-center text-xs sm:text-sm font-semibold text-white leading-tight">
								Scan QR Code and redeem digital twin and rewards!
							</span>
						</button>
						<img
							className="z-[1] opacity-0 block w-full h-auto"
							src="/chills1.jpg"
							alt=""
						/>
					</div>
				))}
				{/* collections guideline placeholder */}
				<div className="hidden md:flex flex-row w-full rounded-xl gap-4 p-8 bg-white bg-opacity-5">
					<div className="inline-flex text-white">
						<IconCollections size={38} />
					</div>
					<div className="flex flex-col w-full gap-3">
						<span className="text-sm font-semibold text-white leading-tight">
							Your Grails and Prizes
						</span>
						<span className="text-sm text-white/70 leading-tight line-clamp-4">
							As you collect comics your collection will grow here. Over time
							you’ll receive additional collectibles, bonus content and rewards
							and any of those that are collectible will also show up here.
						</span>
						<span className="text-sm text-white/70 leading-tight line-clamp-3">
							This is your personal collection. Each item is yours and all kinds
							of awesome will land here as you explore the Macroverse and
							participate across it.
						</span>
					</div>
				</div>
			</div>
			{/* collections guideline placeholder */}
			<div className="flex md:hidden flex-row w-full rounded-xl gap-4 p-8 bg-white bg-opacity-5">
				<div className="inline-flex text-white">
					<IconCollections size={38} />
				</div>
				<div className="flex flex-col w-full gap-3">
					<span className="text-sm font-semibold text-white leading-tight">
						Your Grails and Prizes
					</span>
					<span className="text-sm text-white/70 leading-tight line-clamp-4">
						As you collect comics your collection will grow here. Over time
						you’ll receive additional collectibles, bonus content and rewards
						and any of those that are collectible will also show up here.
					</span>
					<span className="text-sm text-white/70 leading-tight line-clamp-3">
						This is your personal collection. Each item is yours and all kinds
						of awesome will land here as you explore the Macroverse and
						participate across it.
					</span>
				</div>
			</div>
		</div>
	);
}
