import { QueryClient } from "@tanstack/query-core";
import { AxiosError } from "axios";

const backoffDelay = (attempt: number) => Math.min(1000 * 2 ** attempt, 30000);

const nonRetryableStatusCodes = [401, 403, 404];

const shouldRetry = (failureCount: number, error: any) => {
	if (error instanceof AxiosError) {
		const status = error.response?.status;
		if (status !== undefined && nonRetryableStatusCodes.includes(status)) {
			return false;
		}
	}
	return failureCount < 3;
};

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			networkMode: "always",
			staleTime: 1000 * 60 * 5,
			retry: shouldRetry,
			retryDelay: backoffDelay,
		},
	},
	// mutationCache: new MutationCache({
	// 	onError: (error) => {
	// 		if (error instanceof AxiosError && error.response) {
	// 			toast.error(error.response.data.message);
	// 		}
	// 	},
	// }),
});
