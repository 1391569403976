import { Slider } from "@/components/core/forms/range-slider.tsx";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import Cropper from "react-easy-crop";

const BannerCropper = ({
	image,
	onCrop,
}: { image: any; onCrop: (value: Area) => void }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);

	const onCropChange = (crop) => {
		setCrop(crop);
	};

	const onCropComplete = (croppedArea, croppedAreaPixels: Area) => {
		onCrop(croppedAreaPixels);
	};

	const onZoomChange = (zoom) => {
		setZoom(zoom);
	};

	const handleZoomChange = (value: number) => {
		if (value >= 1 && value <= 5) {
			setZoom(value);
		}
	};

	return (
		<div className="z-[999] fixed top-0 left-0 right-0 flex w-full h-[210px]">
			<div className="flex w-full">
				<Cropper
					image={image}
					// objectFit="horizontal-cover"
					crop={crop}
					cropSize={{ width: 960, height: 210 }}
					zoom={zoom}
					maxZoom={5}
					aspect={4 / 3}
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
					style={{ containerStyle: { maxWidth: "100%" } }}
				/>
			</div>
			{image && (
				<div className="z-[50] absolute bottom-4 left-1/2 -ml-[100px] flex flex-row justify-center items-center w-full max-w-[200px] py-2 px-3 bg-mvdark-950/70 backdrop-blur border border-white/20  rounded-full">
					<button
						type="button"
						onClick={() => handleZoomChange(zoom - 0.1)}
						className="text-white/50 hover:text-white"
					>
						<IconMinus size={16} stroke={3} />
					</button>
					<Slider
						value={[zoom]}
						onValueChange={(value) => handleZoomChange(value[0])}
						min={1}
						max={5}
						step={0.1}
						aria-label="Zoom"
						className="zoom-range block w-full mx-2"
					/>
					<button
						type="button"
						onClick={() => handleZoomChange(zoom + 0.1)}
						className="text-white/50 hover:text-white"
					>
						<IconPlus size={16} stroke={3} />
					</button>
				</div>
			)}
		</div>
	);
};

export default BannerCropper;
