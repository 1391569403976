import { apiClient } from "@/api/api-client.ts";
import AuthModal from "@/components/auth-modal.tsx";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import { useModal } from "@/components/core/modals/use-modal.ts";
import LoginWalletModal from "@/components/login-wallet-modal.tsx";
import RegisterModal from "@/components/register-modal.tsx";
import { useAuth } from "@/hooks/use-auth.tsx";
import { useNavigate } from "@tanstack/react-router";

export default function LoginSocialsModal() {
	const { closeModal } = useModalControl();
	const { fetchProfile } = useAuth();
	const modal = useModal();
	const navigate = useNavigate({
		from: "/",
	});

	const handleRedirect = async (provider: string) => {
		try {
			const response = await apiClient.get<{ url: string }>(
				`/auth/${provider}/redirect`,
			);
			const loginURL = response.data.url;

			const width = 500;
			const height = 600;
			const left = window.screen.width / 2 - width / 2;
			const top = window.screen.height / 2 - height / 2;

			setTimeout(() => {
				window.open(
					loginURL,
					"_blank",
					`width=${width},height=${height},top=${top},left=${left}`,
				);
			});

			window.addEventListener(
				"message",
				async (event) => {
					if (event.data.status === "success") {
						await fetchProfile();

						setTimeout(async () => {
							closeModal();

							await navigate({
								to: "/profile",
							});
						}, 500);
					}
				},
				{ once: true },
			);
		} catch (error) {
			console.error("Failed to open login popup", error);
		}
	};

	return (
		<div className="flex flex-col justify-center items-center w-full">
			<div className="flex flex-col w-full max-w-[380px] gap-8">
				<div className="flex flex-col justify-center items-center w-full gap-4">
					<div className="inline-flex flex-row items-center gap-2">
						<span className="text-lg sm:text-xl text-white">Welcome</span>
						<div className="w-[38px] sm:w-[44px]">
							<img
								className="block w-full"
								src="/svg/profile-macronaut.svg"
								alt=""
							/>
						</div>
						<span className="text-lg sm:text-xl text-white">Macronaut</span>
					</div>
					<div className="flex flex-col justify-center items-center w-full gap-2">
						<span className="font-quagmire text-center font-bold text-2xl text-white uppercase leading-7">
							Suit up and Let's Go...
						</span>
						<span className="font-quagmire text-center font-bold text-2xl text-mvred-600 uppercase leading-7">
							Adventure awaits!
						</span>
					</div>
				</div>
				<div className="flex flex-col w-full divide-y divide-white/20">
					<div className="flex flex-col w-full gap-4 pb-6">
						<div className="grid grid-cols-12 w-full gap-2">
							<div className="col-span-12 flex flex-col w-full mt-2">
								<Button
									className="font-quagmire bg-mvdark-950 uppercase w-full"
									type="button"
									variant="gradient"
									onClick={() => handleRedirect("google-oauth2")}
									size="lg"
								>
									<ButtonLabel>Google Login</ButtonLabel>
								</Button>
							</div>
							<div className="col-span-12 flex flex-col w-full mt-2">
								<Button
									className="font-quagmire bg-mvdark-950 uppercase w-full"
									type="button"
									onClick={() => handleRedirect("apple")}
									variant="gradient"
									size="lg"
								>
									<ButtonLabel>Apple Login</ButtonLabel>
								</Button>
							</div>
							<div className="col-span-12 flex flex-col w-full mt-2">
								<Button
									className="font-quagmire bg-mvdark-950 uppercase w-full"
									type="button"
									variant="gradient"
									size="lg"
								>
									<ButtonLabel>X Login</ButtonLabel>
								</Button>
							</div>
						</div>
						<div className="flex flex-col justify-center items-center w-full mt-2">
							<span className="text-md text-white/70">Need an account?</span>
							<button
								type="button"
								onClick={() => {
									closeModal();

									modal.openModal({
										title: "Login Email Modal",
										component: <RegisterModal />,
									});
								}}
								className="font-semibold text-lg text-white uppercase"
							>
								Register
							</button>
						</div>
					</div>
					<div className="flex flex-col w-full gap-3 pt-6">
						<Button
							onClick={() => {
								closeModal();

								modal.openModal({
									title: "Login Email Modal",
									component: <AuthModal />,
								});
							}}
							className="font-quagmire w-full uppercase"
							type="button"
							variant="outline"
							color="blue"
						>
							<ButtonLabel>Login with email</ButtonLabel>
						</Button>
						{/*<Button*/}
						{/*	onClick={() => {*/}
						{/*		closeModal();*/}

						{/*		modal.openModal({*/}
						{/*			title: "Login Wallet Modal",*/}
						{/*			component: <LoginWalletModal />,*/}
						{/*		});*/}
						{/*	}}*/}
						{/*	className="font-quagmire w-full"*/}
						{/*	type="button"*/}
						{/*	variant="outline"*/}
						{/*	color="blue"*/}
						{/*>*/}
						{/*	<ButtonLabel>Login with wallet</ButtonLabel>*/}
						{/*</Button>*/}
					</div>
				</div>
			</div>
		</div>
	);
}
