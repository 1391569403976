import waitlistService from "@/api/services/waitlist-service.ts";
import { useMutation } from "@tanstack/react-query";

export const useWaitlistMutation = () => {
	return useMutation({
		mutationKey: ["waitlist"],
		mutationFn: waitlistService.create,
		onSuccess: () => {},
	});
};
