import clsx from "clsx";

type IconProps = {
	size?: number;
	color?: string;
	title?: string;
	className?: string;
};

const IconPackage = ({
	size = 24,
	color = "currentColor",
	title = "mv-icon",
	className,
	...props
}: IconProps) => (
	<div
		className={clsx(
			"inline-flex shrink-0 justify-center items-center",
			className,
		)}
		style={{ width: size, height: size }}
	>
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 30 31"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			aria-labelledby="icon-title"
			{...props}
		>
			<title id="icon-title">{title}</title>
			<path
				d="M6.51937 4.49727L1.10725 7.38825C1.01455 7.43714 0.927011 7.49281 0.844277 7.55373L14.6836 14.8169L20.4788 11.932L6.51937 4.49727Z"
				fill={color}
			/>
			<path
				d="M29.9973 9.16668L23.0737 12.6132V16.4961C23.0737 16.9845 22.6803 17.3804 22.1951 17.3804C21.7099 17.3804 21.3165 16.9845 21.3165 16.4961V13.488L15.5517 16.3578V30.4105C15.5697 30.4021 15.588 30.3943 15.6059 30.3853L28.8587 23.6269C29.5628 23.2725 30 22.5606 30 21.7681V9.24482C30 9.21858 29.9982 9.19269 29.9973 9.16668Z"
				fill={color}
			/>
			<path
				d="M28.8587 7.38603L15.6113 0.630374L15.6058 0.627602C15.0159 0.332034 14.315 0.338639 13.7307 0.645293L8.39502 3.49535L22.4206 10.9653L29.1905 7.5952C29.0878 7.51642 28.9774 7.44571 28.8587 7.38603Z"
				fill={color}
			/>
			<path
				d="M0.00416016 9.10742C0.00181641 9.14794 0 9.18851 0 9.22944V21.7835C0 22.5596 0.424219 23.2647 1.10719 23.6246L13.7251 30.3646L13.7307 30.3677C13.7517 30.3787 13.7732 30.3884 13.7944 30.3986V16.3448L0.00416016 9.10742Z"
				fill={color}
			/>
		</svg>
	</div>
);

export default IconPackage;
