import AuthModal from "@/components/auth-modal.tsx";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import { useModal } from "@/components/core/modals/use-modal.ts";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/scan")({
	component: IndexComponent,
});

function IndexComponent() {
	const modal = useModal();

	return (
		<div className="flex flex-col justify-center items-center w-full bg-mvdark-950 min-h-screen">
			<div className="relative flex flex-col justify-center items-center w-[420px] h-[860px] border-2 border-white overflow-hidden">
				<div className="z-[3] absolute inset-0 flex flex-1 flex-col">
					<div className="flex flex-row justify-center items-center w-full max-w-[360px] h-auto mx-auto p-4 pt-12">
						<div className="inline-flex h-[44px]">
							<img src="/svg/macroverse-logo-white.svg" alt="Macroverse" />
						</div>
					</div>
					<div className="flex flex-1 flex-col justify-center items-center w-full max-w-[360px] mx-auto p-4 gap-6">
						<div className="inline-flex flex-col justify-center items-center w-auto gap-2">
							<div className="inline-flex w-[300px]">
								<img
									className="block w-full"
									src="/svg/brand-authentic-editions.svg"
									alt="Macroverse"
								/>
							</div>
							<div className="inline-flex w-[90px]">
								<img
									className="block w-full"
									src="/svg/indicator-comics-scan.svg"
									alt="Macroverse"
								/>
							</div>
						</div>
						<span className="text-center font-semibold text-md text-white">
							Scan Authentic Edition QR Codes and redeem your free Digital Twins
							plus Bonus Perks and Rewards!
						</span>
						<Button
							className="font-quagmire w-full uppercase"
							onClick={() => {
								modal.openModal({
									title: "Login Modal",
									component: <AuthModal />,
								});
							}}
							type="button"
							variant="default"
							color="white"
							size="lg"
						>
							<ButtonLabel>Login and Scan</ButtonLabel>
						</Button>
					</div>
				</div>
				<div className="z-[2] absolute w-[380px] h-[380px] rounded-full blur-[100px] bg-black opacity-50" />
				<div className="z-[1] absolute inset-0 bg-mvdark-800 opacity-50" />
				{/*<img
          className="block h-full w-max max-w-max"
          src="/landing-hero.jpg"
          alt="Macroverse"
        />*/}
			</div>
		</div>
	);
}
