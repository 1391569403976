import { IconX } from "@tabler/icons-react";
import clsx from "clsx";

function AlertErrors<T>({ errors }: { errors: Record<string, T> }) {
	return (
		<ul>
			{Object.entries(errors).map(([key, value]) => (
				<li key={key}>{value as string}</li>
			))}
		</ul>
	);
}

type AlertProps = {
	icon?: JSX.Element;
	title?: JSX.Element | string;
	type?: "default" | "error" | "success" | "warning" | "info";
	children: React.ReactNode;
	className?: string;
	allowClose?: boolean;
};

function Alert({
	icon,
	type = "default",
	children,
	title,
	className,
	allowClose = false,
}: AlertProps) {
	const alertStyles = {
		default: {
			bgColor: "bg-white/10",
			iconColor: "text-white/80",
			titleTextColor: "text-white/80",
			textColor: "text-white/50",
		},
		error: {
			bgColor: "bg-mvred-600/20",
			iconColor: "text-mvred-600",
			titleTextColor: "text-mvred-600",
			textColor: "text-mvred-600",
		},
		success: {
			bgColor: "bg-green-600/20",
			iconColor: "text-green-600",
			titleTextColor: "text-green-600",
			textColor: "text-green-600",
		},
		warning: {
			bgColor: "bg-yellow-600/20",
			iconColor: "text-yellow-600",
			titleTextColor: "text-yellow-600",
			textColor: "text-yellow-600",
		},
		info: {
			bgColor: "bg-mvblue-400/20",
			iconColor: "text-mvblue-500",
			titleTextColor: "text-mvblue-500",
			textColor: "text-mvblue-500",
		},
	};

	return (
		<div
			className={clsx(
				"relative flex flex-row items-center justify-center w-full p-4 gap-3 rounded-xl",
				className,
				alertStyles[type].bgColor,
			)}
		>
			{allowClose && (
				<div className="absolute top-0 right-0">
					<button type="button" className="opacity-50 hover:opacity-100">
						<IconX size={16} stroke={1.5} />
					</button>
				</div>
			)}

			{icon && (
				<div
					className={`inline-flex justify-center items-center ${alertStyles[type].iconColor}`}
				>
					{icon}
				</div>
			)}
			<div className="flex flex-col shrink w-auto gap-1">
				{title && (
					<span
						className={clsx(
							"block w-auto text-sm font-semibold leading-tight",
							alertStyles[type].titleTextColor,
						)}
					>
						{title}
					</span>
				)}
				<div
					className={clsx(
						"flex flex-col w-auto text-sm gap-1 leading-tight",
						alertStyles[type].textColor,
					)}
				>
					{children}
				</div>
			</div>
		</div>
	);
}

Alert.Errors = AlertErrors;

export default Alert;
