import { settingsSubTabTrigger } from "@/components/constants/tabs.ts";
import { Button, ButtonLabel } from "@/components/core/buttons/button";
import CustomToast from "@/components/core/feedbacks/custom-toast.tsx";
import Tooltip from "@/components/core/feedbacks/tooltip.tsx";
import { useModalControl } from "@/components/core/modals/use-modal-controller.ts";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@/components/core/navigations/tabs.tsx";
import SettingsAccountsWallets from "@/components/features/settings/settings-accounts-wallets.tsx";
import {
	IconCreditCard,
	IconExp,
	IconSettings,
	IconVrs,
	IconWallet,
} from "@/components/mvicons";
import SettingsAddressSwiper from "@/components/swipers/settings-address-swiper.tsx";
import SettingsCreditCardsSwiper from "@/components/swipers/settings-credit-cards-swiper.tsx";
import { useState } from "react";
import { toast } from "sonner";

const tabItems = [
	{
		id: "exp",
		icon: <IconExp size={24} />,
		label: "XP",
	},
	// {
	// 	id: "vrs",
	// 	icon: <IconVrs size={22} />,
	// 	label: "Vrs",
	// },
	// {
	// 	id: "payments",
	// 	icon: <IconCreditCard size={22} />,
	// 	label: "Payments + Shipping",
	// },
	// {
	// 	id: "wallets",
	// 	icon: <IconWallet size={22} />,
	// 	label: "Wallets",
	// },
];

export default function SettingsAccounts() {
	const { closeModal } = useModalControl();
	const [activeTab, setActiveTab] = useState("exp"); // tab trigger

	return (
		<div className="flex flex-col w-full">
			<Tabs value={activeTab} onValueChange={setActiveTab}>
				<TabsList className="border-none !py-0 !gap-2">
					{tabItems.map((item) => {
						const isActiveTab = activeTab === item.id;

						return (
							<TabsTrigger
								key={item.id}
								className={settingsSubTabTrigger}
								value={item.id}
							>
								{isActiveTab ? (
									<div className="inline-flex flex-row items-center w-auto gap-2">
										<span className="inline-flex">{item.icon}</span>
										<span className="hidden sm:inline-flex">{item.label}</span>
									</div>
								) : (
									<Tooltip size="sm" content={item.label} sideOffset={8}>
										<div className="inline-flex flex-row items-center w-auto gap-2">
											<span className="inline-flex">{item.icon}</span>
										</div>
									</Tooltip>
								)}
							</TabsTrigger>
						);
					})}
				</TabsList>
				<TabsContent value="exp">
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-row items-center gap-2">
							<span className="text-sm font-semibold text-white">Your Exp</span>
							<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
								Coming Soon
							</span>
						</div>
						<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
							<div className="inline-flex text-white">
								<IconExp />
							</div>
							<div className="flex flex-col w-full max-w-[280px] gap-2">
								<span className="text-sm font-semibold text-white leading-tight">
									Your latest and greatest content.
								</span>
								<span className="text-sm text-white/70 leading-tight">
									Series you’re reading are automatically waiting for you here.
									Add them to your list and queue them up here as well.
								</span>
							</div>
						</div>
					</div>
				</TabsContent>
				{/*<TabsContent value="vrs">
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-row items-center gap-2">
							<span className="text-sm font-semibold text-white">Your Vrs</span>
							<span className="inline-flex items-center justify-center h-[18px] w-auto px-2 rounded-full bg-white/30 font-bold text-2xs text-mvdark-950 uppercase">
								Coming Soon
							</span>
						</div>
						<div className="flex flex-row w-full rounded-xl gap-4 p-6 bg-white bg-opacity-5">
							<div className="inline-flex text-white">
								<IconVrs />
							</div>
							<div className="flex flex-col w-full max-w-[280px] gap-2">
								<span className="text-sm font-semibold text-white leading-tight">
									Your latest and greatest content.
								</span>
								<span className="text-sm text-white/70 leading-tight">
									Series you’re reading are automatically waiting for you here.
									Add them to your list and queue them up here as well.
								</span>
							</div>
						</div>
					</div>
				</TabsContent>*/}
				<TabsContent value="payments">
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-row items-center gap-2">
							<span className="text-sm font-semibold text-white">
								Your Credit Card/s
							</span>
						</div>
						<SettingsCreditCardsSwiper />
					</div>

					<div className="flex flex-col w-full gap-2 mt-4">
						<div className="flex flex-row items-center gap-2">
							<span className="text-sm font-semibold text-white">
								Your Shipping Address/es
							</span>
						</div>
						<SettingsAddressSwiper />
					</div>

					<div className="flex flex-col w-full mt-12">
						<div className="inline-flex self-center flex-col w-full max-w-[320px] gap-2">
							<Button
								onClick={() =>
									toast(
										<CustomToast
											icon={<IconSettings size={72} />}
											message="Settings Updated"
										/>,
										{
											duration: 2000,
											position: "top-center",
											className:
												"flex flex-row justify-center p-0 bg-transparent shadow-none !mt-[calc(50vh-65px)]",
										},
									)
								}
								className="font-quagmire self-center bg-mvdark-950 w-full uppercase"
								variant="gradient"
								size="lg"
							>
								<ButtonLabel>Update</ButtonLabel>
							</Button>
							<Button
								onClick={() => {
									closeModal();
								}}
								className="self-center w-full"
								variant="unstyled"
								size="sm"
							>
								<ButtonLabel>Cancel</ButtonLabel>
							</Button>
						</div>
					</div>
				</TabsContent>
				<TabsContent value="wallets">
					<SettingsAccountsWallets />
				</TabsContent>
			</Tabs>
		</div>
	);
}
