import * as SliderPrimitive from "@radix-ui/react-slider";
import clsx from "clsx";
import React from "react";

const Slider = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
	<SliderPrimitive.Root
		ref={ref}
		className={clsx(
			"relative flex w-full touch-none select-none items-center",
			className,
		)}
		{...props}
	>
		<SliderPrimitive.Track className="bg-white/50 relative grow rounded-full h-[3px]">
			<SliderPrimitive.Range className="absolute bg-transparent rounded-full h-full" />
		</SliderPrimitive.Track>
		<SliderPrimitive.Thumb className="block w-4 h-4 bg-white shadow-[0_2px_10px] shadow-neutral-950 rounded-[10px] hover:bg-white focus:outline-none focus:shadow-[0_0_0_5px] focus:shadow-transparent" />
	</SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
