import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {
	questQueryOptions,
	useQuestClaimMutation,
} from "@/api/queries/quest-queries.ts";
import ExpTimer from "@/components/exp-timer.tsx";
import SingleQuestCard from "@/components/single-quest-card.tsx";
import ExpCardSkeleton from "@/components/skeletons/exp-card-skeleton";
import QuestCardSkeleton from "@/components/skeletons/quest-card-skeleton";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Pagination } from "swiper/modules";

export default function QuestBoard() {
	const questQuery = useQuery(questQueryOptions());
	const quests = questQuery.data;
	const questClaimMutation = useQuestClaimMutation();
	const navigate = useNavigate({
		from: "/profile",
	});

	const isLoading = false;

	return (
		<div className="flex flex-col w-full gap-2">
			<div className="flex flex-row items-center gap-2">
				<span className="text-sm font-semibold text-white">
					Perks and Rewards
				</span>
			</div>
			<div className="flex flex-row w-full gap-4 overflow-visible">
				<Swiper
					slidesPerView="auto"
					spaceBetween={10}
					pagination={{
						enabled: false,
						clickable: true,
					}}
					modules={[Pagination]}
					className="overflow-visible w-full quest-board-swiper"
				>
					{isLoading ? (
						<SwiperSlide>
							<ExpCardSkeleton />
						</SwiperSlide>
					) : (
						<SwiperSlide>
							<div className="flex flex-1 flex-col min-w-[260px] w-[260px] max-w-[260px] min-h-[300px] h-full rounded-4xl bg-white bg-opacity-5 text-white/50">
								<ExpTimer duration={10} />
							</div>
						</SwiperSlide>
					)}
					{quests?.items?.map((quest) =>
						isLoading ? (
							<SwiperSlide key={quest.id}>
								<QuestCardSkeleton />
							</SwiperSlide>
						) : (
							<SwiperSlide
								key={quest.id}
								className=" min-w-[260px] w-[260px] max-w-[260px]"
							>
								<SingleQuestCard
									quest={quest}
									onClaim={async (quest) => {
										await questClaimMutation.mutateAsync({ questId: quest.id });
									}}
									onAction={async (quest) => {
										await navigate({
											to: quest.metadata.actionLink,
										});
									}}
								/>
							</SwiperSlide>
						),
					)}
				</Swiper>
			</div>
		</div>
	);
}
